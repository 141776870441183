import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useUserContext } from '../../contexts/user/UserContextProvider';
import Avatar from './Avatar';
import DropdownMenu, { PLACEMENT, TRIGGER } from '../../../components/dropdown-menu';
import useNavigateToApp from '../../useNavigateToApp';
import ProfileMenuHeader from './profile-menu-header';
import style from './style';

const FontAwesome = ({ icon }) => <i className={`fas fa-${icon}`} />;

async function signOut() {
  try {
    await Auth.signOut();
  } catch (error) {
    console.log('error signing out: ', error);
  }
}

const ProfileMenu = ({ className }) => {
  const { userName } = useUserContext();
  const history = useHistory();
  const { navigate } = useNavigateToApp();

  const handleMenuClick = useCallback(
    id => {
      navigate(id, false);
    },
    [navigate]
  );

  const navigateToProfile = useCallback(() => {
    history.push('/app/settings/_/user-profile');
  }, [history]);

  const menuItems = useMemo(() => {
    const items = [];

    items.push({
      type: 'function',
      label: 'settings.user-profile',
      icon: <FontAwesome icon="chart-bar" />,
      fn: navigateToProfile,
    });

    items.push({
      type: 'function',
      label: 'signOut',
      icon: <FontAwesome icon="sign-out-alt" />,
      fn: signOut,
    });

    return [
      {
        type: 'component',
        component: <ProfileMenuHeader userName={userName} />,
      },
      ...items,
    ];
  }, [userName, navigateToProfile]);

  return (
    <div className={cx('cx-profile-menu', className)} data-testid="profileMenu">
      <DropdownMenu
        trigger={[TRIGGER.CLICK]}
        placement={PLACEMENT.BOTTOM_RIGHT}
        menuItems={menuItems}
        onClick={handleMenuClick}
        activeId={''}
      >
        <div className="dropdown-content">
          <div className="avatar">
            <Avatar />
          </div>
          <div className="name">{userName}</div>
        </div>
      </DropdownMenu>
    </div>
  );
};

export default styled(ProfileMenu)`
  ${style}
`;
