import { css } from 'styled-components';

export default css`
  &&& {
    .ant-statistic-title {
      color: var(--statistic-title-color);
    }

    .ant-statistic-content {
      color: var(--statistic-content-color);
    }
  }
`;
