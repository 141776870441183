import View from './view';
import i18n from './i18n';
import permissions from './permissions';

export default {
  component: View,
  i18n,
  id: 'sdlc-dashboard',
  moduleId: 'sdlc-dashboard',
  permissions,
};
