import React, { useCallback } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useHistory, useRouteMatch } from 'react-router-dom';
import FlexColumn from '../../../../../../../components/layout-flex-column';
import Table from '../../../../../../../components/table';
import style from './dataSetListStyle';
import Text from '../../../../../../../components/typography/text';
import Button from '../../../../../../../components/button';

const columns = [
  {
    title: 'ID',
    dataIndex: 'ID',
    key: 'ID',
  },
  {
    title: 'Description',
    dataIndex: 'Description',
    key: 'Description',
  },
  {
    title: 'Frequency',
    dataIndex: 'Frequency',
    key: 'Frequency',
  },
  {
    title: 'Iterator',
    dataIndex: 'Iterator',
    key: 'Iterator',
  },
];

export const DataSetList = ({ dataSets = [], className }) => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const handleNew = useCallback(() => {
    history.push(`${url}/new`);
  }, [history, url]);

  return (
    <FlexColumn className={cn(className)}>
      <div className="heading">
        <div className="left">
          <Text text="datasets.dataSets" />
        </div>
        <div className="right">
          <Button label="new" icon={<i className="far fa-plus-square" />} onClick={handleNew} />
        </div>
      </div>
      <Table
        dataSource={dataSets}
        columns={columns}
        size="small"
        onRow={record => {
          return {
            onClick: e => {
              // TODO: put dataSets in context.
              history.push(`${url}/${record.ID}`, record);
            },
          };
        }}
      />
    </FlexColumn>
  );
};

export default styled(DataSetList)`
  ${style}
`;
