import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import { Button } from 'components/index';
import copyToClipboard from 'util/copy-to-clipboard';
import Spinner from 'components/spinner';

const ItemResults = ({ className, lookupResults, modalLoading }) => {
  return (
    <div className={cn('item-details-results', className)}>
      <div className="item-details-header-section">
        <Button
          icon={<i className="fa fa-copy" />}
          onClick={() => copyToClipboard(JSON.stringify(lookupResults, null, 2))}
          label={'audit-logs.copy-json'}
        />
      </div>
      <div className="content-section">
        <Spinner spinning={modalLoading} size="large">
          <pre className="item-details-results">{JSON.stringify(lookupResults, null, 2)}</pre>
        </Spinner>
      </div>
    </div>
  );
};

export default styled(ItemResults)`
  ${style}
`;
