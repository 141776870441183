import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import style from './style';
import cn from 'classnames';

const ProblemList = ({ problems, className }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    problems && problems.length > 0 && setVisible(true);
  }, [problems]);

  return (
    <table className={cn(className, 'problem-list')}>
      {visible && (
        <tbody>
          <tr className="header">
            <td style={{ width: '25%' }}>Problem ID</td>
            <td>Description</td>
          </tr>
          {problems.map((problem, index) => {
            return (
              <tr key={index} className={`prevents-merge-${problem.preventsMerge}`}>
                <td>{problem.id}</td>
                <td>{problem.description}</td>
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
};

export default styled(ProblemList)`
  ${style}
`;
