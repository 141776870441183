import { useCallback, useState } from 'react';
import * as Roadmunk from '../../../api/roadmunk';
import useHandleApiCall from 'util/handle-api-call';
import { useUserContext } from 'core/contexts/user/UserContextProvider';

export const useConsole = () => {
  const [productAreas, setProductAreas] = useState(null);
  const [ideas, setIdeas] = useState(null);
  const [feature, setFeature] = useState(null);
  const [factors, setFactors] = useState(null);

  const {
    user: { username: author },
  } = useUserContext();

  const _getProductAreas = useCallback(async () => {
    return Roadmunk.action({ actionId: 'listProductAreas' }).then(({ response }) => {
      setProductAreas((response?.Issues?.length && []) ?? response);
    });
  }, []);

  const getIdeas = useCallback(async productAreaRef => {
    Roadmunk.action({ actionId: 'listIdeas', params: { productAreaRef } }).then(rs => {
      setIdeas(rs?.response?.ideas ?? []);
      setFactors(rs?.response?.factors ?? []);
    });
  }, []);

  const getRelatedFeature = useCallback(async featureId => {
    Roadmunk.action({ actionId: 'getRelatedFeature', params: { featureId } }).then(rs => {
      setFeature(rs?.response ?? []);
    });
  }, []);

  const [getProductAreas, gettingProductAreas] = useHandleApiCall(_getProductAreas);

  return {
    productAreas,
    getProductAreas,
    gettingProductAreas,

    ideas,
    getIdeas,

    factors,

    getRelatedFeature,
    feature,
  };
};
