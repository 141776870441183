export default {
  appName: 'Integrate',
  'integrate-dashboard': 'Dashboard',
  airticket: 'AirTicket',
  'airticket-metrics': 'Metrics',
  'airticket-configure': 'Configure',
  'command-assurance': 'COMMANDAssurance',
  'command-assurance-metrics': 'Metrics',
  'command-assurance-configure': 'Configure',
};
