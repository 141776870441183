export class TransformQuerySqlRq {
  static transformRequest(query) {
    return {
      Columns: query.columns,
      Name: query.name,
      Parameters: (query.parameters || []).map(p => ({
        Value: p.value,
        Type: p.type,
        Name: p.name,
      })),
      Type: query.type,
      Sql: query.sql,
      PerformanceMetrics: query.performanceMetrics,
    };
  }
}
