import React from 'react';
import cx from 'classnames';
import propTypes from 'prop-types';
import styles from './flex-column.module.css';

const FlexColumn = ({ children, className, style }) => {
  return (
    <div className={cx(className, 'cx-flex-column', styles.cxFlexColumn)} style={style}>
      {children}
    </div>
  );
};

FlexColumn.propTypes = {
  children: propTypes.any,
  className: propTypes.string,
  style: propTypes.object,
};

export default FlexColumn;
