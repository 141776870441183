export default {
  id: 'custom-components',
  icon: 'code',
  description: 'Custom Components',
  moduleId: 'custom-components',

  // Everything in params (optional) will get passed into your component as a prop.
  params: {
    test: '1',
  },
};
