export default {
  moduleDisplayLabel: 'Dynamic Form',
  exampleArray: 'Example Array',
  firstName: 'First Name',
  thing: 'Thing',
  product: 'Product',
  dob: 'DOB',
  enabled: 'Enabled',
  timeZone: 'Time Zone',
  currency: 'Currency',
  dateRange: 'Date Range',
};
