export default {
  moduleDisplayLabel: 'Запити',
  executionPlan: 'План виконання',
  loadingExecutionPlan: 'завантажити план виконання',
  editQuery: 'редагувати запит',
  refresh: 'Оновлення',
  backToList: 'Повернутися до списку запитів',
  parameters: 'Параметри',
  name: "Ім'я",
  defaultValue: 'Значення за замовчуванням',
  tags: 'Теги',
  type: 'Тип',
};
