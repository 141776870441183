import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Drawer, Spinner, Table } from 'components';
import PullRequestHeader from '../pull-request-header';
import cn from 'classnames';
import style from './style';
import DrawerTitleWithSize from '../../../../data/explorer/view/components/data-results/DrawerTitleWithSize';
import CreatePullRequest from '../create-pull-request';
import FlexColumn from 'components/layout-flex-column';
import { usePullRequest } from '../usePullRequest';
import PullRequestDetail from '../pull-request-detail';
import moment from 'moment';
import buildColumns from './buildColumns';
import { useOnMount } from 'util/useOnMount';

const columns = buildColumns();

const PullRequestList = ({ module, className }) => {
  const history = useHistory();
  const { pullRequestId } = useParams();
  const [showDetail, setShowDetail] = useState(!!pullRequestId);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState(null);
  const [status, setStatus] = useState('OPEN');
  const [repo, setRepo] = useState('connex');
  const { listing, listPullRequests, pullRequests } = usePullRequest();

  useEffect(() => {
    pullRequests &&
      setData(
        pullRequests.map(p => {
          return {
            ...p,
            lastActivityDate: moment(p.lastActivityDate).format('MM/DD HH:MM A'),
            approvalCount: (p.approvals || []).length,
          };
        })
      );
  }, [pullRequests]);

  useOnMount(() => {
    listPullRequests(repo, status, true);
  });

  const onRow = useCallback(
    record => {
      const handleClick = () => {
        setShowDetail(true);
        history.push(`${module.path}/${record.id}`);
      };

      return {
        onClick: handleClick,
      };
    },
    [history, module.path]
  );

  const onMerged = useCallback(() => {
    history.goBack();
    setShowDetail(false);
    listPullRequests(repo, status, true);
  }, [history, listPullRequests, repo, status]);

  const onCreated = useCallback(() => {
    setShowModal(false);
    listPullRequests(repo, status, true);
  }, [listPullRequests, repo, status]);

  const closeDetailDrawer = useCallback(() => {
    setShowDetail(false);
  }, []);

  return (
    <FlexColumn className={cn('pull-request-list', className)}>
      <PullRequestHeader
        setShowModal={setShowModal}
        listPullRequests={listPullRequests}
        listing={listing}
        repo={repo}
        setRepo={setRepo}
        setStatus={setStatus}
        status={status}
      />
      <FlexColumn className="pull-request-list-body">
        <Spinner spinning={listing} size="large">
          <Table
            className="pull-request-list"
            columns={columns}
            dataSource={data || []}
            showFilters
            showSort
            isCompact
            onRow={onRow}
          />
        </Spinner>
      </FlexColumn>
      <Drawer
        title={<DrawerTitleWithSize title="pull-requests.create-pull-request" />}
        width={800}
        onClose={() => setShowModal(false)}
        visible={showModal}
        drawerStyle={{ display: 'flex' }}
        style={{ display: 'flex' }}
        destroyOnClose
      >
        <CreatePullRequest repositoryName={repo} onCreated={onCreated} />
      </Drawer>
      <Drawer
        title={<DrawerTitleWithSize title="pull-requests.pullRequestReview" />}
        width={800}
        onClose={() => {
          history.push(module.path);
          setShowDetail(false);
        }}
        visible={showDetail}
        drawerStyle={{ display: 'flex' }}
        style={{ display: 'flex' }}
        destroyOnClose
      >
        <PullRequestDetail onMerged={onMerged} onCancel={closeDetailDrawer} />
      </Drawer>
    </FlexColumn>
  );
};

export default styled(PullRequestList)`
  ${style}
`;
