import React from 'react';
import AppRoute from './App';
import { AuthWrapper } from '../../core/AuthWrapper';

const AuthenticatedRoute = () => {
  return (
    <AuthWrapper>
      <AppRoute />
    </AuthWrapper>
  );
};

export default AuthenticatedRoute;
