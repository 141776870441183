import { v4 as uuid } from 'uuid';

export class TransformGetQueryRq {
  static transformIncoming(getQueryRq) {
    if (!getQueryRq.success) return getQueryRq;

    const { response } = getQueryRq;

    return {
      ...getQueryRq,
      response: {
        modifyDateTime: response.ModifyDateTime,
        name: response.Name,
        ownerEmail: response.OwnerEmail,
        ownerUid: response.OwnerUid,
        parameters: (response.Parameters || []).map(p => ({
          value: p.Value,
          type: p.Type,
          name: p.Name,
        })),
        sql: response.Sql,
        state: response.State,
        tags: response.Tags,
        uid: response.UID,
        __console_meta: {
          id: uuid(),
          version: 2,
        },
      },
    };
  }
}
