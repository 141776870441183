import common from '../common';
import columnHeaders from './columnHeaders';
import errors from './errors';

export default {
  ...common,
  clearAll: 'Clear All',
  testMessage: 'This is a test message',
  notImplemented: 'Module not Implemented.',
  notFound: 'Module not Found.',
  appNotPermitted: 'Permission denied.',
  save: 'Save',
  cancel: 'Cancel',
  switchTheme: 'Switch Theme',
  poweredBy: 'Powered By',
  small: 'Small',
  medium: 'Medium',
  large: 'Large',
  notProvisioned: 'No Apps have been provisioned.',
  validationRequired: 'This field is required.',
  notifications: 'Notifications',

  error: 'Error',
  signOut: 'Sign Out',

  version: 'Version {version}',

  dynamicFormSubmitButton: 'Submit',
  dynamicFormCancelButton: 'Cancel',
  dynamicFormResetButton: 'Reset',

  emptyText: 'No Data',

  csv: 'CSV',
  json: 'JSON',

  selectLoadingPlaceholder: 'Loading...',
  selectPlaceholder: 'Select...',

  columnHeaders,
  errors,
};
