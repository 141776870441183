import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import propTypes from 'prop-types';
import { Input as AntInput } from 'antd';
import style from './style';

const {TextArea: AntTextArea} = AntInput;

const Input = ({ className, innerRef, password, ...props }) => {
  return (
    <AntInput
      type={password ? 'password' : 'text'}
      className={cn('cx-input', className)}
      {...props}
      ref={innerRef}
    />
  );
};

const TextArea = ({className, innerRef, password, ...props}) => {
  return <AntTextArea
    className={cn('cx-input', className)}
    {...props}
    ref={innerRef}
  />
}

Input.propTypes = {
  className: propTypes.string,
  innerRef: propTypes.string,
  password: propTypes.oneOfType([propTypes.bool, propTypes.string]),
};

const StyledInput = styled(Input)`
  ${style}
`;

StyledInput.TextArea = styled(TextArea)`
  ${style}
`;

export default StyledInput;
