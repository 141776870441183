import React from 'react';
import styled from 'styled-components';
import { isNumber } from 'lodash';
import { Affix, Drawer as AntDrawer } from 'antd';
import cn from 'classnames';
import style from './style';
import propTypes from 'prop-types';
import { useViewport } from '../../core/app-layout/Viewport';

const Drawer = ({ className, title, width, visible, onClose, footer, children }) => {
  const viewport = useViewport();

  const calculatedWidth = React.useMemo(() => {
    //expects width to be a number or a string percentage (i.e. '75%')
    if (isNumber(width)) return width > viewport.width ? viewport.width : width;

    return width;
  }, [width, viewport]);

  return (
    <AntDrawer
      className={cn('cx-drawer', className)}
      title={title}
      width={calculatedWidth}
      visible={visible}
      onClose={onClose}
      destroyOnClose
    >
      {children}
      {footer && (
        <Affix offsetBottom={0} className="cx-drawer-footer">
          {footer}
        </Affix>
      )}
    </AntDrawer>
  );
};

Drawer.propTypes = {
  className: propTypes.string,
  title: propTypes.oneOfType([propTypes.string, propTypes.object]),
  width: propTypes.oneOfType([propTypes.string, propTypes.number]),
  visible: propTypes.bool,
  onClose: propTypes.func,
  footer: propTypes.element,
  children: propTypes.element,
};

export default styled(Drawer)`
  ${style}
`;
