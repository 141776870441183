import React, { useCallback, useEffect, useState } from 'react';
import { ChartRq } from '../../../api';
import FlexColumn from 'components/layout-flex-column';
import { ViewHeader } from 'components';
import TicketSummary from './ticket-summary';
import TicketsByMonth from './tickets-by-month';
import TopEntities from './top-entities';

export default () => {
  const [loading, setLoading] = useState(false);
  const [metrics, setMetrics] = useState(null);
  const [months, setMonths] = useState(null);

  const getEntitySummary = useCallback(() => {
    setLoading(true);

    ChartRq({
      Action: 'getPlatformSummary',
    })
      .then(({ response = {} }) => {
        setMetrics(response.Summary || {});
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  const getTicketsByMonth = useCallback(() => {
    setLoading(true);

    ChartRq({
      Action: 'getTicketsByMonth',
    })
      .then(({ response = {} }) => {
        const { Items = [] } = response;

        setMonths(Items);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getEntitySummary();
    getTicketsByMonth();
  }, [getEntitySummary, getTicketsByMonth]);

  return (
    <FlexColumn>
      <ViewHeader>Platform Ticket Analysis</ViewHeader>
      <TicketSummary loading={loading} months={months} metrics={metrics} />
      <TicketsByMonth loading={loading} months={months} />
      <TopEntities />
    </FlexColumn>
  );
};
