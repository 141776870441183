import React from 'react';
import useAppsConfig from './useAppsConfig';
import Spinner from '../../../components/spinner';
import { AppsConfigContext } from './AppsConfigContext';

export const AppsConfig = ({ children }) => {
  const { appsConfig, busy } = useAppsConfig();

  return (
    <AppsConfigContext.Provider value={{ appsConfig }}>
      <Spinner spinning={busy} size="large">
        {children}
      </Spinner>
    </AppsConfigContext.Provider>
  );
};

export default AppsConfig;
