import { css } from 'styled-components';

export default css`
  &&& {
    .ant-empty-image {
      .ant-empty-img-default-ellipse {
        fill-opacity: 0.08;
        fill: var(--empty-fill-ellipse);
        transition: all 0.3s;
      }
      .ant-empty-img-default-path-1 {
        fill: var(--empty-fill-path-1);
        transition: all 0.3s;
      }
      .ant-empty-img-default-path-2 {
        fill: url(#linearGradient-1);
        transition: all 0.3s;
      }
      .ant-empty-img-default-path-3 {
        fill: var(--empty-fill-path-3);
        transition: all 0.3s;
      }
      .ant-empty-img-default-path-4 {
        fill: var(--empty-fill-path-4);
        transition: all 0.3s;
      }
      .ant-empty-img-default-path-5 {
        fill: var(--empty-fill-path-5);
        transition: all 0.3s;
      }
      .ant-empty-img-default-g {
        fill: var(--empty-img-default-g);
        transition: all 0.3s;
      }
    }
    .ant-empty-description {
      color: var(--baseTextColor);
      transition: all 0.3s;
    }
  }
`;
