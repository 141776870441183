import { css } from 'styled-components';

export default css`
  &&& {
    background-color: ${props =>
      props.type
        ? `var(--button-type-${props.type}-background-color);`
        : 'var(--button-background-color);'};

    color: ${props =>
      props.type ? `var(--button-type-${props.type}-color);` : 'var(--button-color);'};
    ${({ type }) =>
      type === 'text'
        ? ''
        : `border-color: ${
            type ? `var(--button-type-${type}-border-color);` : 'var(--button-border-color);'
          }`}

    border-radius: var(--button-border-radius);
    height: var(--base-height);
    font-size: ${({ theme }) => `${theme.fontSizeMultiplier}rem`};
    min-width: var(--button-width);
    width: inherit;
    transition: font-size 0.3s, width 0s, color 0.3s, background-color 0.3s;

    &:active {
      border-color: var(--button-type-primary-active-border-color);
      background-color: var(--button-type-primary-active-background-color);
      color: var(--button-type-primary-active-color);
      transition: font-size 0.3s, color 0.3s, background-color 0.3s;
    }

    &:focus {
      &:not(:active) {
        transition: font-size 0.3s, color 0.3s, background-color 0.3s;
        background-color: ${props =>
          props.type
            ? `var(--button-type-${props.type}-focus-background-color);`
            : 'var(--button-focus-background-color);'};

        border-color: ${props =>
          props.type
            ? `var(--button-type-${props.type}-focus-border-color);`
            : 'var(--button-focus-border-color);'};

        color: ${props =>
          props.type
            ? `var(--button-type-${props.type}-focus-color);`
            : 'var(--button-focus-color);'};
      }
    }
    &:hover {
      &:not(:focus) {
        transition: font-size 0.3s, color 0.3s, background-color 0.3s;
        background: ${props =>
          props.type
            ? `var(--button-type-${props.type}-hover-background-color);`
            : 'var(--button-hover-background-color);'};

        border-color: ${props =>
          props.type
            ? `var(--button-type-${props.type}-hover-border-color);`
            : 'var(--button-hover-border-color);'};

        color: ${props =>
          props.type
            ? `var(--button-type-${props.type}-hover-color);`
            : 'var(--button-hover-color);'};
      }
    }

    &[disabled]:hover,
    &[disabled] {
      background-color: var(--button-disabled-background-color);
      color: var(--button-disabled-color);
      border-color: var(--button-disabled-border-color);
    }

    &.link {
      border: none;
      padding: 0;
    }

    &.text {
      border: none;
    }

    &.ant-btn-loading {
      opacity: var(--button-loading-opacity);
      transition: opacity 0.3s;
      &:before {
        background: transparent;
      }
    }
  }
`;
