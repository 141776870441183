import React from 'react';
import { Menu, Dropdown as AntDropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const menu = (items, onClick) => {
  return (
    <Menu>
      {items.map(item => (
        <Menu.Item
          key={item.id}
          onClick={() => {
            onClick(item);
          }}
          disabled={item._cx_active === true}
        >
          {item.displayLabel}
        </Menu.Item>
      ))}
    </Menu>
  );
};

const DropDownMenu = ({ items, onSelection, title }) => {
  return (
    <div className="cx-dropdown-menu">
      <AntDropdown overlay={menu(items, onSelection)} trigger={['click']}>
        <a className="cx-dropdown-menu-link" onClick={e => e.preventDefault()}>
          {title} <DownOutlined />
        </a>
      </AntDropdown>
    </div>
  );
};

export default DropDownMenu;
