import React from 'react';
import { FormItemWrapper, Select } from 'components';

const SelectBranch = ({ id, branches, onSelect, defaultValue = null }) => {
  return (
    <Select
      id={id}
      placeholder="pull-requests.select"
      defaultValue={defaultValue}
      options={branches}
      valuePath="branchName"
      displayPath="branchName"
      className="select-filter"
      onChange={onSelect}
      loading={!branches}
    >
      {branches.map(b => (
        <Select.Option key={b.branchName} value={b.branchName}>
          {b.branchName}
        </Select.Option>
      ))}
    </Select>
  );
};

const SelectBranches = ({ start, onSelectSource, onSelectTarget }) => {
  const sourceBranches = (start && start.sourceBranches) || [];
  const targetBranches = (start && start.targetBranches) || [];

  return (
    <table className="select-branches">
      <tbody>
        <tr>
          <td>
            <FormItemWrapper htmlFor="select-source" label="pull-requests.sourceBranch">
              <SelectBranch
                id="select-source"
                branches={sourceBranches}
                onSelect={onSelectSource}
              />
            </FormItemWrapper>
          </td>
          <td>
            <FormItemWrapper htmlFor="select-target" label="pull-requests.targetBranch">
              <SelectBranch
                id="select-target"
                branches={targetBranches}
                onSelect={onSelectTarget}
                defaultValue={'develop'}
              />
            </FormItemWrapper>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default SelectBranches;
