import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { Space } from 'antd';
import { FormattedDate, FormattedTime } from 'react-intl';
import style from './notificationItemStyle';
import moment from 'moment';
import { message, copyToClipboard } from '../../../util';

const ICON = {
  success: <CheckCircleOutlined style={{ color: '#49aa19' }} />,
  error: <CloseCircleOutlined style={{ color: '#A61D24' }} />,
  warning: <ExclamationCircleOutlined style={{ color: '#D89614' }} />,
  info: <InfoCircleOutlined style={{ color: '#177DCC' }} />,
};

const handleClick = notification => {
  copyToClipboard(
    `${notification.title}\n${notification.message}\n${moment(
      notification.timeStamp
    ).toISOString()}`
  );
  message.success('Notification copied to the clipboard.');
};

const NotificationItem = ({ notification, onRemove, className }) => {
  return (
    <div className={cn('notification-item', className)}>
      <div className="header">
        {notification.type && <div className="type-icon">{ICON[notification.type]}</div>}
        <div className="title">{notification.title}</div>
        <div
          className="close-icon"
          onClick={() => {
            onRemove(notification);
          }}
        >
          <CloseOutlined />
        </div>
      </div>
      <div className="body">{notification.message}</div>
      <div className="footer">
        <div className="timestamp">
          <Space>
            <FormattedDate value={notification.timeStamp} />
            <FormattedTime
              value={notification.timeStamp}
              hour="numeric"
              minute="numeric"
              second="numeric"
            />
          </Space>
        </div>
        <div className="copy-icon">
          <button
            onClick={() => {
              handleClick(notification);
            }}
          >
            <i className="far fa-copy" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default styled(NotificationItem)`
  ${style}
`;
