import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';

const Widget = ({ className, children }) => {
  return <div className={cn('cx-widget', className)}>{children}</div>;
};

export default styled(Widget)`
  ${style}
`;
