import React from 'react';

export const AppsConfigContext = React.createContext();

export const useAppsConfigContext = mountPoint => {
  const context = React.useContext(AppsConfigContext);

  if (!context) {
    throw new Error('useAppContext must be called within the App Context.');
  }

  const { appsConfig = [] } = context;

  if (mountPoint) {
    return { ...context, apps: appsConfig.filter(app => app.mountPoint === mountPoint) };
  }
  return context;
};
