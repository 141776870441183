import { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useModulesContext } from 'core/contexts/modules';

const initialState = {
  entity: null,
  status: 'Error',
  statementName: null,
  userName: null,
  containerName: null,
  limit: 10,
  duration: 0,
  itemCount: 0,
  beginDate: moment(),
  endDate: moment(),
  beginTime: moment().startOf('day'),
  endTime: moment().endOf('day'),
  startDateTimeString: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
  endDateTimeString: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
};

export const useFiltersForm = () => {
  const { queryLogsCache } = useModulesContext();
  const cachedFilterForm = queryLogsCache.form;
  const [filterForm, _setFilterForm] = useState(cachedFilterForm || initialState);

  const setFilterForm = useCallback((value, name) => {
    _setFilterForm(s => ({ ...s, [name]: value }));
  }, []);

  useEffect(() => {
    queryLogsCache.setForm(filterForm);
  }, [filterForm, queryLogsCache]);

  useEffect(() => {
    _setFilterForm(s => ({
      ...s,
      startDateTimeString: s.beginDate.format('YYYY-MM-DD') + s.beginTime.format('THH:mm:ss'),
      endDateTimeString: s.endDate.format('YYYY-MM-DD') + s.endTime.format('THH:mm:ss'),
    }));
  }, [
    filterForm.beginDate,
    filterForm.beginTime,
    filterForm.endDate,
    filterForm.endTime,
    setFilterForm,
  ]);

  const resetFilterForm = useCallback(() => {
    _setFilterForm(initialState);
  }, []);

  return {
    filterForm,
    setFilterForm,
    resetFilterForm,
  };
};
