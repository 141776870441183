export class TransformSaveQueryRq {
  static transformRequest(query) {
    console.log(query);
    return {
      Columns: query.columns,
      Name: query.name,
      Parameters: (query.parameters || []).map(p => ({
        Value: p.value,
        Type: p.type,
        Name: p.name,
      })),
      Type: query.type || 'sql',
      State: query.state,
      Sql: query.sql,
      Tags: query.tags,
      UID: query.uid,
    };
  }

  static transformResponse(saveQueryResponse) {
    if (!saveQueryResponse.success) return saveQueryResponse;

    const { response } = saveQueryResponse;

    return {
      ...saveQueryResponse,
      response: {
        name: response.Name,
        ownerEmail: response.OwnerEmail,
        ownerUid: response.OwnerUID,
        parameters: (response.Parameters || []).map(p => ({
          value: p.Value,
          type: p.Type,
          name: p.Name,
        })),
        type: response.Type || 'sql',
        sql: response.Sql,
        state: response.State,
        tags: response.Tags,
        uid: response.UID,
      },
    };
  }
}
