import { useCallback, useState } from 'react';
import SDLC from '../../../../../../api/sdlc';

export const usePR = pullRequestId => {
  const [triggerTests, setTriggerTests] = useState(false);
  const [triggerBuild, setTriggerBuild] = useState(false);

  const [merging, setMerging] = useState(false);
  const [pullRequest, setPullRequest] = useState(null);

  const mergePullRequest = useCallback(() => {
    setMerging(true);
    setPullRequest(null);

    SDLC('MergePullRequest', {
      pullRequestId,
      triggerTests,
      triggerBuild,
    })
      .then(setPullRequest)
      .catch(console.error)
      .finally(() => {
        setMerging(false);
      });
  }, [pullRequestId, triggerBuild, triggerTests]);

  return {
    merging,
    pullRequest,
    mergePullRequest,
    triggerBuild,
    triggerTests,
    setTriggerBuild,
    setTriggerTests,
  };
};
