import * as Common from './common';
import * as Platform from './platform';
import * as Developer from './developer';
import * as Admin from './admin';
import * as User from './user';
import * as Entity from './entity';
import * as WDC from './wdc';
import * as Roadmunk from './roadmunk';

export const API = {
  Platform,
  Developer,
  Common,
  Admin,
  User,
  Entity,
  WDC,
  Roadmunk,
};
