export const MonthMap = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

export const RelativeDates = {
  value: 'relative',
  label: `Relative`,
  isLeaf: false,
  children: [
    {
      value: 'hours',
      label: 'Hours',
      isLeaf: false,
      children: [
        { value: '1', label: 'Last Hour', isLeaf: true },
        { value: '2', label: 'Last 2 Hours', isLeaf: true },
        { value: '3', label: 'Last 3 Hours', isLeaf: true },
        { value: '4', label: 'Last 4 Hours', isLeaf: true },
        { value: '5', label: 'Last 5 Hours', isLeaf: true },
        { value: '6', label: 'Last 6 Hours', isLeaf: true },
        { value: '7', label: 'Last 7 Hours', isLeaf: true },
        { value: '8', label: 'Last 8 Hours', isLeaf: true },
        { value: '9', label: 'Last 9 Hours', isLeaf: true },
        { value: '10', label: 'Last 10 Hours', isLeaf: true },
        { value: '11', label: 'Last 11 Hours', isLeaf: true },
        { value: '12', label: 'Last 12 Hours', isLeaf: true },
        { value: '13', label: 'Last 13 Hours', isLeaf: true },
        { value: '14', label: 'Last 14 Hours', isLeaf: true },
        { value: '15', label: 'Last 15 Hours', isLeaf: true },
        { value: '16', label: 'Last 16 Hours', isLeaf: true },
        { value: '17', label: 'Last 17 Hours', isLeaf: true },
        { value: '18', label: 'Last 18 Hours', isLeaf: true },
        { value: '19', label: 'Last 19 Hours', isLeaf: true },
        { value: '20', label: 'Last 20 Hours', isLeaf: true },
        { value: '21', label: 'Last 21 Hours', isLeaf: true },
        { value: '22', label: 'Last 22 Hours', isLeaf: true },
        { value: '23', label: 'Last 23 Hours', isLeaf: true },
      ],
    },
    {
      value: 'days',
      label: 'Days',
      isLeaf: false,
      children: [
        { value: '0', label: 'Today', isLeaf: true },
        { value: '1', label: 'Last 2 Days', isLeaf: true },
        { value: '2', label: 'Last 3 Days', isLeaf: true },
        { value: '3', label: 'Last 4 Days', isLeaf: true },
        { value: '4', label: 'Last 5 Days', isLeaf: true },
        { value: '5', label: 'Last 6 Days', isLeaf: true },
      ],
    },
    {
      value: 'weeks',
      label: 'Weeks',
      isLeaf: false,
      children: [
        { value: '0', label: 'This Week', isLeaf: true },
        { value: '1', label: 'Last 2 Weeks', isLeaf: true },
        { value: '2', label: 'Last 3 Weeks', isLeaf: true },
        { value: '3', label: 'Last 4 Weeks', isLeaf: true },
      ],
    },
    {
      value: 'months',
      label: 'Months',
      isLeaf: false,
      children: [
        { value: '0', label: 'This Month', isLeaf: true },
        { value: '1', label: 'Last 2 Months', isLeaf: true },
        { value: '2', label: 'Last 3 Months', isLeaf: true },
        { value: '3', label: 'Last 4 Months', isLeaf: true },
        { value: '4', label: 'Last 5 Months', isLeaf: true },
        { value: '5', label: 'Last 6 Months', isLeaf: true },
        { value: '6', label: 'Last 7 Months', isLeaf: true },
        { value: '7', label: 'Last 8 Months', isLeaf: true },
        { value: '8', label: 'Last 9 Months', isLeaf: true },
        { value: '9', label: 'Last 10 Months', isLeaf: true },
        { value: '10', label: 'Last 11 Months', isLeaf: true },
      ],
    },
  ],
};

export const CustomDates = {
  value: 'custom',
  label: `Custom Range`,
  isLeaf: true,
};

export const FixedDates = {
  value: 'fixed',
  label: `Fixed`,
  isLeaf: false,
  children: [{ value: 'ALL', label: 'All Years', isLeaf: true }],
};
