import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import { find } from 'lodash';
import SubMenu from '../SubMenu';

const AutoOpenSubMenu = props => {
  const appMatch = useRouteMatch({ path: '/app/:appId', exact: true });
  const match = useRouteMatch(`/app/:appId/:level/:moduleId`);

  let initiallyOpen = false;

  if (match) {
    // Remove trailing slash if it exists
    const matchUrl = match.url.endsWith('/') ? match.url.slice(0, -1) : match.url;

    initiallyOpen = !!find(props.items, item => item.path === matchUrl);
  } else if (appMatch) {
    initiallyOpen = true;
  }

  return <SubMenu {...props} initiallyOpen={initiallyOpen} />;
};

export default AutoOpenSubMenu;
