import { css } from 'styled-components';

export default css`
  &&& {
    background-color: var(--collapse-header-color);
    border-color: var(--collapse-main-background-color);

    border: none;

    .ant-collapse-header {
      background-color: var(--collapse-header-color);
      color: var(--collapse-header-text-color);
    }

    .ant-collapse-content {
      background-color: var(--collapse-content-color);
      border-color: var(--collapse-border-color);
    }

    .ant-collapse-content-box {
      padding: 0;
    }

    .ant-collapse-content-active {
      padding: 0;
    }
    .ant-collapse-item {
      border-color: var(--collapse-border-color);
    }
  }
`;
