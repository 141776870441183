import { css } from 'styled-components';

export default css`
  &&& {
    .action-section {
      display: flex;
      justify-content: flex-end;
      padding: 10px 24px;
    }
    .notification-items {
      //padding: 0 24px 10px 24px;
      padding: ${({ theme }) =>
        `0 ${theme.fontSizeMultiplier * 24}px 10px ${theme.fontSizeMultiplier * 24}px `};
    }
  }
`;
