const productionSettings = {
  region: 'us-east-2',
  identityPoolId: null,
  userPoolId: 'us-east-2_agOdsEDSr',
  appId: '2ghtghsvc57ka9f44l96iriglu',
  endpoints: {
    platform: 'https://connex-console-api-prod.commandalkon.io',
    sdlc: 'https://connex-sdlc.commandalkon.io',
  },
};

export default {
  connex: {
    localhost: {
      ...productionSettings,
      userPoolId: 'us-east-2_pEf1njwft',
      appId: '4gpoutshpmqjha556tbkkiere6',
      endpoints: {
        platform: 'http://localhost:9000',
        sdlc: 'https://connex-sdlc.commandalkon.io',
      },
    },
    'connex-console-develop-app': {
      ...productionSettings,
      userPoolId: 'us-east-2_pEf1njwft',
      appId: '4gpoutshpmqjha556tbkkiere6',
      endpoints: {
        platform: 'https://connex-console-api-develop.commandalkon.io',
        sdlc: 'https://connex-sdlc.commandalkon.io',
      },
    },
    'console-stage': {
      ...productionSettings,
      userPoolId: 'us-east-2_pEf1njwft',
      appId: '4gpoutshpmqjha556tbkkiere6',
      endpoints: {
        platform: 'https://connex-console-api-develop.commandalkon.io',
        sdlc: 'https://connex-sdlc.commandalkon.io',
      },
    },
    console: {
      ...productionSettings,
      isProduction: true,
    },
  },
};
