import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import SubMenuTitle from '../submenu-title';
import SubMenuItems from '../submenu-items';
import { useSidebarContext } from '../../index';
import { Tooltip, TOP_OFFSET } from '../tooltip/Tooltip';
import MenuItem from '../menu-item';
import style from './style';

const MenuItems = React.memo(({ handleItemSelection, items }) => {
  return items.map(item => (
    <Tooltip key={item.moduleId} text={item.description} topOffset={TOP_OFFSET.SUB_MENU}>
      <MenuItem handleItemSelection={handleItemSelection} module={item} className="submenu-item" />
    </Tooltip>
  ));
});

const SubMenu = ({ className, titleKey, items, handleItemSelection, initiallyOpen }) => {
  const { fixed, mode } = useSidebarContext();

  const [isOpen, setIsOpen] = useState(initiallyOpen);

  const toggle = useCallback(() => {
    setIsOpen(s => !s);
  }, []);

  return (
    <div className={cn('cx-submenu', fixed, mode, { open: isOpen }, className)}>
      {fixed || mode === 'drawer' ? (
        <>
          <SubMenuTitle titleKey={titleKey} onToggle={toggle} items={items} />
          <SubMenuItems className={cn({ open: isOpen })}>
            <MenuItems items={items} handleItemSelection={handleItemSelection} />
          </SubMenuItems>
        </>
      ) : isOpen ? (
        <MenuItems items={items} />
      ) : null}
    </div>
  );
};

// Since 'style' is interpolating something, this format is needed
// as opposed to : styled(SubMenu)(style);
export default styled(SubMenu)`
  ${style}
`;
