export default {
  moduleDisplayLabel: 'Профіль',
  oldPassword: 'Старий пароль',
  newPassword: 'Новий пароль',
  confirmNewPassword: 'Підтвердити новий пароль',
  email: 'Адреса електронної пошти',
  firstName: "Ім'я",
  lastName: 'Прізвище',
  phoneNumber: 'Номер телефону',
  preferredMfa: 'Бажаний тип MFA',
  qrCodeInstruction: 'Відскануйте цей QR-код або введіть секретний ключ у програмі.',
  mfaTitle: 'Налаштуйте програму автентифікації для створення кодів',
  mfaConfirm: 'Введіть код безпеки, сформований програмою для підтвердження.',
  EnableSoftwareTokenMFAException: 'Недійсний код безпеки',
  'tab.general': 'загальний',
  'tab.mfa': 'MFA',
  'tab.change.password': 'Змінити пароль',
  reenroll: 'повторно зареєструватися',

  validation: {
    required: "Це поле є обов'язковим.",
    password: {
      minLength: 'Мінімальна довжина - {arg1}',
      required: "Це поле є обов'язковим.",
    },
    confirmPasswordRequired: "Це поле є обов'язковим.",
    passwordsMustMatch: 'Паролі повинні збігатися.',
  },
};
