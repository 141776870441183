export default {
  Attributes: 'Attributes',
  Duration: 'Duration',
  Entity: 'Entity',
  JSON: 'JSON',
  StatementName: 'Statement Name',
  Timestamp: 'Timestamp',
  Row_Count: 'Row Count',
  SQL: 'SQL',
  State: 'State',
  Status: 'Status',
  Name: 'Name',
  Creator: 'Creator',
  Modified: 'Modified',
  Tags: 'Tags',
  Actions: 'Actions',
  UserName: 'UserName',
  ContainerName: 'Container Name',
};
