export default {
  appName: 'Розробник',
  builds: 'Будує',
  metrics: 'Метрики',
  'data-explorer': 'Редактор запитів',
  'audit-logs': 'Журнали',
  'api-docs': 'Документи Api',
  'developer-dashboard': 'Панель приладів',
  data: 'Дані',
  api: 'API',
  'api-metrics': 'Показники API',
  integrations: 'Інтеграції',
  datasets: 'Набори даних',
  queries: 'Збережені запити',
  save: 'Зберегти',
  run: 'діяти',
  format: 'форматувати',
  public: 'Відкритий',
  saveQuery: 'Зберегти запит',
  saveAsQuery: 'Запит - Зберегти як',
  name: "Ім'я",
  elapsedTime:
    'Знайдено {itemCount, plural, =0 {0 предметів} one {1 предмет за} other {{itemCount} предметів}} ({size} {sizeType}) за {runTime, plural, =0 {0,00 секунди} one {1 секунда} other {{runTime} секунд}}',
  Results: 'Результати',
  Parameters: 'Параметри',
  AddNewParameter: 'Додати новий параметр',
  Details: 'Деталі',
  Metrics: 'Метрики',
  Edit: 'Редагувати запит',
  Columns: 'Стовпці',
  Visibility: 'Видимість',
  sdlc: 'SDLC',
  'pull-requests': `Demandes d'extraction`,
};
