import { css } from 'styled-components';

export default css`
  margin-top: 5%;

  --amplify-font-family: 'Fira Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;

  &.appear {
    opacity: 0.01;
  }

  &.appear.appear-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }

  .CONNEX-Login {
    display: flex;
    justify-content: center;
  }

  .logo {
    display: flex;
    justify-content: center;
    margin: 5% 0;

    .st0 {
      fill: var(--logoColor);
      transition: fill 3s cubic-bezier(0, 0.54, 0.42, 0.77) !important;
    }
    .st1 {
      fill: #e04e38;
    }
    .st2 {
      display: none;
    }
    .st3 {
      display: inline;
    }
    .st4 {
      fill: #ed5338;
    }
  }
`;
