import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';

const Component = ({ className, children }) => {
  return <h3 className={cn('view-header', className)}>{children}</h3>;
};

export default styled(Component)(style);
