import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import propTypes from 'prop-types';
import TagGroup from './TagGroup';
import style from './style';

const Tabs = ({ className, ...props }) => {
  return <TagGroup className={cn(className)} {...props} />;
};

Tabs.propTypes = {
  className: propTypes.string,
};

export default styled(Tabs)(style);
