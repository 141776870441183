import { css } from 'styled-components';

export default css`
  .cx-spinner {
    margin-top: 10%;
  }
  .panel {
    padding: 10px;
    background-color: #1f1f1f; //var(--baseBackgroundColor);
    .entity-selection {
      display: flex;
      > :first-child {
        margin-right: 10px;
      }
    }

    textarea {
      flex: 1;
      background-color: #1f1f1f;
      margin: 5px;
      border: none;
      font-family: monospace;
    }
  }
  .cx-tabs {
    &.data-set-details {
      display: flex;
      flex-direction: column;
      flex: 1;
      min-height: 0;
      min-width: 0;
      .ant-tabs-content-holder {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 0;
        min-width: 0;
      }
      .ant-tabs-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-height: 0;
        min-width: 0;
        border: 1px solid #717273;
        border-top: none;
        .ant-tabs-tabpane {
          display: flex;
          flex-direction: column;
          flex: 1;
          min-height: 0;
          min-width: 0;
          .inner-wrapper {
            &::-webkit-scrollbar {
              display: none;
            }
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }
        }
      }
    }
  }
`;
