import React, { useCallback } from 'react';
import cn from 'classnames';
import { useHistory, useRouteMatch } from 'react-router-dom';
import ProfileMenu from '../profile-menu';
import styles from './Header.module.scss';
import { useSidebarContext } from '../sidebar';
import AppSwitcher from '../app-switcher';
import { MOUNT_POINT } from '../../constants';
import Text from '../../../components/typography/text';
import { MODE } from '../sidebar/constants';
import NotificationLogIcon from './NotificationLogIcon';

export const Header = ({ className }) => {
  const { mode, toggleDrawer } = useSidebarContext();
  const history = useHistory();

  const match = useRouteMatch('/app/:appId');

  const handleSelection = useCallback(
    appId => {
      history.push(`/app/${appId}`);
    },
    [history]
  );

  return (
    <div className={cn('cx-header', styles.header, { small: mode === MODE.DRAWER }, className)}>
      <div className="left-side">
        <div
          className={cn(
            'cx-drawer-toggle',
            { [`${styles.visible}`]: mode === 'drawer' },
            styles.drawerToggle
          )}
          onClick={toggleDrawer}
        >
          <i className="icon fas fa-bars" />
        </div>
        <div className="app-switcher-wrapper">
          <AppSwitcher mountPoint={MOUNT_POINT.APP_SWITCHER} onSelection={handleSelection} />
        </div>

        {match && (
          <div className="active-app-label" data-testid="appLabel">
            <Text text={`${match.params.appId}.appName`} />
          </div>
        )}
      </div>
      <div className="right-side">
        <div className="notification-log-icon-container">
          <NotificationLogIcon />
        </div>
        <div className="user-info" data-testid="userInfo">
          <ProfileMenu />
        </div>
      </div>
    </div>
  );
};
