import { css } from 'styled-components';

// TODO: theming

export default css`
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #444;

  .heading {
    font-size: 1.2em;
    font-weight: bold;
    background-color: #444;
    display: flex;
    align-items: center;
    .left {
      flex: 1;
      padding-left: 10px;
    }
    .right {
      padding: 5px 10px;
    }
  }
`;
