import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Empty as AntEmpty } from 'antd';
import propTypes from 'prop-types';
import style from './style';
import { useIntlUtils } from 'components/i18n/useIntlUtils';

const Empty = ({ className, description = 'emptyText' }) => {
  const { translate } = useIntlUtils();

  const translatedDescription = React.useMemo(() => {
    return translate(description);
  }, [translate, description]);

  return (
    <div className={cn('cx-empty', className)}>
      <AntEmpty description={translatedDescription} />
    </div>
  );
};

Empty.propTypes = {
  className: propTypes.string,
  description: propTypes.string,
};

export default styled(Empty)`
  ${style}
`;
