import styled from 'styled-components';
import { SIDEBAR_WIDTH_PX } from '../../constants';

export default styled.div`
  display: flex;
  flex: 1;
  color: var(--sidebarBaseTextColor);
  .cx-content {
    transition: margin-left 0.3s;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .cx-sidebar-menu {
    position: absolute;
    overflow: hidden;
    transition: all 0.3s;
    background-color: var(--sidebarBackgroundColor);
    z-index: 100;
    &.drawer {
      border-right: var(--sidebarDrawerBorderRight);
    }
  }

  &.drawer.open {
    .cx-content {
      filter: blur(2px);
      //opacity: 30%;
      transition: filter 1s;
    }
  }

  &.drawer {
    .cx-content {
      margin-left: 0;
    }
    .cx-sidebar-menu {
      top: 0;
      bottom: 0;
      left: -${SIDEBAR_WIDTH_PX}px;
      width: ${SIDEBAR_WIDTH_PX}px;
      &.open {
        left: 0;
      }
    }
  }

  &.fixed {
    transition: left 0.3s;
    &.fixed-full {
      .cx-content {
        margin-left: ${SIDEBAR_WIDTH_PX}px;
      }
      .cx-sidebar-menu {
        top: 0;
        bottom: 0;
        left: 0;
        width: ${SIDEBAR_WIDTH_PX}px;
      }
    }
    &.fixed-icon {
      .cx-content {
        margin-left: 50px;
      }
      .cx-sidebar-menu {
        top: 0;
        bottom: 0;
        left: 0;
        width: 50px;
      }
    }
  }
`;
