export default {
  cancel: 'Cancel',
  'closed-pull-requests': 'Closed Pull Requests',
  'continue-pull-request': 'Continue',
  'confirm-pull-request-modal-title': 'Merge Pull Request - Confirm',
  'confirm-pull-request-modal-message': 'Are you sure you want to merge this pull request?',
  'confirm-pull-request-modal-confirmed-button-label': 'Yes',
  'confirm-pull-request-modal-cancel-button-label': 'Cancel',
  'create-pull-request': 'Create Pull Request',
  'merge-pull-request': 'Merge Pull Request',
  'cancel-merge-pull-request': 'Cancel',
  destination: 'Destination',
  jiraDetails: 'Jira Issue Details',
  pullRequestDetails: 'Pull Request Details',
  pullRequestReview: 'Review & Approve Pull Request',
  'open-pull-requests': 'Open Pull Requests',
  'pull-request-details': 'Pull Request Details',
  refresh: 'Refresh',
  repository: 'Repository',
  search: 'Search',
  select: 'Select...',
  sourceBranch: 'Source Branch',
  status: 'Status',
  targetBranch: 'Target Branch',
  viewHeader: 'Pull Requests',
  withBuild: 'Build',
  withoutBuild: 'No Build',
  withTests: 'Tests',
  withoutTests: 'No Tests',
};
