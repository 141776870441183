import { css } from 'styled-components';

// H1, H2, etc. sizes in EMs.
const LEVEL = {
  1: 2,
  2: 1.5,
  3: 1.17,
  4: 1,
  5: 0.83,
};

export default css`
  &&& {
    color: var(--baseTextColor);
    font-size: ${({ level = 1, theme }) => `${LEVEL[level] * theme.fontSizeMultiplier}rem`};
    transition: all 0.3s;
  }
`;
