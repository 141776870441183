import { css } from 'styled-components';

export default css`
  &&& {
    background: var(--card-background-color);
    border: 1px solid var(--card-border-color);
    transition: all 0.3s;
    .ant-card-head {
      border-bottom-color: var(--card-border-color);
      color: var(--card-base-color);
      transition: all 0.3s;
    }
  }
`;
