import React from 'react';
import { find } from 'lodash';
import { useUserContext } from '../../contexts/user/UserContextProvider';
import Result, { STATUS } from '../result';
import { useAppsConfigContext } from '../../components/app-config/AppsConfigContext';

const AppPermission = ({ appId, children }) => {
  const { hasAppPermission } = useUserContext();
  const { appsConfig } = useAppsConfigContext();

  const targetApp = React.useMemo(() => find(appsConfig, app => app.id === appId), [
    appsConfig,
    appId,
  ]);

  const isPermitted = React.useMemo(() => targetApp && hasAppPermission(targetApp), [
    hasAppPermission,
    targetApp,
  ]);

  return isPermitted ? children : <Result title="appNotPermitted" status={STATUS.ERROR} />;
};

export default AppPermission;
