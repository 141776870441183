export default {
  id: 'sandbox-dashboard',
  icon: 'chart-bar',
  description: 'Dashboard',
  moduleId: 'sandbox-dashboard',
  feature: {
    id: 'FE-0000',
    description: 'Sandbox Dashboard',
    url: 'https://jira.commandalkon.com/browse/FE-0000',
  },
  params: {},
  hidden: true,
};
