import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import propTypes from 'prop-types';
import { Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import style from './style';

const { Title: AntTitle } = Typography;

const Title = ({ className, text, values, level = 1 }) => {
  return (
    <AntTitle className={cn(className)} level={level}>
      <FormattedMessage id={text} values={values} />
    </AntTitle>
  );
};

Title.propTypes = {
  className: propTypes.string,
  text: propTypes.string,
  values: propTypes.oneOfType([propTypes.string, propTypes.object]),
  level: propTypes.number,
};

Title.defaultProps = {
  level: 1,
};

export default styled(Title)`
  ${style}
`;
