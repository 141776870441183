import { css } from 'styled-components';

export default css`
  .cx-spinner {
    margin-top: 10%;
  }
  padding: 10px;

  .ant-drawer-header {
    padding: 5px;
  }

  .ant-card-head {
    padding: 0;
  }
  .ant-card-head-title {
    padding: 0;
  }
  .actions {
    display: flex;
    align-items: center;
    .attributes {
      & > * {
        margin-right: 10px;
      }
    }
    .buttons {
      flex: 1;
      text-align: right;
      & > * {
        margin-left: 10px;
      }
    }
  }
`;
