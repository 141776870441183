import React from 'react';
import cx from 'classnames';
import ViewHeader from '../../../../../components/headers/view-header';

export const Dashboard = ({ className }) => {
  return (
    <div className={cx(className)}>
      <ViewHeader>Accounting Dashboard</ViewHeader>
    </div>
  );
};

export default Dashboard;
