import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Tabs as AntTabs } from 'antd';
import style from './style';

export const { TabPane } = AntTabs;

const Tabs = ({ className, children, ...props }) => {
  return (
    <AntTabs className={cn('cx-tabs', className)} animated={false} {...props}>
      {children}
    </AntTabs>
  );
};

Tabs.TabPane = TabPane;

Tabs.propTypes = {
  className: propTypes.string,
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.element),
    propTypes.element,
    propTypes.string,
  ]),
};

export default styled(Tabs)`
  ${style}
`;
