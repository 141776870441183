import React from 'react';
import cn from 'classnames';

import CustomTitle from '../custom-title';
import { Button } from '../../../../../../../components';

import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import Text from '../../../../../../../components/typography/text';

const Component = ({ className }) => {
  return (
    <div className={cn('entity-search', className)}>
      <CustomTitle>
        <Text text="configure.entity.search" />
      </CustomTitle>

      <Button type="primary">
        <FormattedMessage id={'configure.search'} />
      </Button>
    </div>
  );
};

export default styled(Component)`
  margin: 20px;
  text-align: center;
  padding: 30px;
  border: white; // move to var
`;
