import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import sqlFormatter from 'sql-formatter';
import style from './style';
import { Button, CodeEditor, Tabs, Text } from 'components/index';
import FlexColumn from 'components/layout-flex-column';

const copyToClipboard = data => {
  const temporaryInput = document.createElement('textarea');
  temporaryInput.value = data;
  document.body.appendChild(temporaryInput);
  temporaryInput.select();
  document.execCommand('copy');
  document.body.removeChild(temporaryInput);
};

const ShowSql = ({ className, data, moduleHandoff }) => {
  const formattedSql = useMemo(() => sqlFormatter.format(data.SQL), [data]);

  const editQueryHandoff = useCallback(
    query => {
      moduleHandoff.to({
        appId: 'developer',
        moduleId: 'data-explorer',
        state: { type: 'query-log-item', query },
      });
    },
    [moduleHandoff]
  );

  return (
    <FlexColumn className={cn(className)}>
      <Tabs
        className="audit-logs"
        defaultActiveKey={'SQL'}
        tabBarStyle={{ marginLeft: 12 }}
        tabBarExtraContent={
          <div>
            <Button label="audit-logs.edit-query" onClick={() => editQueryHandoff(data)} />
          </div>
        }
      >
        <Tabs.TabPane tab={<Text text="audit-logs.SQL" />} key="SQL">
          <CodeEditor dark defaultValue={formattedSql} mode="sql" disabled showLineNumbers />

          <div style={{ margin: 12 }}>
            <Button
              icon={<i className="fa fa-copy" />}
              title="audit-logs.copy-sql"
              label="audit-logs.copy-sql"
              onClick={() => copyToClipboard(formattedSql)}
            />
          </div>
        </Tabs.TabPane>
        {data.parameters && (
          <Tabs.TabPane tab={<Text text="audit-logs.parameters" />} key="Parameters">
            <CodeEditor
              dark
              defaultValue={JSON.stringify(data.parameters, null, 2)}
              mode="javascript"
              disabled
              showLineNumbers
            />
            <div style={{ margin: 12 }}>
              <Button
                icon={<i className="fa fa-copy" />}
                title="audit-logs.copy-parameters"
                label="audit-logs.copy-parameters"
                onClick={() => copyToClipboard(JSON.stringify(data.parameters, null, 2))}
              />
            </div>
          </Tabs.TabPane>
        )}
      </Tabs>
    </FlexColumn>
  );
};

export default styled(ShowSql)`
  ${style}
`;
