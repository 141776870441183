import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Statistic as AntStatistic } from 'antd';
import propTypes from 'prop-types';
import style from './style';
import { useIntl } from 'react-intl';

const Statistic = ({ className, title, ...props }) => {
  const { formatMessage } = useIntl();

  const i18nLabel = React.useMemo(() => formatMessage({ id: title }), [title, formatMessage]);

  return <AntStatistic className={cn('cx-statistics', className)} title={i18nLabel} {...props} />;
};

Statistic.propTypes = {
  className: propTypes.string,
  title: propTypes.string,
};

export default styled(Statistic)`
  ${style}
`;
