import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import { useIntl } from 'react-intl';

const ModuleSpinner = ({ module, className }) => {
  const { formatMessage } = useIntl();

  return (
    <div className={cn('cx-module-loading', className)}>
      <LoadingOutlined />
      {module && (
        <div className="cx-module-loading-text">{`Loading ${formatMessage({
          id: `${module.id}.moduleDisplayLabel`,
        })}...`}</div>
      )}
    </div>
  );
};

export default styled(ModuleSpinner)`
  text-align: center;
  margin-top: 10%;
  .anticon-loading {
    font-size: 2em;
  }
`;
