export default {
  id: 'airticket',
  moduleId: 'airticket',
  description: 'AirTicket',
  modules: [
    {
      id: 'airticket-metrics',
      icon: 'chart-bar',
      description: 'Metrics',
      moduleId: 'airticket-metrics',
      feature: {
        id: 'FE-1506',
        description: 'AirTicket Integration (Metrics)',
        url: 'https://jira.commandalkon.com/browse/FE-1506',
      },
    },
    {
      id: 'airticket-configure',
      icon: 'table',
      description: 'Configure',
      moduleId: 'airticket-configure',
      feature: {
        id: 'FE-1506',
        description: 'AirTicket Integration (Configuration)',
        url: 'https://jira.commandalkon.com/browse/FE-1506',
      },
    },
  ],
};
