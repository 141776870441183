import { useCallback, useState } from 'react';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import useHandleApiCall from 'util/handle-api-call';
import { API } from 'api/index';
import { useModulesContext } from 'core/contexts/modules';

const GetSQLEvents = API.Developer.Sql.GetSQLEventsRq;

const identifyResults = results => {
  return results.map(result => ({ ...result, __id: uuid() }));
};

export const useExecuteQuery = () => {
  const { queryLogsCache } = useModulesContext();
  const cachedResults = queryLogsCache.results;

  const [getQueries, queriesBusy, cancelQueries] = useHandleApiCall(GetSQLEvents);
  const [results, setResults] = useState(cachedResults);

  const setActiveRow = useCallback(
    id => {
      const target = _.find(results, r => r.id === id);

      if (target) {
        setResults(s =>
          s.map(result => {
            if (result.id === id) {
              return { ...result, __active: true };
            }
            return { ...result, __active: false };
          })
        );
      }
    },
    [results]
  );

  const executeQuery = useCallback(
    ({
      duration,
      entity,
      itemCount,
      statementName,
      status,
      startDateTimeString: startDate,
      endDateTimeString: endDate,
      limit,
      containerName,
      userName,
    }) => {
      setResults([]);
      queryLogsCache.setResults(null);
      queryLogsCache.setItemCount(0);
      queryLogsCache.setPagination({});

      getQueries({
        duration,
        entity,
        itemCount,
        statementName,
        status,
        startDate,
        endDate,
        containerName,
        userName,
        limit,
      }).then(results => {
        const items = identifyResults(results.response.Items || []);
        queryLogsCache.setResults(items);
        setResults(items);
      });
    },
    [getQueries, queryLogsCache]
  );

  return { executeQuery, results, setActiveRow, loading: queriesBusy };
};

export default useExecuteQuery;
