import { css } from 'styled-components';

export default css`
  &&& {
    background-color: var(--dropdown-menu-background-color);
    border: var(--dropdown-menu-border);
    box-shadow: var(--dropdown-menu-box-shadow);
    min-width: 150px;
    .cx-menu-item {
      display: flex;
      align-items: center;

      .text-label {
        width: 100%;
      }

      .icon {
        margin-right: 1em;
        display: flex;
        color: var(--baseTextColor);
      }

      &.text-item {
        margin-top: 0;
        margin-bottom: 0;
        background-color: var(--profile-dropdown-menu-header-background-color) !important;
        color: var(--baseTextColor);

        border-bottom: var(--profile-dropdown-menu-header-border-bottom);

        &.ant-menu-item-active {
          &:hover {
            background-color: inherit;
            cursor: default;
            color: var(--baseTextColor);
          }
        }
        &.ant-menu-item-selected {
          color: var(--baseTextColor);
        }
      }

      &:not(.active) {
        font-weight: normal; //var(--dropdown-menu-item-font-weight);
        background-color: var(--dropdown-menu-item-background-color);
        .label {
          .cx-text {
            color: var(--dropdown-menu-item-color);
            transition: all 0.3s;
          }
        }
        &:hover {
          background-color: var(--dropdown-menu-item-hover-background-color);
          .label {
            .cx-text {
              color: var(--dropdown-menu-item-hover-color);
            }
          }
        }
      }

      &.active {
        font-weight: var(--dropdown-menu-item-active-font-weight);
        background-color: var(--dropdown-menu-item-active-background-color);
        .label {
          .cx-text {
            color: var(--dropdown-menu-item-active-color);
            transition: color 0.3s;
          }
        }

        &:hover {
          background-color: var(--dropdown-menu-item-active-hover-background-color);
          .label {
            .cx-text {
              color: var(--dropdown-menu-item-active-hover-color);
              transition: color 0.3s;
            }
          }
        }
      }
    }
  }
`;
