import React, { useCallback, useEffect, useState } from 'react';
import { find, values } from 'lodash';
import { useSidebarContext } from '../sidebar/components/sidebar-context';
import { useAppsConfigContext } from '../app-config/AppsConfigContext';
import { appDefinitions } from '../../apps';

const prepareAppDefinitions = apps => {
  const getAppDefinition = appId => find(values(appDefinitions), def => def.id === appId);

  return apps.map(app => {
    return { ...app, appConfig: getAppDefinition(app.id) };
  });
};

export const useAppSwitcher = mountPoint => {
  const [visible, setVisible] = useState(false);
  const { mode } = useSidebarContext();
  const iconRef = React.createRef();
  const { apps } = useAppsConfigContext(mountPoint);
  const [style, setStyle] = useState({});

  const appSwitcherApps = React.useMemo(() => prepareAppDefinitions(apps), [apps]);
  const enabled = React.useMemo(() => appSwitcherApps.length > 1, [appSwitcherApps]);

  const showAppSwitcher = useCallback(() => {
    if (enabled) setVisible(true);
  }, [enabled]);

  const hideAppSwitcher = useCallback(() => {
    if (enabled) setVisible(false);
  }, [enabled]);

  const toggleAppSwitcher = useCallback(() => {
    if (enabled)
      setVisible(s => {
        if (!s) {
          const dimensions = iconRef.current.getBoundingClientRect();
          setStyle(currentState => {
            return {
              ...currentState,
              left: dimensions.left - 10,
              top: dimensions.bottom + 2,
            };
          });

          showAppSwitcher();
        } else {
          hideAppSwitcher();
        }
      });
  }, [enabled, iconRef, showAppSwitcher, hideAppSwitcher]);

  useEffect(() => {
    hideAppSwitcher();
  }, [mode, hideAppSwitcher]);

  useEffect(() => {
    if (visible) {
      // wait a tick before setting opacity.
      setTimeout(() => {
        setStyle(s => {
          return { ...s, opacity: 1 };
        });
      });
    } else {
      setStyle(s => {
        return { ...s, opacity: 0 };
      });
    }
  }, [visible]);

  useEffect(() => {}, [iconRef]);

  return {
    style,
    appSwitcherApps,
    iconRef,
    mode,
    hideAppSwitcher,
    toggleAppSwitcher,
    visible,
    enabled,
  };
};

export default useAppSwitcher;
