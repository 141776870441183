import React, { useEffect, useState } from 'react';
import { connect, wdcInit } from './lifecycle';
import { Button, Form, Select } from 'antd';
import { Auth } from 'aws-amplify';
import { Input, Spinner } from '../../components';
import WDC from '../../api/wdc';

import '../../../node_modules/antd/dist/antd.dark.min.css';

const { Option } = Select;

const isLocal = window.location.hostname.includes('localhost');

export const Wdc = () => {
  const [busy, setBusy] = useState(false);
  const [connecting] = useState(false);
  const [signedIn, setSignedIn] = useState(false);
  const [privateKey, setPrivateKey] = useState(null);
  const [username, setUsername] = useState((isLocal && 'wdc@commandalkon.com') || null);
  const [password, setPassword] = useState((isLocal && '') || null);
  const [entityRef, setEntityRef] = useState(null);
  const [entities, setEntities] = useState([]);
  const [dataSets, setDataSets] = useState(null);
  const [dataSet, setDataSet] = useState(null);
  const [connectionName, setConnectionName] = useState(null);
  const [entityName, setEntityName] = useState(null);
  const [params, setParams] = useState([]);
  const [iterator, setIterator] = useState(null);
  const [userAlias, setUserAlias] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const ready = username && password && password.length >= 8;

  useEffect(() => {
    wdcInit();
  }, []);

  const login = () => {
    setErrorMessage(null);
    setPrivateKey(null);
    setBusy(true);

    Auth.signIn({
      username: username,
      password: password,
    })
      .then(session => {
        const { name, given_name = '', family_name = '' } = session.attributes;

        setUserAlias(name || `${given_name} ${family_name}`);

        WDC('connex', {
          Action: 'authorize',
          Email: username,
          UserName: session.username,
        })
          .then(Rs => {
            setEntities(Rs.Entities || []);
            setPrivateKey(Rs.PrivateKey);
            setSignedIn(true);
            setBusy(false);
          })
          .catch(e => {
            setErrorMessage(`Error: ${e.message}`);
            setSignedIn(false);
            setBusy(false);
          });
      })
      .catch(e => {
        setErrorMessage(e.message || 'Invalid username or password');

        setBusy(false);

        setSignedIn(false);
      });
  };

  const setDataSource = entityRef => {
    setEntityRef(entityRef);

    setEntityName(entities.find(e => e.crn === entityRef).name);

    setErrorMessage(null);

    setBusy(true);

    WDC(entityRef, {
      Action: 'getDataSets',
      EID: entityRef,
    })
      .then(Rs => {
        setBusy(false);

        setDataSets(Rs.Schemas || []);
      })
      .catch(e => {
        setBusy(false);

        setErrorMessage(`Error: ${e.message}`);
      });
  };

  const configureDataSet = id => {
    setDataSet(id);

    const ds = dataSets.find(d => d.ID === id);

    if (ds) {
      setIterator(ds.Iterator || null);

      if (!ds.Params || !Array.isArray(ds.Params)) {
        ds.Params = [];
      }

      let limit = ds.Params.find(p => p.name === 'Limit');

      if (!limit) {
        limit = { name: 'Limit', type: 'int', value: 1000 };

        ds.Params.push(limit);
      }

      setParams(ds.Params || []);

      setConnectionName(`${entityName} (${ds.Description})`);
    }
  };

  const updateParam = (name, value) => {
    const param = params.find(p => p.name === name);

    param.value = value;
  };

  const createConnection = async () => {
    await connect(
      entityRef,
      username,
      password,
      userAlias,
      dataSet,
      params,
      iterator,
      connectionName,
      privateKey
    );
  };

  return (
    <div style={{ padding: '25px' }}>
      {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

      {!signedIn && (
        <Form layout={'vertical'}>
          <Form.Item label="User Name">
            <Input
              value={username}
              type={'text'}
              onChange={e => setUsername(e.currentTarget.value)}
            />
          </Form.Item>
          <Form.Item label="Password" labelAlign={'top'}>
            <Input
              value={password}
              type={'password'}
              onChange={e => setPassword(e.currentTarget.value)}
            />
          </Form.Item>
          <div>
            {ready && (
              <Button onClick={login} loading={busy}>
                Login
              </Button>
            )}
          </div>
        </Form>
      )}

      {signedIn && (
        <Form layout={'vertical'} size={'small'}>
          <Form.Item label="Data Source">
            <Select placeholder={'Select Data Source...'} onChange={setDataSource}>
              {entities.map((e, index) => {
                return (
                  <Option key={index} value={e.crn}>
                    {e.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>

          {dataSets && (
            <Form.Item label="Data Set" labelAlign={'top'}>
              <Spinner spinning={busy} size={'small'}>
                <Select
                  placeholder={'Select Data Set...'}
                  disabled={!dataSets}
                  onChange={configureDataSet}
                >
                  {dataSets.map((d, index) => {
                    return (
                      <Option key={index} value={d.ID}>
                        {d.Description}
                      </Option>
                    );
                  })}
                </Select>
              </Spinner>
            </Form.Item>
          )}

          {dataSet && (
            <Form.Item label="Connection Name">
              <Input
                value={connectionName}
                type={'text'}
                onChange={e => setConnectionName(e.currentTarget.value)}
              />
            </Form.Item>
          )}

          {params.map((p, index) => {
            return (
              <Form.Item key={index} label={p.name}>
                <Input
                  defaultValue={p.value}
                  type={'text'}
                  onChange={e => updateParam(p.name, e.currentTarget.value)}
                />
              </Form.Item>
            );
          })}

          {dataSet && (
            <Form.Item>
              <Button onClick={createConnection} loading={connecting}>
                Connect!
              </Button>
            </Form.Item>
          )}
        </Form>
      )}
    </div>
  );
};

export default Wdc;
