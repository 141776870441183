export default {
  appName: 'Platform Support',
  'pa-dashboard': 'Dashboard',
  users: 'Users',
  metrics: 'Metrics',
  'profile-search': 'Profile Search',
  organizations: 'Organizations',
  entities: 'Entities',
  'ps-entities-metrics': 'Metrics',
  'ps-entities-search': 'Search Entities',
};
