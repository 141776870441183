export default {
  moduleDisplayLabel: 'Examples',
  customButton: 'Custom Button',
  defaultButton: 'Default Button',
  default: 'За замовчуванням',
  'default.disabled': 'За замовчуванням вимкнено',
  'default.loading': ' Завантаження за замовчуванням',
  primary: 'Первинна',
  'primary.loading': 'Первинне завантаження',
  dashed: 'Штриховий',
};
