import { css } from 'styled-components';

export default css`
  margin-top: 10px;

  table {
    width: 100%;
  }

  td {
    padding: 5px;
  }

  tr {
    vertical-align: top;
  }

  .header {
    font-weight: bold;
  }

  .ant-card-head-wrapper {
    padding: 10px;
  }
  .ant-card-body {
    padding: 10px;
  }
`;
