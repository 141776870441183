import React, { useEffect } from 'react';
import { cache } from '../../util';
import { BASE_FONT_SIZE_PX } from '../components/sidebar/constants';
import { ThemeContext, ThemeProvider } from 'styled-components';
import fontSizes from '../components/app-font-size-switcher/fontSizes';

export const useThemeContext = () => {
  return React.useContext(ThemeContext);
};

const loadTheme = themeId => {
  if (!themeId) return;

  // Import the css, then set the theme classname in the body tag.
  return import(`../../themes/themes/${themeId}/theme.scss`)
    .then(() => {
      const bodies = document.getElementsByTagName('BODY');
      if (bodies.length === 1) {
        const body = bodies[0];

        const classes = body.classList.values();

        // we don't know the previous classname, so remove anything
        // that looks like a theme classname.
        for (var value of classes) {
          if (value.startsWith('__theme__')) {
            body.classList.remove(value);
          }
        }
        body.classList.add(`__theme__${themeId}`);
      }
    })
    .catch(e => {
      //couldn't load the theme.
      console.log('e', e);
    });
};

export const Theme = ({ defaultTheme, children }) => {
  const [themeId, setThemeId] = React.useState(cache.getItem('cx.theme') || defaultTheme || 'dark');

  const [fontSize, setFontSize] = React.useState(cache.getItem('cx.fontSize') || 'medium');

  const [resolvedFontSize, setResolvedFontSize] = React.useState(
    BASE_FONT_SIZE_PX * fontSize.multiplier
  );

  useEffect(() => {
    setResolvedFontSize(BASE_FONT_SIZE_PX * fontSizes[fontSize].multiplier);
    cache.setItem('cx.fontSize', fontSize);
  }, [fontSize]);

  useEffect(() => {
    cache.setItem('cx.theme', themeId);
    loadTheme(themeId);
  }, [themeId]);

  return (
    <ThemeProvider
      theme={{
        themeId,
        setThemeId,
        fontSize,
        setFontSize,
        resolvedFontSize,
        fontSizeMultiplier: fontSizes[fontSize].multiplier,
      }}
    >
      {children}
    </ThemeProvider>
  );
};
