export default {
  id: 'command-assurance',
  moduleId: 'command-assurance',
  description: 'COMMANDassurance',
  modules: [
    {
      id: 'command-assurance-metrics',
      icon: 'chart-bar',
      description: 'Metrics',
      moduleId: 'command-assurance-metrics',
      feature: {
        id: 'FE-1644',
        description: 'COMMANDassurance (Metrics)',
        url: 'https://jira.commandalkon.com/browse/FE-1644',
      },
    },
    {
      id: 'command-assurance-configure',
      icon: 'table',
      description: 'Configure',
      moduleId: 'command-assurance-configure',
      feature: {
        id: 'FE-1644',
        description: 'COMMANDassurance (Configuration)',
        url: 'https://jira.commandalkon.com/browse/FE-1644',
      },
    },
  ],
};
