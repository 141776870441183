import { useIntl } from 'react-intl';
import { Button, Col, Divider, Row, Skeleton } from 'antd';
import React from 'react';
import Widget from '../../../../../../../../../components/widget';

const Waiting = () => {
  return <Skeleton className="waiting" size="small" active title paragraph={{ rows: 4 }} />;
};

export default ({
  loading,
  icon,
  headline,
  total,
  leftTitle,
  rightTitle,
  leftButton,
  rightButton,
}) => {
  const { formatNumber } = useIntl();

  return (
    <Widget>
      {(!loading && (
        <div className="details">
          <Row>
            <Col span={24}>
              <span className={`icon fa fa-${icon}`} />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="headline">{`${formatNumber(total)}`}</div>
              <div>{headline}</div>
              <Divider />
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div>{leftTitle}</div>
              <div>
                <Button>{leftButton}</Button>
              </div>
            </Col>
            <Col span={12}>
              <div>{rightTitle}</div>
              <div>
                <Button>{rightButton}</Button>
              </div>
            </Col>
          </Row>
        </div>
      )) || <Waiting />}
    </Widget>
  );
};
