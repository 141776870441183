import { SizeMe } from 'react-sizeme';
import AreaChart from './AreaChart';
import FlexColumn from 'components/layout-flex-column';

export default ({ data, onClick }) => {
  return (
    <FlexColumn>
      <SizeMe monitorHeight monitorWidth refreshRate={100}>
        {({ size }) => (
          <div style={{ width: `100%` }}>
            <AreaChart
              onClick={onClick}
              yAxis={false}
              width={size.width}
              height={300}
              data={data}
            />
          </div>
        )}
      </SizeMe>
    </FlexColumn>
  );
};
