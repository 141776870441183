import { useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import useHandleApiCall from 'util/handle-api-call';
import { API } from 'api/index';
import { useIntlUtils } from 'components/i18n/useIntlUtils';
import { Text } from 'components/index';
import { useOnMount } from 'util/useOnMount';
import { useModulesContext } from 'core/contexts/modules';

const ListAuditEntities = API.Developer.Sql.ListAuditEntitiesRq;

const ListAuditStatementNames = API.Developer.Sql.ListAuditStatementNamesRq;
const ListStatementContainerNames = API.Developer.Sql.ListStatementContainerNames;
const ListStatementUserNames = API.Developer.Sql.ListStatementUserNames;

const untranslatedStatusOptions = [
  { id: null, value: 'audit-logs.all' },
  { id: 'Error', value: 'audit-logs.error' },
  { id: 'Success', value: 'audit-logs.success' },
];

const untranslatedLimitOptions = [
  { id: 10, value: 10 },
  { id: 25, value: 25 },
  { id: 50, value: 50 },
  { id: 100, value: 100 },
  { id: 250, value: 250 },
  { id: 0, value: 'audit-logs.max' },
];

const initialState = {
  entities: { list: [], busy: false },
  statements: { list: [], busy: false },
  containers: { list: [], busy: false },
  users: { list: [], busy: false },
  statuses: { list: [], busy: false },
  limits: { list: [], busy: false },
};

export const useFilterFormLists = () => {
  const [getEntityList, entitiesBusy] = useHandleApiCall(ListAuditEntities);
  const [getStatementList, statementsBusy] = useHandleApiCall(ListAuditStatementNames);
  const [getContainerList, containersBusy] = useHandleApiCall(ListStatementContainerNames);
  const [getUserList, usersBusy] = useHandleApiCall(ListStatementUserNames);

  const [formLists, setFormLists] = useState(initialState);
  const { translateObject } = useIntlUtils();
  const { queryLogsCache } = useModulesContext();

  useEffect(() => {
    setFormLists(s => {
      const newState = { ...s };

      if (s.entities.busy !== entitiesBusy) {
        newState.entities.busy = entitiesBusy;
      }
      if (s.statements.busy !== statementsBusy) {
        newState.statements.busy = statementsBusy;
      }
      if (s.containers.busy !== containersBusy) {
        newState.containers.busy = containersBusy;
      }
      if (s.users.busy !== usersBusy) {
        newState.users.busy = usersBusy;
      }

      return newState;
    });
  }, [entitiesBusy, statementsBusy, containersBusy, usersBusy]);

  const PrepareFns = useMemo(
    () => ({
      entities: values => [
        { crn: null, name: <Text text="audit-logs.all" /> },
        ..._.sortBy(values, ['name']),
      ],
      statements: values => [
        { id: null, name: <Text text="audit-logs.all" /> },
        ..._.sortBy(values, ['name']),
      ],
      containers: values => [
        {
          ContainerName: null,
          Label: <Text text="audit-logs.all" />,
        },
        ...values,
      ],
      users: values => [{ UserName: null, Label: <Text text="audit-logs.all" /> }, ...values],
      limits: v => v,
      statuses: v => v,
    }),
    []
  );

  const updateFormLists = useCallback(
    (name, value) => {
      const sortedValues = PrepareFns[name](value);
      setFormLists(s => ({ ...s, [name]: { ...s[name], list: sortedValues } }));
    },
    [PrepareFns]
  );

  useEffect(() => {
    queryLogsCache.setLists(formLists);
  }, [formLists, queryLogsCache]);

  useEffect(() => {
    updateFormLists('statuses', translateObject(untranslatedStatusOptions, 'value'));
    updateFormLists(
      'limits',
      translateObject(untranslatedLimitOptions, 'value', { includeIndexes: [5] })
    );
  }, [updateFormLists, translateObject]);

  useOnMount(() => {
    getStatementList().then(results => {
      const statements = _.get(results, 'response.Items', [])
        .map(item => ({
          id: item.name,
          name: item.name,
        }))
        .filter(item => !!item.name);
      updateFormLists('statements', statements);
    });

    getEntityList().then(results => {
      const uniqEntities = _.uniqBy(results.response.Items, 'crn');
      const noNullEntities = _.filter(uniqEntities, 'crn');

      updateFormLists('entities', noNullEntities || []);
    });

    getUserList().then(results => {
      updateFormLists(
        'users',
        results.response.Items.map(v => {
          return {
            ...v,
            Label: v.UserName,
          };
        })
      );
    });

    getContainerList().then(results => {
      updateFormLists(
        'containers',
        results.response.Items.map(v => {
          return {
            ...v,
            Label: v.ContainerName,
          };
        })
      );
    });

    updateFormLists('statuses', translateObject(untranslatedStatusOptions, 'value'));
    updateFormLists(
      'limits',
      translateObject(untranslatedLimitOptions, 'value', { includeIndexes: [5] })
    );
  });

  return {
    formLists,
    formListsBusy: entitiesBusy || statementsBusy || containersBusy || usersBusy,
  };
};
