import { css } from 'styled-components';

// Base Table

export default css`
  &&& {
    .ant-table-container {
      border: 1px solid var(--table-border-color);

      table > thead > tr:first-child th:first-child {
        border-top-left-radius: 0 !important;
      }

      .ant-table-thead {
        .ant-table-cell {
          border-bottom: 1px solid var(--table-row-bottom-border-color);
          border-right: 1px solid var(--table-border-color);
          color: var(--baseTextColor);
        }
      }
      .ant-table-tbody {
        .ant-table-row {
          &:hover {
            .ant-table-cell {
              background-color: var(--table-row-hover-background-color);
            }
          }
          .ant-table-cell {
            color: var(--baseTextColor);
            border-bottom: 1px solid var(--table-row-bottom-border-color);

            padding: 2px;
            border-right: 1px solid var(--table-border-color);
            //text-overflow: ellipsis;
            //max-width: 200px;
            //white-space: nowrap;
          }
        }
      }
    }
    .ant-pagination {
      .ant-pagination-jump-prev,
      .ant-pagination-jump-next {
        .ant-pagination-item-ellipsis,
        .ant-pagination-item-link-icon {
          color: var(--button-color);
          background-color: var(--button-background-color);
          border: none;
        }
      }

      .ant-pagination-prev,
      .ant-pagination-next {
        .ant-pagination-item-link {
          color: var(--button-color);
          border: 1px solid var(--button-border-color);
          background-color: var(--button-background-color);
        }
      }

      .ant-pagination-item {
        a {
          color: var(--button-color);
        }
        background-color: var(--button-background-color);
        border: 1px solid var(--button-border-color);

        &.ant-pagination-item-active {
          a {
            color: var(--table-pagination-active-color);
          }
          background-color: var(--table-pagination-active-background-color);
          border: 1px solid var(--table-pagination-active-border-color);
        }
      }

      .ant-pagination-options {
        .ant-select-selector {
          color: var(--input-color) !important;
          background-color: var(--input-background-color) !important;
          border-color: var(--input-border-color);
        }
        .ant-select-dropdown {
          background-color: var(--select-dropdown-background-color);
          .ant-select-item-option {
            &.ant-select-item-option-active {
              &:not(.ant-select-item-option-selected) {
                background-color: var(--select-dropdown-item-hover-background-color);
              }
            }
            &.ant-select-item-option-selected {
              font-weight: bold;
              background-color: var(--select-dropdown-item-selected-background-color);
            }
            .ant-select-item-option-content {
              color: var(--select-dropdown-color);
            }
          }
        }
      }
    }
  }
`;
