import { css } from 'styled-components';

export default css`
  padding: 10px;

  table {
    width: 100%;
  }

  td {
    padding: 5px;
  }

  .select-branches td {
    width: 50%;
  }

  .actions {
    padding: 5px;
  }

  .jira-issue {
    margin-top: 10px;
  }

  .jira-issue .header {
    font-weight: bold;
  }

  .create-btn {
    float: right;
    margin-right: 10px;
  }
`;
