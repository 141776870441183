import { v4 as uuid } from 'uuid';

export class TransformListQueryRq {
  static transformIncoming(listQueryRq) {
    if (!listQueryRq.success) return listQueryRq;

    const { Items } = listQueryRq.response;

    return {
      ...listQueryRq,
      response: {
        ...listQueryRq.response,
        Items: Items.map(item => ({
          creatorEmail: item.CreatorEmail,
          modifyDateTime: item.ModifyDateTime,
          name: item.Name,
          ownerUid: item.OwnerUID,
          parameters: (Array.isArray(item.Parameters) && item.Parameters || []).map(p => ({
            value: p.Value,
            type: p.Type,
            name: p.Name,
          })),
          sql: item.Sql,
          state: item.State,
          tags: item.Tags,
          uid: item.UID,
          __console_meta: {
            id: uuid(),
            version: 2,
          },
        })),
      },
    };
  }

  static transformOutgoing(query) {
    return {
      CreatorEmail: query.creatorEmail,
      ModifyDateTime: query.modifyDateTime,
      Name: query.name,
      OwnerUID: query.ownerUid,
      Parameters: (query.parameters || []).map(p => ({
        Value: p.value,
        Type: p.type,
        Name: p.name,
      })),
      Sql: query.sql,
      State: query.state,
      Tags: query.tags,
      UID: query.uid,
    };
  }
}
