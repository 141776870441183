import React from 'react';
import { Card } from 'components';
import styled from 'styled-components';
import style from './style';
import cn from 'classnames';

const JiraIssue = ({ issue, className }) => {
  const { id, description, status, type, created, priority, epicId, assignee } = issue;
  const issueRef = `https://jira.commandalkon.com/browse/${id}`;
  const epicRef = `https://jira.commandalkon.com/browse/${epicId}`;

  return (
    <Card className={cn('jira-issue', className)} title="pull-requests.jiraDetails">
      <table>
        <tbody>
          <tr className="header">
            <td style={{ width: '100px' }}>Issue ID</td>
            <td style={{ width: '100px' }}>Type</td>
            <td>Description</td>
            <td>Status</td>
          </tr>
          <tr>
            <td>
              <a target={'_blank'} href={issueRef}>
                {id}
              </a>
            </td>
            <td>{type}</td>
            <td>{description}</td>
            <td>{status}</td>
          </tr>
          <tr className="header">
            <td>Feature ID</td>
            <td>Priority</td>
            <td>Created</td>
            <td>Assigned To</td>
          </tr>
          <tr>
            <td>
              <a target={'_blank'} href={epicRef}>
                {epicId}
              </a>
            </td>
            <td>{priority}</td>
            <td>{created}</td>
            <td>{assignee}</td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};

export default styled(JiraIssue)`
  ${style}
`;
