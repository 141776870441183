import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';

const Component = ({ className, children }) => {
  return <div className={cn('custom-title', className)}>{children}</div>;
};

export default styled(Component)`
  font-weight: bold;
  padding: 10px;
  color: var(--custom-title-color);
`;
