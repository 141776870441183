import React, { useEffect, useState } from 'react';
import { Button, FormItemWrapper, Select, Spinner, Text } from 'components';
import { Space } from 'antd';
import { usePullRequest } from '../usePullRequest';

export const PullRequestHeader = ({
  setShowModal,
  listPullRequests,
  listing,
  status,
  setStatus,
  repo,
  setRepo,
}) => {
  const { listRepositories, listingRepositories, repositoryList } = usePullRequest();
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    listRepositories();
  }, [listRepositories]);

  const statusList = [
    { id: 'OPEN', name: <Text text="pull-requests.open-pull-requests" /> },
    { id: 'CLOSED', name: <Text text="pull-requests.closed-pull-requests" /> },
  ];

  return (
    <Space direction="vertical" style={{ margin: 15 }}>
      <Space>
        <FormItemWrapper htmlFor="select-status" label="pull-requests.status">
          <Select
            disabled={listing}
            id="select-status"
            value={status}
            options={statusList}
            valuePath="id"
            displayPath="name"
            className="select-filter"
            onChange={value => {
              setIsDirty(true);
              setStatus(value);
            }}
          />
        </FormItemWrapper>
        <FormItemWrapper htmlFor="select-repository" label="pull-requests.repository">
          <Select
            disabled={listing}
            id="select-repository"
            value={repo}
            options={(repositoryList && repositoryList.repositories) || []}
            valuePath="repositoryName"
            displayPath="repositoryName"
            className="select-filter"
            onChange={value => {
              setIsDirty(true);
              setRepo(value);
            }}
            loading={listingRepositories}
          />
        </FormItemWrapper>
        <div style={{ paddingTop: '20px' }}>
          <Button
            id="refresh-btn"
            className={'search-pull-request-btn'}
            onClick={() => {
              setIsDirty(false);
              listPullRequests(repo, status, true);
            }}
            disabled={listing}
            icon={<i className={isDirty ? 'fa fa-search' : 'fa fa-sync'} />}
            label={isDirty ? 'pull-requests.search' : 'pull-requests.refresh'}
            type="secondary"
          />
        </div>
        <div style={{ paddingTop: '20px' }}>
          <Button
            id="create-btn"
            className={'create-pull-request-btn'}
            onClick={() => setShowModal(true)}
            label="pull-requests.create-pull-request"
            type="primary"
          />
        </div>
      </Space>
    </Space>
  );
};

export default PullRequestHeader;
