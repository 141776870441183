import { css } from 'styled-components';

export default css`
  &&& {
    .ant-modal-header {
      background-color: var(--modal-header-background-color);
      border-bottom-color: var(--modal-header-border-bottom-color);
      .ant-typography {
        margin-bottom: 0;
      }
    }

    .ant-modal-body {
      padding-left: 0;
      padding-right: 0;
      background-color: var(--modal-body-background-color);

      .cx-modal-content {
        padding: 0 25px;
      }
    }
    .ant-modal-footer {
      background-color: var(--modal-footer-background-color);
      border-top-color: var(--modal-footer-border-top-color);
    }
  }
`;
