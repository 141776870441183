import Dashboard from './modules/dashboard/nav.config';
import License from './modules/license/nav.config';
import { MOUNT_POINT } from '../../core/constants';

export default {
  id: 'accounting',
  moduleId: 'accounting',
  description: 'Accounting',
  mountPoint: MOUNT_POINT.APP_SWITCHER,
  modules: [Dashboard, License],
  hidden: false,
};
