import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Table from '../table';
import * as SF from './sort-functions';
import cn from 'classnames';
import propTypes from 'prop-types';
import style from './style';
import { Input } from '../index';

const AdvancedTable = ({
  className,
  columns,
  dataSource,
  isCompact = false,
  showSort = false,
  showFilters = false,
  ...props
}) => {
  const [filteredDataSource, setFilteredDataSource] = useState(dataSource);
  const [filters, setFilters] = useState({});

  const filterData = useCallback(() => {
    const itemPassesFilter = (value, itemValue) => {
      if (value && itemValue) {
        if (typeof record === 'object') {
          return JSON.stringify(itemValue).toLowerCase().includes(value.toLowerCase());
        }
        return itemValue.toString().toLowerCase().includes(value.toLowerCase());
      }
      return !itemValue;
    };

    const newFilteredDataSource = dataSource.filter(value => {
      let passes = true;
      for (const key of Object.keys(filters)) {
        const filterValue = filters[key];
        if (filterValue) {
          if (!itemPassesFilter(filterValue, value[key])) {
            passes = false;
            break;
          }
        }
      }
      return passes;
    });

    setFilteredDataSource(newFilteredDataSource);
  }, [filters, setFilteredDataSource, dataSource]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  const { SortFunctions } = SF;

  const getColumnSearchProps = useCallback(
    column => ({
      filterDropdown: ({ selectedKeys, setSelectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            allowClear
            className={'table-search-input'}
            placeholder={`Search ${column.dataIndex}`}
            value={filters[column.dataIndex] || ''}
            onChange={e => {
              const newFilters = { ...filters };
              newFilters[column.dataIndex] = e.target.value;
              setSelectedKeys(e.target.value ? [e.target.value] : []);
              setFilters(newFilters);
              filterData();
            }}
            onKeyUp={e => {
              if (e.keyCode === 27) {
                confirm();
              }
            }}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            onBlur={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, padding: '5px' }}
          />
        </div>
      ),
    }),
    [filters, setFilters, filterData]
  );

  const filteredColumns = columns.map(column => {
    let retColumn = {
      ...column,
      sorter:
        column.customSorter ||
        (showSort && !column.hideSort
          ? (a, b) => SortFunctions.default(a[column.dataIndex], b[column.dataIndex])
          : false),
      ellipsis: isCompact ? { showTitle: true } : null,
    };

    if (!column.hideFilter) {
      retColumn = { ...retColumn, ...getColumnSearchProps(column) };
    }

    return retColumn;
  });

  const sortColumns = columns.map(column => {
    const retColumn = {
      ...column,
      sorter:
        column.customSorter ||
        (showSort && !column.hideSort
          ? (a, b) => SortFunctions.default(a[column.dataIndex], b[column.dataIndex])
          : false),
      ellipsis: isCompact ? { showTitle: true } : null,
    };
    return column.hideSort ? column : retColumn;
  });

  const handleSearch = (selectedKeys, confirm) => {
    confirm();
  };

  const retColumns = showFilters ? filteredColumns : sortColumns;
  return (
    <>
      <Table
        className={cn('advanced-table', className)}
        columns={retColumns}
        dataSource={filteredDataSource}
        {...props}
      />
    </>
  );
};

AdvancedTable.propTypes = {
  className: propTypes.string,
  columns: propTypes.arrayOf(propTypes.object),
  dataSource: propTypes.arrayOf(propTypes.object),
  isCompact: propTypes.bool,
  showSort: propTypes.bool,
  showFilters: propTypes.bool,
};

AdvancedTable.defaultProps = {
  isCompact: false,
  showSort: false,
  showFilters: false,
};

export default styled(AdvancedTable)`
  ${style}
`;
