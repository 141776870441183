import { css } from 'styled-components';

export default css`
  &&& {
    .cx-drawer-footer {
      & > * {
        margin-top: 10px;
        padding: 10px;
        background-color: var(--drawer-background-color);
      }
    }

    .ant-drawer-content-wrapper {
      &.ant-drawer-open {
        box-shadow: var(--drawer-box-shadow);
      }
      border-left: 1px solid var(--drawer-border-left-color);
      .ant-drawer-header {
        background-color: var(--drawer-background-color);
        padding: 16px 24px 8px 24px;
        border-bottom: 1px solid var(--drawer-divider-color);
        .ant-drawer-title {
          color: var(--drawer-title-color);
          .ant-typography {
            color: var(--drawer-title-color);
          }
        }
        .ant-drawer-close {
          color: var(--drawer-close-icon-color);
          &:hover {
            color: var(--drawer-close-icon-hover-color);
          }
        }
      }
      .ant-drawer-content {
        background-color: var(--drawer-background-color);
      }
    }

    .ant-drawer-body {
      padding: 0;
      display: flex;
      flex-direction: column;
    }
  }
`;
