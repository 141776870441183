export const appDefinitions = {};

const appCache = {};

function importAll(r) {
  r.keys().forEach(key => {
    return (appCache[key] = r(key));
  });
}

importAll(require.context('../../apps', true, /app.config.js$/));

Object.entries(appCache).forEach(([key, value]) => {
  const def = value.default;
  if (!def.disabled) {
    appDefinitions[key] = def;
  }
});
