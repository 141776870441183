import { css } from 'styled-components';

export default css`
  &&& {
    .CodeMirror {
      background-color: var(--cm-background-color);
      color: var(--cm-color);
      font-size: ${({ theme }) => `${theme.resolvedFontSize}px`};
      cursor: default;
      transition: all 0.3s;
    }
    .CodeMirror-gutters {
      background: var(--cm-gutters-background);
      color: var(--cm-guttermarker-color);
      border-right: 1px solid var(--cm-gutters-border-right-color);
      transition: all 0.3s;
    }

    .CodeMirror-guttermarker {
      color: var(--cm-guttermarker-color);
    }
    .CodeMirror-guttermarker-subtle {
      color: var(--cm-guttermarker-color);
    }
    .CodeMirror-linenumber {
      color: var(--cm-linenumber-color);
    }

    .CodeMirror-cursor {
      border-left: var(--cm-cursor-border-left);
    }

    div.CodeMirror-selected {
      background: var(--cm-selected-background);
      transition: all 0.3s;
    }

    .CodeMirror-focused div.CodeMirror-selected {
      background: var(--cm-focused-background);
      transition: all 0.3s;
    }

    .CodeMirror-line::selection {
      background: var(--cm-line-selection-background);
      transition: all 0.3s;
    }
    .CodeMirror-line > span::selection {
      background: var(--cm-line-selection-background);
      transition: all 0.3s;
    }
    .CodeMirror-line > span > span::selection {
      background: var(--cm-line-selection-background);
      transition: all 0.3s;
    }

    .CodeMirror-activeline-background {
      background: var(--cm-activeline-background);
      transition: all 0.3s;
    }

    .cm-meta {
      color: var(--cm-meta-color);
    }
    .cm-keyword {
      line-height: 1em;
      font-weight: bold;
      color: var(--cm-keyword-color);
    }
    .cm-atom {
      color: var(--cm-atom-color);
    }
    .cm-number {
      color: var(--cm-number-color);
    }
    .cm-def {
      color: var(--cm-def-color);
    }
    .cm-variable {
      color: var(--cm-variable-color);
    }
    .cm-variable-2 {
      color: var(--cm-variable-2-color);
    }
    .cm-variable-3 {
      color: var(--cm-variable-3-color);
    }
    .cm-type {
      color: var(--cm-type-color);
    }
    .cm-property {
      color: var(--cm-property-color);
    }
    .cm-operator {
      color: var(--cm-operator-color);
    }
    .cm-comment {
      color: var(--cm-comment-color);
    }
    .cm-string {
      color: var(--cm-string-color);
    }
    .cm-string-2 {
      color: var(--cm-string-2-color);
    }
    .cm-qualifier {
      color: var(--cm-qualifier-color);
    }
    .cm-builtin {
      color: var(--cm-builtin-color);
    }
    .cm-tag {
      color: var(--cm-tag-color);
    }
    .cm-attribute {
      color: var(--cm-attribute-color);
    }

    .cm-error {
      color: var(--cm-error-color);
      background-color: var(--cm-error--background-color);
      transition: all 0.3s;
    }

    .CodeMirror-matchingbracket {
      text-decoration: var(--cm-matchingbracket-text-decoration);
      color: var(--cm-matchingbracket-color) !important;
    }
  }
`;
