export default {
  viewHeader: 'Журнали аудиту',
  SQL: 'SQL',
  all: 'Всі',
  'begin-time': 'Початок часу',
  'clear-filters': 'Очистити фільтри',
  'clear-table-filters': 'Очистити фільтри таблиці',
  'compact-size': 'Компактний розмір',
  'copy-json': 'Скопіюйте JSON',
  'duration-minimum-search': 'Мінімальна тривалість (секунд)',
  'edit-query': 'редагувати запит',
  'end-time': 'Час закінчення',
  entity: 'Сутність',
  error: 'Помилка',
  'full-size': 'Повний розмір',
  'item-details': 'Деталі деталей',
  'item-minimum-search': 'Мінімальна кількість предметів',
  'limit-search': 'Обмежити пошук',
  max: 'макс',
  search: 'Пошук',
  'showing-item-count': 'Показ {rowLimit} з {rowCount} предметів.',
  'statement-name': 'Назва заяви',
  status: 'Статус',
  success: 'Yспіху',
  'view-json': 'Переглянути JSON',
  'view-sql-statement': 'Переглянути SQL',
  'copy-parameters': 'Параметри копіювання',
  'copy-sql': 'Копіювати SQL',
  parameters: 'Параметри',
  'user-name': "Ім'я користувача",
  'container-name': 'Назва контейнера',
};
