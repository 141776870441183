import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Drawer, Spinner, Table } from 'components';
import cn from 'classnames';
import style from './style';
import FlexColumn from 'components/layout-flex-column';
import { useBuild } from '../useBuild';
import buildColumns from './buildColumns';
import BuildListHeader from '../build-list-header';
import { useOnMount } from 'util/useOnMount';
import DrawerTitleWithSize from '../../../../data/explorer/view/components/data-results/DrawerTitleWithSize';
import CreateBuild from '../create-build';
import { useUserContext } from 'core/contexts/user/UserContextProvider';

const columns = buildColumns();

const BuildList = ({ module, className }) => {
  const { builds, listBuilds, listing } = useBuild();
  const [maxResults, setMaxResults] = useState(10);
  const [status, setStatus] = useState('Success');
  const [showModal, setShowModal] = useState(false);

  const { isPlatformAdmin } = useUserContext();

  useOnMount(() => {
    listBuilds(maxResults, status);
  });

  const onCreated = useCallback(() => {
    setShowModal(false);
    listBuilds(maxResults, status);
  }, [listBuilds, maxResults, status]);

  return (
    <FlexColumn className={cn('build-list', className)}>
      <BuildListHeader
        listing={listing}
        listBuilds={listBuilds}
        maxResults={maxResults}
        setMaxResults={setMaxResults}
        setShowModal={setShowModal}
        setStatus={setStatus}
        status={status}
      />
      <FlexColumn className="build-list-body">
        <Spinner spinning={listing} size="large">
          <Table
            className="build-list"
            columns={columns}
            dataSource={builds || []}
            showFilters
            showSort
            isCompact
          />
        </Spinner>
      </FlexColumn>
      {isPlatformAdmin() && (
        <Drawer
          title={<DrawerTitleWithSize title="builds.create-build" />}
          width={800}
          onClose={() => setShowModal(false)}
          visible={showModal}
          drawerStyle={{ display: 'flex' }}
          style={{ display: 'flex' }}
          destroyOnClose
        >
          <CreateBuild onCreated={onCreated} />
        </Drawer>
      )}
    </FlexColumn>
  );
};

export default styled(BuildList)`
  ${style}
`;
