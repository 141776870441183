import { useCallback, useState } from 'react';
import { API } from '../../../../../../../api';

const useDataSets = () => {
  const [dataSets, setDataSets] = useState();
  const [busy, setBusy] = useState(false);
  const getDataSets = useCallback(entityRef => {
    setBusy(true);
    API.WDC(entityRef, {
      Action: 'getDataSets',
      EID: entityRef,
    })
      .then(response => {
        setDataSets(response.Schemas);
      })
      .finally(() => {
        setBusy(false);
      });
  }, []);

  return { getDataSets, dataSets, busy };
};

export default useDataSets;
