export default {
  moduleDisplayLabel: 'Приклади',
  customButton: 'Власна кнопка',
  defaultButton: 'Кнопка за замовчуванням',
  platformTicketAnalysis: 'Аналіз квитків на платформу',
  title: 'Підсумок квитків',
  totalTickets: 'Всього квитків',
  concreteDelivered: 'Бетон доставлений',
  aggregateDelivered: 'Aggregate Доставлено',

  refresh: 'Refresh',
  apply: 'Apply',
  search: 'Search',
  openFilters: 'More...',
  totalProduced: 'Total Shipped',
  totalShared: 'Total Received',
};
