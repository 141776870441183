import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Modal as AntModal } from 'antd';
import style from './style';
import { Title } from '../index';

const Footer = ({ children }) => {
  return <div className="cx-modal-footer">{children}</div>;
};

const Modal = ({
  title,
  className,
  onOk,
  onCancel,
  visible,
  maskClosable,
  destroyOnClose,
  children,
  footer,
}) => {
  return (
    <AntModal
      className={cn('cx-modal', className)}
      onOk={onOk}
      onCancel={onCancel}
      visible={visible}
      maskClosable={maskClosable}
      destroyOnClose={destroyOnClose}
      title={<Title text={title} level={3} />}
      footer={footer}
    >
      <div className="cx-modal-content" key="title">
        {children}
      </div>
    </AntModal>
  );
};

Modal.Footer = Footer;

export default styled(Modal)`
  ${style}
`;
