import React from 'react';
import styled from 'styled-components';
import propTypes from 'prop-types';
import cn from 'classnames';
import { Card as AntCard } from 'antd';
import { useIntl } from 'react-intl';
import style from './style';

export const Card = ({ className, size, title, extra, style, children }) => {
  const { formatMessage } = useIntl();

  const i18nTitle = React.useMemo(() => title && formatMessage({ id: title }), [
    title,
    formatMessage,
  ]);

  return (
    <AntCard
      className={cn('cx-card', className)}
      size={size}
      title={i18nTitle}
      extra={extra}
      style={style}
    >
      {children}
    </AntCard>
  );
};

Card.propTypes = {
  className: propTypes.string,
  size: propTypes.string,
  title: propTypes.string,
  extra: propTypes.string,
  style: propTypes.object,
  children: propTypes.element,
};

export default styled(Card)`
  ${style}
`;
