import DataSets from './view';
import i18n from './i18n';

export default {
  component: DataSets,
  i18n,
  id: 'datasets',
  moduleId: 'datasets',
  permissions: {},
};
