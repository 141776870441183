import React from 'react';
import { Module } from 'core/modules/Module';

const DataExplorer = React.lazy(() => import('./view'));

export default ({ module }) => {
  return (
    <Module module={module}>
      <DataExplorer />
    </Module>
  );
};
