import React, { useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import useApiStatus from './useApiStatus';
import style from './style';
import { Tooltip } from 'antd';

const ApiStatus = ({ className }) => {
  const { busy, status = {} } = useApiStatus();

  const tooltipText = useMemo(() => {
    if (!status.Status) {
      return 'Checking status...';
    }
    return `v${status.Version} Build: ${status.Build} Status: ${status.Status}`;
  }, [status]);

  return (
    <div className={cn('cx-api-status', className)}>
      <Tooltip placement="topRight" title={tooltipText} arrowPointAtCenter>
        <div className={cn('icon', { normal: status.Status === 'Normal' })}>
          {busy ? <i className="fas fa-spin fa-spinner" /> : <i className="fas fa-circle" />}
        </div>
      </Tooltip>
    </div>
  );
};

export default styled(ApiStatus)(style);
