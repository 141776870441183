module.exports = {
  id: 'dashboard',
  icon: 'chart-bar',
  description: 'Dashboard',
  moduleId: 'accounting-dashboard',
  feature: {
    id: 'FE-0000',
    description: 'Accounting Dashboard',
    url: 'https://jira.commandalkon.com/browse/FE-0000',
  },
};
