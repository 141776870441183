import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Typography } from 'antd';
import style from './style';
import Text from '../../../components/typography/text';
import Button from '../../../components/button';
import NotificationItem from './NotificationItem';
import { useNotificationStateContext, useNotifyContext } from '../../contexts/notifications';

const Title = () => (
  <Typography.Title level={5}>
    <Text text="notifications" />
  </Typography.Title>
);

const NotificationLog = ({ className, onEmpty = () => {} }) => {
  const { notificationLog } = useNotificationStateContext();
  const { removeLogItem, clear } = useNotifyContext();

  const handleRemoveClick = useCallback(
    item => {
      removeLogItem(item);
    },
    [removeLogItem]
  );

  const handleClear = useCallback(() => {
    clear();
  }, [clear]);

  useEffect(() => {
    if (!notificationLog.length) {
      onEmpty();
    }
  }, [notificationLog, onEmpty]);

  return (
    <div className={cn(className)}>
      <div className="action-section">
        <Button
          label="clearAll"
          size="small"
          onClick={handleClear}
          disabled={!notificationLog.length}
        />
      </div>
      <div className="notification-items">
        {notificationLog.reverse().map(notification => (
          <NotificationItem
            key={notification.timeStamp}
            notification={notification}
            onRemove={handleRemoveClick}
          />
        ))}
      </div>
    </div>
  );
};

NotificationLog.Title = Title;

export default styled(NotificationLog)`
  ${style}
`;
