import Profile from './index';
import i18n from './i18n';

export default {
  component: Profile,
  i18n,
  id: 'user-profile',
  moduleId: 'user-profile',
  permissions: {},
};
