import React from 'react';
import { Col, Row } from 'antd';
import Widget from 'components/widget';
import BigTotal from '../../../../../components/common/BigTotal';

export default ({ loading, summary, sources }) => {
  return (
    <Row>
      <Col span={8}>
        <Widget>
          <BigTotal
            number={summary && summary.TicketCount}
            label="pa-dashboard.totalTickets"
            loading={loading}
          />
        </Widget>
      </Col>
      <Col
        span={8}
        style={{
          paddingLeft: '10px',
          paddingRight: '10px',
        }}
      >
        <Widget>
          <BigTotal
            number={sources.production.TicketCount}
            label="pa-dashboard.totalProduced"
            loading={loading}
          />
        </Widget>
      </Col>
      <Col span={8}>
        <Widget>
          <BigTotal
            number={sources.shared.TicketCount}
            label="pa-dashboard.totalShared"
            loading={loading}
          />
        </Widget>
      </Col>
    </Row>
  );
};
