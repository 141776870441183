import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import FlexColumn from 'components/layout-flex-column';
import JiraIssue from '../components/jira-issue';
import { usePullRequest } from '../usePullRequest';
import { useParams } from 'react-router-dom';
import PullRequestSummary from '../components/pull-request-summary';
import Actions from './Actions';
import { Spinner } from 'components/index';
import { Space } from 'antd';
import ProblemList from '../components/problem-list';
import FileList from '../components/file-list';

const PullRequestDetail = ({ className, onMerged, onCancel }) => {
  const [issue, setIssue] = useState(null);
  const [mergeable, setMergeable] = useState(false);
  const [analysis, setAnalysis] = useState(null);

  const { pullRequestId } = useParams();

  const { getPullRequestDetail, pullRequestDetail, getting } = usePullRequest();

  useEffect(() => {
    getPullRequestDetail(pullRequestId);
  }, [getPullRequestDetail, pullRequestId]);

  useEffect(() => {
    if (pullRequestDetail) {
      setAnalysis(pullRequestDetail.platformTeamReview);
      setIssue(pullRequestDetail.issue || {});
      setMergeable(pullRequestDetail.mergeable === true);
    }
  }, [pullRequestDetail]);

  return (
    <FlexColumn className={cn('pull-request-detail', className)}>
      <Spinner spinning={getting} size="large">
        {pullRequestDetail && (
          <Space direction={'vertical'}>
            <ProblemList problems={pullRequestDetail.problems || []} />

            <PullRequestSummary pullRequest={pullRequestDetail} />

            {issue && <JiraIssue issue={issue} />}

            {analysis && <FileList analysis={analysis} />}

            <Actions
              mergeable={mergeable}
              pullRequestId={pullRequestDetail && pullRequestDetail.id}
              onMerged={onMerged}
              onCancel={onCancel}
            />
          </Space>
        )}
      </Spinner>
    </FlexColumn>
  );
};

export default styled(PullRequestDetail)`
  ${style}
`;
