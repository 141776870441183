import { css } from 'styled-components';

export default css`
  &&& {
    background-color: var(--date-picker-background-color);

    border-color: var(--date-picker-border-color);

    transition: all 0.3s;
    INPUT {
      color: var(--date-picker-color);
      &::placeholder {
        color: var(--date-picker-placeholder-color);
      }
    }
    SPAN {
      color: var(--date-picker-icon-color);
      background-color: var(--date-picker-background-color);
    }
  }
`;
