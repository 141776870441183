import { useHistory } from 'react-router-dom';
import { cache } from '../util';
import { APP_PATH } from './components/sidebar/constants';

export const useNavigateToApp = () => {
  const history = useHistory();

  const navigate = (appId, shouldCache) => {
    shouldCache && cache.setItem('cx.app', appId);
    history.push(`${APP_PATH}/${appId}`);
  };

  return { navigate };
};

export default useNavigateToApp;
