import { useState, useEffect } from 'react';
import useHandleApiCall from '../../../util/handle-api-call';
import { API } from '../../../api';

export const useApiStatus = () => {
  const [status, setStatus] = useState();
  const [getStatus, busy] = useHandleApiCall(API.Platform.getStatus);
  useEffect(() => {
    getStatus().then(response => {
      setStatus(response.response);
    });
  }, [getStatus]);

  return { busy, status };
};

export default useApiStatus;
