import SDKComponents from './sdk-components/nav.config';
import CustomComponents from './custom-components/nav.config';
import DynamicForm from './dynamic-form/nav.config';
import ThemeTest from './theme test/nav.config';
import HandoffFromModule from './handoff-from-module/nav.config';
import HandoffToModule from './handoff-to-module/nav.config';

export default {
  id: 'examples',
  moduleId: 'examples',
  description: 'Examples',
  modules: [
    SDKComponents,
    CustomComponents,
    DynamicForm,
    ThemeTest,
    HandoffFromModule,
    HandoffToModule,
  ],
};
