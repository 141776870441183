export const themeDefinitions = {};

const themeCache = {};

function importAll(r) {
  r.keys().forEach(key => {
    return (themeCache[key] = r(key));
  });
}

importAll(require.context('./themes', true, /theme.config.js$/));

Object.entries(themeCache).forEach(([key, value]) => {
  const def = value.default;
  if (!def.disabled) {
    themeDefinitions[key] = def;
  }
});
