import React, { useCallback } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { values } from 'lodash';
import { useThemeContext } from '../../theme-provider';
import Text from '../../../components/typography/text';
import style from './style';
import { themeDefinitions } from '../../../themes';
import DropdownMenu, { PLACEMENT, TRIGGER } from '../../../components/dropdown-menu';

const themeMenuItems = values(themeDefinitions).map(theme => ({
  id: theme.id,
  label: `${theme.id}.themeName`,
}));

const ThemeSwitcher = ({ className }) => {
  const { themeId, setThemeId } = useThemeContext();

  const handleMenuClick = useCallback(
    themeId => {
      setThemeId(themeId);
    },
    [setThemeId]
  );

  return (
    <div className={cn('cx-theme-switcher', className)}>
      <DropdownMenu
        trigger={[TRIGGER.CLICK]}
        placement={PLACEMENT.TOP_LEFT}
        menuItems={themeMenuItems}
        onClick={handleMenuClick}
        activeId={themeId}
        toggleWhenTwo
      >
        <div className="dropdown-content">
          <div className="icon">
            <i className="fas fa-adjust" />
          </div>
          <div className="label">
            <Text text={`${themeId}.themeName`} />
          </div>
        </div>
      </DropdownMenu>
    </div>
  );
};

export default styled(ThemeSwitcher)(style);
