import React from 'react';
import cn from 'classnames';
import EntitySearch from '../components/entity-search';
import { ViewHeader } from '../../../../../../components';
import Text from '../../../../../../components/typography/text';
export const View = ({ className }) => {
  return (
    <div className={cn('license-configure-view', className)}>
      <ViewHeader>
        <Text text="configure.heading" />
      </ViewHeader>
      <EntitySearch />
    </div>
  );
};

export default View;
