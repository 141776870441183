import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Col, Divider, Input, List, Row } from 'antd';
import { useIntl } from 'react-intl';
import FlexColumn from 'components/layout-flex-column';
import Widget from 'components/widget';
import { ChartRq } from '../../../../api';

const getEntityTicketCounts = Filters => {
  return ChartRq({
    Action: 'getTicketsByEntity',
    Filters,
  }).then(({ response }) => {
    const { Items = [] } = response;

    return Items;
  });
};

const byCount = (a, b) => (a.TicketCount < b.TicketCount ? 1 : -1);
const byName = (a, b) => (`${a.Name}`.toUpperCase() < `${b.Name}`.toUpperCase() ? -1 : 1);
const withKey = (Item, index) => {
  return {
    ...Item,
    Key: `entity-${index}`,
  };
};

const EntityList = ({ title }) => {
  const [data, setData] = useState(null);
  const [entities, setEntities] = useState(null);
  const history = useHistory();
  const { url } = useRouteMatch();
  const { formatNumber } = useIntl();
  const [filter, setFilter] = useState('');
  const [name, setName] = useState(null);
  const [sort] = useState('byCount');

  useEffect(() => {
    getEntityTicketCounts({}).then(Items => {
      setEntities(Items);
    });
  }, []);

  const preparedItems = React.useMemo(() => {
    const nameMatches = T =>
      !filter || filter === '' || (T.Name && T.Name.toUpperCase().includes(filter.toUpperCase()));

    return (
      (entities &&
        [].concat(
          entities
            .map(withKey)
            .filter(nameMatches)
            .sort(sort === 'byCount' ? byCount : byName)
        )) ||
      []
    );
  }, [entities, filter, sort]);

  useEffect(() => {
    if (entities) {
      setData(preparedItems);
    }
  }, [entities, filter, preparedItems]);

  const refresh = useCallback(() => {
    setFilter(name);
  }, [name]);

  const showEntity = useCallback(
    Item => {
      const base = url.endsWith('/') ? url : `${url}/`;

      const entityPage = `${base}entities/${Item.crn}`;

      history.push(entityPage, Item);
    },
    [history, url]
  );

  return (
    <FlexColumn>
      <Widget>
        <Row
          style={{
            textAlign: 'left',
            padding: '10px',
            paddingBottom: '0px',
          }}
        >
          <Col span={12}>{title}</Col>
          <Col span={12}>
            <Input
              placeholder="Search by name..."
              defaultValue=""
              autoFocus
              allowClear
              onChange={e => setName(e.target.value)}
              value={name}
              onPressEnter={refresh}
              addonAfter={
                <span className="fa fa-search" style={{ cursor: 'pointer' }} onClick={refresh} />
              }
            />
          </Col>
        </Row>
        <Divider />
        {data && (
          <List
            size="small"
            pagination={{
              size: 'small',
              total: data.length,
              pageSize: 10,
              showSizeChanger: false,
              showQuickJumper: true,
            }}
            dataSource={data}
            renderItem={item => (
              <List.Item
                key={item.Key}
                style={{ cursor: 'pointer' }}
                onClick={() => showEntity(item)}
              >
                <span>{`${item.Name || 'Unknown'}`.substr(0, 36)}</span>
                <span>{formatNumber(item.TicketCount)}</span>
              </List.Item>
            )}
          />
        )}
      </Widget>
    </FlexColumn>
  );
};

export default EntityList;
