export default {
  moduleDisplayLabel: 'API Docs',
  apiCatalog: 'API Catalog',
  codeExample: 'Code Example',
  componentLibrary: 'Component Library',
  fields: 'Fields',
  models: 'Models',
  services: 'Services',
  run: 'Run',
};
