export default {
  moduleDisplayLabel: 'Saved Queries',
  executionPlan: 'Execution Plan',
  loadingExecutionPlan: 'Loading Execution Plan',
  editQuery: 'Edit Query',
  refresh: 'Refresh',
  backToList: 'Back to query list',
  parameters: 'Parameters',
  name: 'Name',
  defaultValue: 'Default Value',
  tags: 'Tags',
  type: 'Type',
};
