export default {
  cancel: 'Скасувати',
  'closed-pull-requests': 'Закриті запити на витягування',
  'confirm-pull-request-modal-title': 'запит на об’єднання - підтвердити',
  'confirm-pull-request-modal-message': 'Ти впевнений?',
  'confirm-pull-request-modal-confirmed-button-label': 'так',
  'confirm-pull-request-modal-cancel-button-label': 'скасувати',
  'create-pull-request': 'Створити запит на витягування',
  'cancel-merge-pull-request': 'Cancel',
  destination: 'Пункт призначення',
  'merge-pull-request': 'Merge Pull Request',
  'open-pull-requests': 'Відкрийте запити на витяг',
  'pull-request-details': 'Pull Request Details',
  refresh: 'Оновлення',
  repository: 'Репозиторій',
  search: 'Пошук',
  status: 'Статус',
  viewHeader: `Demandes d'extraction`,
  withBuild: 'Build',
  withoutBuild: 'No Build',
  withTests: 'Tests',
  withoutTests: 'No Tests',
};
