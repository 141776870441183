import { css } from 'styled-components';

export default css`
  .icon {
    cursor: pointer;
    font-size: 0.8em;
    &.normal {
      color: var(--apiStatusNormalColor);
    }
    &.unknown {
      color: #ccc;
    }
  }
`;
