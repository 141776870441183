import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Switch, useRouteMatch, Route, useHistory } from 'react-router-dom';
import Select from '../../../../../../components/select';
import useEntities from './useEntities';
import { Spinner } from '../../../../../../components';
import Button from '../../../../../../components/button';
import FlexColumn from '../../../../../../components/layout-flex-column';
import style from './style';
import DataSetList from './dataset';
import DataSetDetails from './dataset/DataSetDetails';

const DataSets = ({ className }) => {
  const { path, url } = useRouteMatch();
  const history = useHistory();
  const [selectedEntityRef, setSelectedEntityRef] = useState(
    '64ebc275-8468-4ca2-9720-655210f6a95d'
  );
  const { entities, busy } = useEntities();

  const handleEntityChange = useCallback(entity => {
    console.log('entity', entity);
    setSelectedEntityRef(entity);
  }, []);

  const fetchDataSets = useCallback(() => {
    history.push(`${url}/${selectedEntityRef}`);
  }, [history, url, selectedEntityRef]);

  return (
    <FlexColumn className={cn('datasets', className)}>
      <Spinner spinning={busy} size="large">
        <FlexColumn className="panel">
          <Switch>
            <Route path={`${path}/:entityRef/:dataSet`}>
              <DataSetDetails />
            </Route>
            <Route path={path}>
              <div className="entity-selection">
                <Select
                  placeholder="Select an Entity"
                  options={entities}
                  value={selectedEntityRef}
                  valuePath="crn"
                  displayPath="name"
                  onChange={handleEntityChange}
                />
                <Button
                  label="datasets.fetchDataSets"
                  onClick={fetchDataSets}
                  disabled={!selectedEntityRef}
                />
              </div>
              <Route path={`${path}/:entityRef`}>
                <DataSetList />
              </Route>
            </Route>
          </Switch>
        </FlexColumn>
      </Spinner>
    </FlexColumn>
  );
};

export default styled(DataSets)`
  ${style}
`;
