export default {
  appName: 'Sandbox',
  'sandbox-dashboard': 'Dashboard',
  'custom-components': 'Custom Components',
  'sdk-components': 'SDK Components',
  examples: 'Examples',
  'dynamic-form': 'Dynamic Form',
  'theme-test': 'Theme Test',
  'to-module': 'Handoff To Module',
  'from-module': 'Handoff From Module',
  workspace: 'Workspace',
};
