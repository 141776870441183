import React, { useCallback } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useThemeContext } from '../../theme-provider';
import Text from '../../../components/typography/text';
import style from './style';
import { values } from 'lodash';
import fontSizes from './fontSizes';
import DropdownMenu, { PLACEMENT, TRIGGER } from '../../../components/dropdown-menu';

const fontSizeItems = values(fontSizes).map(size => ({
  id: size.id,
  label: size.id,
}));

const FontSizeSwitcher = ({ className }) => {
  const { fontSize, setFontSize } = useThemeContext();

  const handleMenuClick = useCallback(
    size => {
      setFontSize(size);
    },
    [setFontSize]
  );

  return (
    <div className={cn('cx-locale-switcher', className)}>
      <DropdownMenu
        trigger={[TRIGGER.CLICK]}
        placement={PLACEMENT.TOP_LEFT}
        menuItems={fontSizeItems}
        onClick={handleMenuClick}
        activeId={fontSize}
      >
        <div className="dropdown-content">
          <div className="icon">
            <i className="fas fa-text-height" />
          </div>
          <div className="label">
            <Text text={`${fontSize}`} />
          </div>
        </div>
      </DropdownMenu>
    </div>
  );
};

export default styled(FontSizeSwitcher)(style);
