import React, { useEffect, useCallback, useState } from 'react';
import useHandleApiCall from 'util/handle-api-call';
import { API } from 'api';
import { omit } from 'lodash';

const QueryListContext = React.createContext();
export const useQueryListContext = () => {
  const context = React.useContext(QueryListContext);
  if (!context) {
    throw new Error(
      `useQueryListContext cannot be rendered outside of the QueryListContext context provider`
    );
  }
  return context;
};

export const QueryListContextProvider = ({ children }) => {
  const [queryList, setQueryList] = useState([]);
  const [filteredQueries, _setFilteredQueries] = useState([]);
  const [listQueryRq, busy] = useHandleApiCall(API.Developer.Sql.ListQueryRq);
  const [listQueryRqBackground, busyInTheBackground] = useHandleApiCall(
    API.Developer.Sql.ListQueryRq
  );
  const [deleteQueryRq] = useHandleApiCall(API.Developer.Sql.DeleteQueryRq);
  const [tagFilter, setTagFilter] = useState([]);

  const refresh = useCallback(
    isInBackground => {
      const fn = isInBackground ? listQueryRqBackground : listQueryRq;

      fn().then(results => {
        const { response } = results;
        const newQueries = response.Items;
        setQueryList(newQueries);
      });
    },
    [listQueryRq, listQueryRqBackground]
  );

  const prepareToDelete = useCallback(queryUid => {
    setQueryList(ql =>
      ql.map(q => {
        if (q.uid !== queryUid) return q;
        return {
          ...q,
          __console_meta: {
            ...q.__console_meta,
            isDeleting: true,
          },
        };
      })
    );

    return () => {
      setQueryList(queries =>
        queries.map(q => {
          return omit(q, '__console_meta.isDeleting');
        })
      );
    };
  }, []);

  const setFilteredQueries = useCallback((arr = []) => {
    return _setFilteredQueries(arr.map(q => ({ ...q, creatorEmail: q.creatorEmail || '' })));
  }, []);

  const deleteQuery = useCallback(
    queryUid => {
      deleteQueryRq(queryUid).then(() => {
        setQueryList(results => results.filter(q => q.uid !== queryUid));

        listQueryRqBackground().then(results => {
          const { response } = results;
          const newQueries = response.Items;
          setQueryList(newQueries.filter(q => q.uid !== queryUid));
        });
      });
    },
    [deleteQueryRq, listQueryRqBackground]
  );

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    if (tagFilter.length === 0) {
      setFilteredQueries(queryList);
      return;
    }

    const newQueries = [];
    for (const query of queryList) {
      if (query.tags && query.tags.length > 0) {
        for (const tag of query.tags) {
          if (tagFilter.indexOf(tag) >= 0) {
            newQueries.push(query);
            break;
          }
        }
      }
    }
    setFilteredQueries(newQueries);
  }, [queryList, setFilteredQueries, tagFilter]);

  const distinctTags = React.useMemo(() => {
    const tagMap = {};
    for (const query of queryList) {
      if (query.tags && query.tags.length > 0) {
        for (const tag of query.tags) {
          tagMap[tag] = true;
        }
      }
    }
    return Object.keys(tagMap);
  }, [queryList]);

  return (
    <QueryListContext.Provider
      value={{
        queries: filteredQueries,
        refresh,
        busy,
        busyInTheBackground,
        deleteQuery,
        prepareToDelete,
        distinctTags,
        setTagFilter,
      }}
    >
      {children}
    </QueryListContext.Provider>
  );
};
