import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  border: 1px solid var(--notification-item-border-color);
  padding: ${({ theme }) => `8px ${theme.fontSizeMultiplier * 16}px`};
  margin-bottom: 20px;

  &:hover {
    .footer {
      .copy-icon {
        button {
          display: block;
          cursor: pointer;
        }
      }
    }
  }

  .header {
    display: flex;
    .type-icon {
      font-size: ${({ theme }) => `${theme.fontSizeMultiplier + 0.3}rem`};
      width: ${({ theme }) => `${theme.fontSizeMultiplier * 30}px`};
      > SPAN {
        color: ${({ notification }) => `--notification-type-${notification.type}-icon-color`};
      }
    }
    .title {
      flex: 1;
      font-size: ${({ theme }) => `${theme.fontSizeMultiplier + 0.1}rem`};
      //font-size: 1.1em;
      font-weight: bold;
      color: var(--notification-item-title-color);
    }
    .close-icon {
      cursor: pointer;
      .anticon-close {
        color: var(--notification-item-close-icon-color);
        transition: color 0.3s;
        &:hover {
          color: var(--notification-item-close-icon-hover-color);
        }
      }
    }
  }
  .body {
    margin-left: ${({ notification, theme }) =>
      `${notification.type ? `${theme.fontSizeMultiplier * 30}px` : '0'}`};
    color: var(--notification-item-body-color);
    font-size: ${({ theme }) => `${theme.fontSizeMultiplier}rem`};
  }
  .footer {
    margin-left: ${({ notification, theme }) =>
      `${notification.type ? `${theme.fontSizeMultiplier * 30}px` : '0'}`};
    display: flex;
    color: var(--notification-item-footer-color);
    .timestamp {
      margin-top: 5px;
      font-weight: bold;
      font-size: ${({ theme }) => `${theme.fontSizeMultiplier}rem`};
    }
    .copy-icon {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      font-size: ${({ theme }) => `${theme.fontSizeMultiplier}rem`};
      button {
        background: transparent;
        border: none;
        display: none;
        color: var(--notification-item-copy-icon-color);
        transition: color 0.3s;
        &:hover {
          color: var(--notification-item-copy-icon-hover-color);
        }
      }
    }
  }
`;
