import Input from './input';

// Abstract antd from apps and modules in case we need to replace it in the future
export { default as Badge } from './badge';
export { default as Button } from './button';
export { default as Card } from './card';
export { default as Code } from './code';
export { default as CodeEditor } from './code-editor';
export { default as Collapse } from './collapse';
export { default as ConfirmButton } from './confirmButton';
export { default as DatePicker } from './date-picker';
export { default as Drawer } from './drawer';
export { default as Exporter } from './exporter';
export { default as FormItemWrapper } from './formItemWrapper';
export { default as Input } from './input';
export { default as InputNumber } from './inputNumber';
export { default as Label } from './label/Label';
export { default as Modal } from './modal';
export { default as Result } from './result';
export { default as Select } from './select';
export { default as Spinner } from './spinner';
export { default as Statistic } from './statistic';
export { default as Table } from './tables/table';
export { default as Tabs } from './tabs';
export { default as TagGroup } from './tag-group';
export { default as Text } from './typography/text';
export { default as Title } from './typography/title';
export { default as TimePicker } from './time-picker';
export { default as ViewHeader } from './headers/view-header';

export default {
  Input,
};
