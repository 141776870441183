import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';

const ProfileMenuHeader = ({ className, userName }) => {
  return <div className={cn(className)}>{userName}</div>;
};

export default styled(ProfileMenuHeader)`
  ${style}
`;
