import { useState, useEffect } from 'react';
import { get } from 'lodash';
import { API } from '../../../../../../api';

export const useData = () => {
  const [entities, setEntities] = useState([]);
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    // TODO move to api sdk.
    API.Wdc('connex', {
      Action: 'listEntities',
    })
      .then(response => {
        setEntities(get(response, 'Items', []));
      })
      .catch(e => {
        // TODO: notification service
        setEntities([]);
        console.log(e);
      })
      .finally(() => {
        setBusy(false);
      });
  }, []);
  return { busy, entities };
};

export default useData;
