import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Result as AntResult } from 'antd';
import propTypes from 'prop-types';
import style from './style';
import { useIntl } from 'react-intl';

const Result = ({ className, status, title, subTitle }) => {
  const { formatMessage } = useIntl();
  return (
    <div className={cn(className)}>
      <AntResult
        status={status}
        title={formatMessage({ id: title })}
        subTitle={(subTitle && formatMessage({ id: subTitle })) || ''}
      />
    </div>
  );
};

Result.propTypes = {
  className: propTypes.string,
  status: propTypes.string,
  title: propTypes.string,
  subTitle: propTypes.string,
};

export default styled(Result)`
  ${style}
`;
