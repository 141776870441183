import React from 'react';
import cn from 'classnames';
import { useThemeContext } from '../../../theme-provider';

const BASE_SIZE_PX = '20';

const Icon = ({ className, active, innerRef }) => {
  const { fontSizeMultiplier } = useThemeContext();

  return (
    <svg
      viewBox="0 0 11 11 "
      width={BASE_SIZE_PX * fontSizeMultiplier}
      height={BASE_SIZE_PX * fontSizeMultiplier}
      ref={innerRef}
      className={cn('cx-icon', { active }, className)}
    >
      <rect className="s s1" x="0" y="0" width="3" height="3" />
      <rect className="s s2" x="4" y="0" width="3" height="3" />
      <rect className="s s3" x="8" y="0" width="3" height="3" />

      <rect className="s s4" x="0" y="4" width="3" height="3" />
      <rect className="s s5" x="4" y="4" width="3" height="3" />
      <rect className="s s6" x="8" y="4" width="3" height="3" />

      <rect className="s s7" x="0" y="8" width="3" height="3" />
      <rect className="s s8" x="4" y="8" width="3" height="3" />
      <rect className="s s9" x="8" y="8" width="3" height="3" />
    </svg>
  );
};

export default Icon;
