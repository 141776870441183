import SummaryWidget from './summary-widget';
import React from 'react';

export default ({ metrics }) => {
  const Values = metrics || {};
  const { DexaCount = 0, ConnectedCount = 0, BuildItCount = 0 } = Values;

  return (
    <SummaryWidget
      loading={!metrics}
      icon="desktop"
      total={ConnectedCount}
      headline={`CONNEX Connected`}
      leftTitle="DExA"
      rightTitle="BuildIt"
      leftButton={`${Math.round(DexaCount / 1000000)}M`}
      rightButton={`${Math.round(BuildItCount / 1000000)}M`}
    />
  );
};
