import { css } from 'styled-components';

export default css`
  padding: 10px;

  h4 {
    margin-top: 20px;
    text-align: left;
  }

  .options {
    text-align: left;
  }

  .product-area {
    float: right;
  }

  table,
  tbody,
  tr {
    width: 100%;
    font-weight: normal;
    font-size: 1rem;
  }

  td:nth-child(2) {
    text-align: left;
  }

  td:last-child {
    text-align: right;
    padding-left: 20px;
  }

  .factor {
    width: 100px;
  }

  .idea {
    color: #fff0f6;
    &:hover {
      cursor: pointer;
      color: #3c9be8;
    }
  }

  .bottom-idea-visible {
    color: #999999;

    &:hover {
      cursor: pointer;
      color: #3c9be8;
    }
  }

  .bottom-idea-hidden {
    display: none;
  }

  .feature-details {
    padding: 20px;
  }
`;
