import { css } from 'styled-components';
import { SIDEBAR_WIDTH_PX } from '../../constants';

export default css`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.1s;

  width: ${SIDEBAR_WIDTH_PX}px;

  border-top: 1px solid var(--sidebarDividerColor);
  border-bottom: 1px solid var(--sidebarDividerColor);

  &&& {
    &:hover {
      &:not(.open) {
        background-color: var(--sidebarClosedMenuItemHover);
      }
    }

    &.open {
      .cx-submenu-title-container {
        &:hover {
          background-color: var(--sidebarOpenMenuItemHover);
          .toggle-icon {
            opacity: 1;
            transform: rotate(0deg);
          }
        }
      }
      .submenu-item {
        &:hover {
          background-color: var(--sidebarOpenMenuItemHover);
        }
      }
    }
  }

  .cx-submenu-items {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    .submenu-item {
      height: 30px;
      .icon {
        background-color: green;
      }
    }
  }

  .cx-submenu-title-container {
    cursor: pointer;
    border: none;
    background-color: transparent;
    position: relative;
    .ant-typography {
      color: var(--sidebarBaseTextColor);
    }
    .toggle-icon {
      position: absolute;
      font-size: 12px;
      top: 25px;
      right: 15px;
      opacity: 0;
      transform: rotate(180deg);
      transition: transform 0.3s, opacity 0.3s;
    }

    &:active,
    &:focus {
      outline: none;
    }

    .items {
      h6 {
        width: ${SIDEBAR_WIDTH_PX - 25}px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1em;
        color: var(--sidebarMutedTextColor);
      }
    }
  }

  &.drawer {
    height: 80px;
    .items {
      transition: opacity 0.3s;
    }
    &:not(.open) {
      .cx-submenu-title-container {
        flex: 0 0 80px;
      }
    }

    .cx-submenu-title-container {
      text-align: left;
      overflow: hidden;
      padding: 20px 0 20px 15px;

      .title {
        flex: 0 0 20px;
      }
      .items {
        font-size: 0.8em;
        color: #666;
        flex: 0 0 20px;
        opacity: 1;
      }
    }
    &.open {
      background-color: var(--sidebarOpenBackgroundColor);
      .cx-submenu-title-container {
        margin-bottom: 0;
        .items {
          opacity: 0;
        }
      }
    }
    &.open.fixed-full {
      flex: ${({ items }) => `0 0 ${80 + items.length * 30}px;`};
    }
  }

  &:not(.drawer) {
    &.fixed-icon {
      &:not(.open) {
        height: 0;
        padding: 0;
        visibility: hidden;
      }
    }

    &.fixed-icon {
      .cx-submenu-items {
        padding-top: 15px;
        padding-bottom: 5px;
      }
      .cx-submenu-title-container {
        height: 0;
        opacity: 0;
        visibility: hidden;
      }
      &.open {
      }
    }

    &.fixed-full {
      height: 80px;
      .items {
        transition: opacity 0.2s;
      }

      &:not(.open) {
        .cx-submenu-title-container {
          flex: 0 0 80px;
        }
      }

      .cx-submenu-title-container {
        text-align: left;
        overflow: hidden;
        padding: 20px 0 20px 15px;

        .title {
          flex: 0 0 20px;
        }
        .items {
          flex: 0 0 20px;
          font-size: 0.8em;
          color: #666;
          opacity: 1;
        }
      }

      &.open {
        background-color: var(--sidebarOpenBackgroundColor);
        .cx-submenu-title-container {
          margin-bottom: 0;
          .items {
            opacity: 0;
          }
        }
      }
    }

    &.open.fixed-full {
      flex: ${({ items }) => `0 0 ${80 + items.length * 30}px;`};
    }
  }
`;
