import { css } from 'styled-components';

export default css`
  .dropdown-content {
    display: flex;
    cursor: pointer;

    > :first-child {
      margin-right: 0.8em;
    }
    .label {
      .ant-typography {
        color: var(--themeSwitcherTextColor);
      }
    }
  }
`;
