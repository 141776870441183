// import i18n from './i18n';
import ApiMetrics from './ApiMetrics';

export default {
  id: 'api-metrics',
  i18n: {
    'en-US': {},
    'es-ES': {},
    'fr-FR': {},
  },
  disabled: false,
  component: ApiMetrics,
  routes: [],
  persona: 'developer',
  permissions: {},
};
