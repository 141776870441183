import { css } from 'styled-components';

export default css`
  cursor: pointer;
  .dropdown-content {
    display: flex;
    align-items: center;
    > :first-child {
      margin-right: 0.8em;
    }
  }
`;
