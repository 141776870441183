import styled from 'styled-components';

export default styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--sidebarOverlayBackgroundColor);
  z-index: 2;
  opacity: ${({ open, mode }) =>
    open && mode === 'drawer' ? 'var(--sidebarOverlayOpacity)' : '0'};
  visibility: ${({ open, mode }) => (open && mode === 'drawer' ? 'visible' : 'hidden')};

  transition: opacity 0.3s, visibility 0.3s;
`;
