import React, { useCallback, useState } from 'react';
import propTypes from 'prop-types';
import Button from '../button';
import Modal from '../modal';
import { useIntlUtils } from 'components/i18n/useIntlUtils';

export const ConfirmButton = ({
  className,
  confirmLabel,
  cancelLabel,
  confirmMessage,
  confirmTitle,
  onClick,
  ...props
}) => {
  const { translate } = useIntlUtils();

  const [visible, setVisible] = useState(false);

  const handleCancel = useCallback(() => {
    setVisible(false);
  }, []);

  const handleConfirm = useCallback(() => {
    setVisible(false);
    onClick && onClick();
  }, [onClick]);

  return (
    <>
      <Button onClick={() => setVisible(true)} {...props} />
      <Modal
        title={translate(confirmTitle)}
        onCancel={handleCancel}
        visible={visible}
        maskClosable={false}
        footer={[
          <Button label={translate(cancelLabel)} onClick={handleCancel} />,
          <Button label={translate(confirmLabel)} type="primary" onClick={handleConfirm} />,
        ]}
        destroyOnClose
      >
        {translate(confirmMessage)}
      </Modal>
    </>
  );
};

ConfirmButton.propTypes = {
  className: propTypes.string,
  confirmLabel: propTypes.string,
  confirmMessage: propTypes.any,
  confirmTitle: propTypes.string,
  onClick: propTypes.func,
};

export default ConfirmButton;
