import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import propTypes from 'prop-types';
import { Input as AntInput } from 'antd';
import style from './style';

const InputSearch = ({ className, ...props }) => {
  return <AntInput.Search className={cn('cx-input-search', className)} {...props} />;
};

InputSearch.prototypes = {
  className: propTypes.string,
};

export default styled(InputSearch)(style);
