import React from 'react';
import { useParams } from 'react-router-dom';
import cn from 'classnames';
import { Result as AntResult } from 'antd';
import useIntlProps from '../../components/i18n/useIntlProps';

export const Error = ({ className }) => {
  const { errorType } = useParams();

  const [error] = useIntlProps(`errors.${errorType}`);

  return (
    <div className={cn(className)}>
      <AntResult status="error" title={error} />
    </div>
  );
};

export default Error;
