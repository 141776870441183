import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import ThemeSwitcher from '../app-theme-switcher';
import LocaleSwitcher from '../app-locale-switcher/LocaleSwitcher';
import ApiStatus from '../api-status';
import pkg from '../../../../package.json';
import style from './style';
import FontSizeSwitcher from '../app-font-size-switcher/FontSizeSwitcher';
import { useSidebarContext } from '../sidebar/components/sidebar-context';
import { MODE } from '../sidebar/constants';
import Text from '../../../components/typography/text';

const Footer = ({ className }) => {
  const { mode } = useSidebarContext();

  return (
    <div className={cn(className, 'cx-app-footer', { small: mode === MODE.DRAWER })}>
      <div className="theme-switcher">
        <ThemeSwitcher />
      </div>
      <div className="locale-switcher">
        <LocaleSwitcher />
      </div>
      <div className="font-size-switcher">
        <FontSizeSwitcher />
      </div>
      <div className="build-info">
        <Text text="version" values={{ version: pkg.version }} className="version-number" />
      </div>
      <div className="api-status">
        <ApiStatus />
      </div>
    </div>
  );
};

export default styled(Footer)`
  ${style}
`;
