import component from './';
import i18n from './i18n';
// import permissions from './permissions';

export default {
  id: 'queries',
  moduleId: 'queries',
  component,
  i18n,
  permissions: {},
};
