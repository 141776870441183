import styled from 'styled-components';

export default styled.div`
  background-color: var(--select-dropdown-background-color);
  .cx-option {
    &.ant-select-item-option-active {
      &:not(.ant-select-item-option-selected) {
        background-color: var(--select-dropdown-item-hover-background-color);
      }
    }
    &.ant-select-item-option-selected {
      font-weight: bold;
      background-color: var(--select-dropdown-item-selected-background-color);
    }
    .ant-select-item-option-content {
      color: var(--select-dropdown-color);
    }
  }
`;
