import React from 'react';
import { useAppsConfigContext } from '../components/app-config/AppsConfigContext';
import { Redirect } from 'react-router-dom';
import { cache } from '../../util';
import { APP_PATH, ERROR_PATH } from '../components/sidebar/constants';

const InitialApp = () => {
  const { appsConfig = [] } = useAppsConfigContext();

  if (!appsConfig.length) {
    return <Redirect to={`${ERROR_PATH}/notProvisioned`} />;
  }

  const apps = appsConfig.map(appConfig => appConfig.id);

  const cachedApp = cache.getItem('cx.app');

  if (cachedApp) {
    const isCachedAppStillGood = apps.includes(cachedApp);
    if (isCachedAppStillGood) {
      return <Redirect to={`${APP_PATH}/${cachedApp}`} />;
    }
  }

  return <Redirect to={`${APP_PATH}/${apps[0]}`} />;
};

export default InitialApp;
