export default {
  add: 'Додати',
  apiCatalog: 'Каталог API',
  cancelQuery: 'Скасувати запит',
  codeExample: 'Приклад коду',
  componentLibrary: 'Бібліотека компонентів',
  confirmDelete: {
    information: 'У вашому запиті, {name}, є зміни, які не було збережено.',
  },
  deleteTabConfirmation: 'Видалити запит - підтвердити',
  deleteTabDiscard: 'не врятувати',
  deleteTabCancel: 'Скасувати',
  fields: 'Поля',
  handoff: {
    alreadyOpenTitle: 'Вже відкрито',
    alreadyOpenMessage:
      'Цей запит уже відкритий. Ви можете відкрити копію або перейти на існуючу вкладку.',
    newNameLabel: 'Нова назва',
    switchToTab: 'Перемикання вкладок',
    openAsCopy: 'Відкрийте копію',
  },
  itemDetails: 'Деталі',
  itemsReturned: 'Повернуті товари',
  JSON: 'JSON',
  large: 'Великий',
  medium: 'Середній',
  missingParameters: 'Наступні параметри не визначені: {missingParameterNames}',
  models: 'Моделі',
  moduleDisplayLabel: 'Розробник',
  name: "Ім'я",
  parameters: 'Параметри',
  performance: 'Продуктивність',
  performanceMetrics: 'Показники ефективності',
  private: 'Приватна',
  public: 'Громадський',
  queries: 'Запити',
  queryCanceled: 'Запит скасовано',
  recentQueries: 'Останні запити',
  refreshColumns: 'Оновити стовпці',
  run: 'Біжи',
  runTest: 'Запустіть тест',
  save: 'зберегти',
  saveAs: 'Зберегти як',
  saveQuery: {
    save: 'Зберегти запит',
    cancel: 'Скасувати',
    title: 'Зберегти запит',
  },
  services: 'Послуги',
  size: 'Розмір',
  small: 'Маленький',
  sqlError: 'Помилка SQL',
  table: 'Таблиця',
  time: 'Час',
  type: 'Тип',
  userCanceledQuery: 'Користувач скасував запит.',
  value: 'Значення',
};
