import React, { useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import propTypes from 'prop-types';
import { Button as AntButton, Space } from 'antd';
import { useIntl } from 'react-intl';
import style from './style';

export const Button = ({
  className,
  'data-testid': dataTestId,
  id,
  loading,
  onClick,
  disabled,
  label,
  type,
  htmlType,
  style,
  icon,
  size,
  title,
}) => {
  const { formatMessage } = useIntl();

  const intlLabel = useMemo(() => {
    if (label && typeof label === 'string') return formatMessage({ id: label });
    return label;
  }, [formatMessage, label]);

  const intTitle = useMemo(() => {
    if (title && typeof title === 'string') return formatMessage({ id: title });
    return title;
  }, [formatMessage, title]);

  return (
    <AntButton
      id={id}
      type={type || 'default'}
      htmlType={htmlType || 'button'}
      className={cn('cx-button', type, className)}
      data-testid={dataTestId}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      style={style}
      size={size}
      title={intTitle || null}
    >
      <Space>
        {!loading && icon && <div>{icon}</div>}
        {intlLabel && <div>{intlLabel}</div>}
      </Space>
    </AntButton>
  );
};

Button.propTypes = {
  className: propTypes.string,
  'data-testid': propTypes.string,
  id: propTypes.string,
  loading: propTypes.bool,
  onClick: propTypes.func,
  disabled: propTypes.bool,
  label: propTypes.any,
  type: propTypes.string,
  htmlType: propTypes.string,
  style: propTypes.object,
  icon: propTypes.element,
  size: propTypes.string,
  title: propTypes.string,
};

export default styled(Button)`
  ${style}
`;
