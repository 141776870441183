export default {
  moduleDisplayLabel: 'Документи API',
  apiCatalog: 'Каталог API',
  codeExample: 'Приклад коду',
  componentLibrary: 'Бібліотека компонентів',
  fields: 'Поля',
  models: 'Моделі',
  services: 'Послуги',
  run: 'Біжи',
};
