import React from 'react';

const SidebarContext = React.createContext();

export const useSidebarContext = () => {
  const context = React.useContext(SidebarContext);
  if (!context) {
    throw new Error('useSidebarContext cannot be used outside the Sidebar Context Provider');
  }
  return context;
};

export default ({ value, children }) => (
  <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
);
