import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import FlexColumn from 'components/layout-flex-column';
import { Route } from 'react-router-dom';
import { Module } from 'core/modules/Module';

import View from './View';

const PullRequests = ({ module, className }) => {
  return (
    <Module module={module}>
      <>
        <Route
          path={`${module.path}/:pullRequestId?`}
          render={() => (
            <FlexColumn className={cn('pull-requests', className)}>
              <View module={module} />
            </FlexColumn>
          )}
        />
      </>
    </Module>
  );
};

export default styled(PullRequests)`
  ${style}
`;
