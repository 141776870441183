import React, { useCallback } from 'react';
import { find } from 'lodash';
import styled from 'styled-components';
import { SUPPORTED_LANGUAGES, useIntlContext } from '../../../i18n';
import cn from 'classnames';
import style from './style';
import Flag from '../flags';
import DropdownMenu, { PLACEMENT, TRIGGER } from '../../../components/dropdown-menu';
import Text from '../../../components/typography/text';

const localeMenuItems = SUPPORTED_LANGUAGES.map(locale => ({
  id: locale.id,
  label: locale.label,
  icon: <Flag id={locale.id} />,
}));

const LocaleSwitcher = ({ className }) => {
  const { userLocale, setUserLocale } = useIntlContext();

  const locale = find(SUPPORTED_LANGUAGES, l => l.id.toLowerCase() === userLocale.toLowerCase());

  const handleMenuClick = useCallback(
    localeId => {
      setUserLocale(localeId);
    },
    [setUserLocale]
  );

  return (
    <div className={cn('cx-locale-switcher', className)}>
      <DropdownMenu
        trigger={[TRIGGER.CLICK]}
        placement={PLACEMENT.TOP_LEFT}
        menuItems={localeMenuItems}
        onClick={handleMenuClick}
        activeId={userLocale}
      >
        <div className="dropdown-content">
          <div className="icon">
            <Flag id={locale.id} />
          </div>
          <div className="label">
            <Text text={locale.label} />
          </div>
        </div>
      </DropdownMenu>
    </div>
  );
};

export default styled(LocaleSwitcher)(style);
