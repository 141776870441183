import React, { useMemo } from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import propTypes from 'prop-types';
import { Label } from '../';

const FormItemWrapper = ({ className, htmlFor, innerRef, label, children, ...props }) => {
  const { formatMessage } = useIntl();

  const intlLabel = useMemo(() => {
    if (label) return formatMessage({ id: label });
    return null;
  }, [formatMessage, label]);

  return (
    <div className={cn('form-item-wrapper', className)} {...props}>
      {label && (
        <div>
          <Label htmlFor={htmlFor} text={intlLabel} />
        </div>
      )}
      <div>{children}</div>
    </div>
  );
};

FormItemWrapper.propTypes = {
  className: propTypes.string,
  htmlFor: propTypes.string,
  innerRef: propTypes.string,
  label: propTypes.oneOfType([propTypes.string, propTypes.object]),
  children: propTypes.element,
};

export default FormItemWrapper;
