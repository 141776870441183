import Call from '../call';

//TODO: rename these methods to conform to generally accepted JS naming conventions.

const SearchPartnerRq = async Types => {
  return Call('entity', 'SearchPartnerRq', { Types });
};

const SearchMasterDataRq = async SID => {
  return Call('entity', `Search${SID}Rq`, {});
};

const CreateProductRq = async (ID, Description) => {
  return Call('entity', 'CreateProductRq', { ID, Description });
};

const GetProductRq = async () => {
  return Call('entity', 'GetProductRq', {});
};

const UpdateProductRq = async (ID, Description, CONNEX) => {
  return Call('entity', 'UpdateProductRq', { ID, Description, CONNEX });
};

const SearchProductRq = async SID => {
  return Call('entity', 'SearchProductRq', { SID });
};

const CreateVehicleRq = async (ID, Description) => {
  return Call('entity', 'CreateVehicleRq', { ID, Description });
};

const UpdateVehicleRq = async (ID, Description, CONNEX) => {
  return Call('entity', 'UpdateVehicleRq', { ID, Description, CONNEX });
};

const CreateDisciplineRq = async (ID, Description) => {
  return Call('entity', 'CreateDisciplineRq', { ID, Description });
};

const UpdateDisciplineRq = async (ID, Description, CONNEX) => {
  return Call('entity', 'UpdateDisciplineRq', { ID, Description, CONNEX });
};

export const Products = {
  GetProductRq,
};

export const Partners = {
  SearchPartnerRq,
};

export const MasterData = {
  SearchMasterDataRq,
  CreateProductRq,
  UpdateProductRq,
  SearchProductRq,
  CreateVehicleRq,
  UpdateVehicleRq,
  CreateDisciplineRq,
  UpdateDisciplineRq,
};
