import { useCallback, useState } from 'react';
import { ChartRq } from '../../../api';

export const useEntityMetrics = () => {
  const [entity, setEntity] = useState([]);
  const [loading, setLoading] = useState(true);

  const getEntitySummary = useCallback(EID => {
    setLoading(true);

    ChartRq({
      Action: 'getEntitySupplierSummary',
      EID,
    })
      .then(({ response }) => {
        setEntity(response);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, []);

  return {
    loading,
    entity,
    getEntitySummary,
  };
};

export default useEntityMetrics;
