import styled from 'styled-components';

export default styled.div`
  &&& {
    background-color: var(--sidebarTooltipBackgroundColor); //#ddddde;
    border: 1px solid var(--sidebarTooltipBorderColor); //#ccc;
    font-size: var(--sidebarTooltipFontSize); //0.8em;
    color: var(--sidebarTooltipColor);

    padding: 0 10px;
    border-radius: 4px;
  }
`;
