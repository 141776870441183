import React, { useEffect } from 'react';
import { Button, ConfirmButton, Text } from 'components';
import { usePR } from '../usePR';
import cn from 'classnames';
import { Switch } from 'antd';

const Actions = ({ pullRequestId, mergeable = true, onMerged, onCancel }) => {
  const {
    setTriggerTests,
    setTriggerBuild,
    triggerTests,
    triggerBuild,
    mergePullRequest,
    merging,
    pullRequest,
  } = usePR(pullRequestId);

  useEffect(() => {
    pullRequest && pullRequest.status === 'CLOSED' && onMerged && onMerged(pullRequest);
  }, [onMerged, pullRequest]);

  return (
    <div className="actions">
      <div className="attributes">
        <Switch
          className={cn('trigger-build-switch')}
          disabled={!mergeable}
          checkedChildren={<Text text="pull-requests.withBuild" />}
          unCheckedChildren={<Text text="pull-requests.withoutBuild" />}
          checked={triggerBuild}
          onChange={setTriggerBuild}
        />

        <Switch
          className={cn('trigger-tests-switch')}
          disabled={!mergeable}
          checked={triggerTests}
          onChange={setTriggerTests}
          checkedChildren={<Text text="pull-requests.withTests" />}
          unCheckedChildren={<Text text="pull-requests.withoutTests" />}
        />
      </div>

      <div className="buttons">
        <Button
          id="create-btn"
          className="create-btn"
          type="primary"
          loading={merging}
          label="pull-requests.cancel-merge-pull-request"
          onClick={onCancel}
        />
        <ConfirmButton
          id="create-btn"
          className="create-btn"
          type="primary"
          loading={merging}
          label="pull-requests.merge-pull-request"
          onClick={mergePullRequest}
          disabled={!mergeable}
          confirmLabel="pull-requests.confirm-pull-request-modal-confirmed-button-label"
          cancelLabel="pull-requests.confirm-pull-request-modal-cancel-button-label"
          confirmMessage="pull-requests.confirm-pull-request-modal-message"
          confirmTitle="pull-requests.confirm-pull-request-modal-title"
        />
      </div>
    </div>
  );
};

export default Actions;
