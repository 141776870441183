import { useCallback, useState } from 'react';
import SDLC from '../../../../../../api/sdlc';
import useHandleApiCall from 'util/handle-api-call';
import { useUserContext } from 'core/contexts/user/UserContextProvider';

export const useBuild = () => {
  const {
    user: { username: author },
  } = useUserContext();
  const [builds, setBuilds] = useState([]);
  const [build, setBuild] = useState(null);

  const _createBuild = useCallback(CreateBuildRq => {
    setBuild(null);
    return SDLC('CreateBuild', {
      ...CreateBuildRq,
    }).then(setBuild);
  }, []);

  const _listBuilds = useCallback((maxResults = 10, status = 'Success') => {
    setBuilds(null);
    return SDLC('ListBuildHistory', {
      status,
      maxResults,
    }).then(setBuilds);
  }, []);

  const [createBuild, creating] = useHandleApiCall(_createBuild);
  const [listBuilds, listing] = useHandleApiCall(_listBuilds);

  return {
    build,
    builds,
    createBuild,
    creating,
    listBuilds,
    listing,
  };
};
