import { css } from 'styled-components';

export default css`
  .ant-tabs {
    display: flex;
    flex-direction: column;
    min-height: 0;
    min-width: 0;
    flex: 1;

    .ant-tabs-content-holder {
      display: flex;
      flex-direction: column;
      min-height: 0;
      min-width: 0;
      flex: 1;

      .ant-tabs-content {
        display: flex;
        flex-direction: column;
        min-height: 0;
        min-width: 0;
        flex: 1;

        .ant-tabs-tabpane {
          display: flex;
          flex-direction: column;
          min-height: 0;
          min-width: 0;
          flex: 1;
        }
      }
    }
  }
`;
