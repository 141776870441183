import { useLocation, useParams } from 'react-router-dom';
import FlexColumn from '../../../../../../../components/layout-flex-column';
import React, { useCallback, useEffect, useState } from 'react';
import EntityHeader from './entity-header';
import useEntityMetrics from './useEntityMetrics';
import TicketsByMonth from './tickets-by-month';

const EntityView = () => {
  const [dateFilters, setDateFilters] = useState(null);

  const { getEntitySummary, entity, loading } = useEntityMetrics();
  const { state: summary } = useLocation();
  const { entityRef } = useParams();

  const refresh = useCallback(() => {
    getEntitySummary(entityRef);
  }, [entityRef, getEntitySummary]);

  useEffect(() => {
    entityRef && refresh();
  }, [entityRef, refresh]);

  return (
    <FlexColumn>
      <EntityHeader
        entityRef={entityRef}
        entity={entity}
        filtersChanged={setDateFilters}
        summary={summary}
        loading={loading}
        onRefresh={refresh}
      />
      {dateFilters && <TicketsByMonth entityRef={entityRef} dateFilters={dateFilters} />}
    </FlexColumn>
  );
};

export default EntityView;
