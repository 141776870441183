import { css } from 'styled-components';

export default css`
  .header {
    font-weight: bold;
  }

  .prevents-merge-true {
    color: red;
  }
`;
