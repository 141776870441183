export default {
  add: 'Add',
  apiCatalog: 'API Catalog',
  cancelQuery: 'Cancel Query',
  codeExample: 'Code Example',
  componentLibrary: 'Component Library',
  confirmDelete: {
    information: 'Your query, {name}, has changes that have not been saved.',
  },
  deleteTabConfirmation: 'Delete Query - Confirm',
  deleteTabDiscard: "Don't Save",
  deleteTabCancel: 'Cancel',
  openQuery: {
    information: 'SQL is used for most queries, but javascript should be used for more efficient filtering for workspace widgets.',
    choice: 'Query Type',
    confirm: 'Open Query - Confirm'
  },
  newQuery: 'New Query',
  fields: 'Fields',
  handoff: {
    alreadyOpenTitle: 'Already Open',
    alreadyOpenMessage:
      'This query is already open. You may open a copy, or switch to the existing tab.',
    newNameLabel: 'New Name',
    switchToTab: 'Switch Tabs',
    openAsCopy: 'Open A Copy',
  },
  itemDetails: 'Item Details',
  itemsReturned: 'Items Returned',
  JSON: 'JSON',
  large: 'Large',
  medium: 'Medium',
  missingParameters: 'The following parameters are not defined: {missingParameterNames}',
  models: 'Models',
  moduleDisplayLabel: 'Developer',
  name: 'Name',
  parameters: 'Parameters',
  performance: 'Performance',
  performanceMetrics: 'Performance Metrics',
  private: 'Private',
  public: 'Public',
  queries: 'Queries',
  queryCanceled: 'Query Canceled',
  recentQueries: 'Recent Queries',
  refreshColumns: 'Refresh Columns',
  run: 'Run',
  runTest: 'Run Test',
  save: 'Save',
  saveAs: 'Save As',
  saveQuery: {
    save: 'Save Query',
    cancel: 'Cancel',
    title: 'Save Query',
  },
  services: 'Services',
  size: 'Size',
  small: 'Small',
  sqlError: 'SQL Error',
  table: 'Table',
  time: 'Time',
  type: 'Type',
  userCanceledQuery: 'Query was canceled by user.',
  value: 'Value',
};
