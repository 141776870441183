import styled from 'styled-components';

export default styled.div`
  &&& {
    background-color: var(--date-picker-panel-background-color);
    box-shadow: var(--date-picker-box-shadow);

    .ant-picker-panel {
      border: var(--date-picker-border);

      .ant-picker-header {
        border-bottom-color: var(--date-picker-divider-color);
        .ant-picker-header-super-prev-btn,
        .ant-picker-header-prev-btn,
        .ant-picker-header-next-btn,
        .ant-picker-header-super-next-btn,
        .ant-picker-month-btn,
        .ant-picker-year-btn {
          color: var(--date-picker-header-text-color);
          font-weight: bold;
          &:hover {
            color: var(--date-picker-header-text-hover-color);
          }
        }
      }

      .ant-picker-body {
        table.ant-picker-content {
          th {
            color: var(--date-picker-color);
          }

          .ant-picker-cell {
            color: var(--date-picker-out-of-view-color);

            &.ant-picker-cell-selected {
              .ant-picker-cell-inner {
                border: 1px solid var(--date-picker-selected-border-color);
                background-color: var(--date-picker-selected-background-color);
                font-weight: var(--date-picker-selected-font-weight);
                color: var(--date-picker-selected-color);
                &:hover {
                  background-color: var(--date-picker-selected-background-color);
                  color: var(--date-picker-selected-hover-color);
                }
              }
            }

            &:not(.ant-picker-cell-selected) {
              .ant-picker-cell-inner {
                &:hover {
                  background-color: var(--date-picker-hover-background-color);
                  color: var(--date-picker-hover-color);
                }
              }

              &.ant-picker-cell-in-view {
                color: var(--date-picker-in-view-color);
                .ant-picker-cell-inner {
                  &:hover {
                    background-color: var(--date-picker-hover-background-color);
                    color: var(--date-picker-hover-color);
                  }
                }
              }
              &.ant-picker-cell-today {
                color: var(--date-picker-today-color);
                .ant-picker-cell-inner {
                  &:before {
                    border-color: var(--date-picker-today-border-color);
                  }
                  &:hover {
                    background-color: var(--date-picker-hover-background-color);
                    color: var(--date-picker-hover-color);
                  }
                }
              }
            }
          }

          .ant-picker-cell {
          }
        }
      }

      .ant-picker-footer {
        border-top-color: var(--date-picker-divider-color);
        .ant-picker-today-btn {
          color: var(--date-picker-today-button-color);
        }
      }
    }
  }
`;
