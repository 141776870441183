import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import ManageSidebar from './ManageSidebar';
import { cache } from '../../util';

const CacheLastVisitedModule = ({ children }) => {
  const match = useRouteMatch();
  const { appId } = useParams();

  if (match && appId) {
    cache.setItem(`cx.lastVisitedModule.${appId}`, match.url);
  }

  return <ManageSidebar>{children}</ManageSidebar>;
};

export default CacheLastVisitedModule;
