import React from 'react';
import { Cell, Legend, Pie, PieChart, Tooltip } from 'recharts';
import { Skeleton } from 'antd';

const COLORS = ['#0088FE', '#00C49F', '#FF0042'];

// eslint-disable-next-line
const Waiting = () => {
  return (
    <div
      style={{
        paddingLeft: '100px',
        width: '50%',
        height: '50%',
      }}
    >
      <Skeleton
        className="waiting"
        size="small"
        title={false}
        paragraph={{ rows: 3, width: [0, 50, 50] }}
        active
      />
    </div>
  );
};

const pieTip = ({ payload = [] }) => {
  return (
    payload.length > 0 && (
      <div>
        <p>{`${payload[0].name} - ${payload[0].value}`}</p>
      </div>
    )
  );
};

const PieOne = ({ data, onClick, startAngle = 0, endAngle = 360 }) => {
  if (data === null || data === undefined || data.length < 1) {
    data = [
      { name: 'Pending', value: 1 },
      { name: 'Accepted', value: 1 },
      { name: 'Rejected', value: 1 },
    ];
  }

  const size = { width: 300, height: 300 };

  return (
    <PieChart width={size.width} height={size.height}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={60}
        outerRadius={80}
        fill="#8884d8"
        dataKey="value"
        paddingAngle={5}
        onClick={e => onClick && onClick(e)}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip content={pieTip} />
      <Legend />
    </PieChart>
  );
};

export default ({ type = 'OneLevel', onClick, data }) => {
  return (
    <div style={{ width: '100%', height: `100%`, textAlign: 'center' }}>
      {type === 'OneLevel' && <PieOne onClick={onClick} data={data} />}
    </div>
  );
};
