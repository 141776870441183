import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  .audit-logs {
    display: flex;
    flex-direction: column;
    flex: 1 1 0%;
    overflow: hidden;
  }
  .audit-log-header {
    .export-buttons {
      float: right;
      margin-top: -7px;
      margin-bottom: 6px;
    }
    .result-count {
      float: left;
      opacity: 1;
      transition: opacity 0.4s;
      &.busy {
        opacity: 0;
      }
    }
    .filter-container {
      grid-template-columns: 1fr 1fr;
      grid-template-areas:
        'one one'
        'two two';
      margin: 10px;
      padding: 10px;

      display: grid;
      grid-gap: 10px;

      .buttons {
        margin-top: 12px;
      }

      .dates {
        grid-area: two;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
        grid-gap: 15px;
        .buttons {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(75px, 1fr));
          grid-gap: 15px;
          button {
            max-width: 200px;
          }
        }
      }

      .options {
        grid-area: one;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
        grid-gap: 15px;
        margin-bottom: 10px;
      }
    }
    .table-utils {
      padding: 12px;
    }
  }
  .audit-log-body {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding-bottom: 10px;
    .ant-spin {
      &.ant-spin-spinning {
        margin-top: 5%;
      }
    }

    .no-data {
      margin-top: 5%;
    }

    .ant-table-header {
      .ant-table-cell {
        background-color: var(--table-header-background-color);
      }
    }
    .ant-table-body {
      background-color: var(--table-background-color);
      .ant-table-row {
        &.active {
          background-color: var(--table-row-highlight-background-color);
        }
        &.alt {
          background-color: var(--table-background-color-alt-row);
        }
      }
    }
  }
`;
