const hostname = window.location.hostname;
const p = hostname.split('.');
const subDomain = p[0];

const consoleService = () => {
  switch (subDomain) {
    case 'console':
      return 'f45710aa1f12f2aaa6bd69d4a2d1359b049f49be59c6cf684b8b3796428f3326';
    case 'connex-console-develop-app':
      return 'd36ce94d6002a701825cd0117cce8ed4bc74b891c48f92de1f7cb6f08023aaa8';
    case 'console-stage':
      return 'd36ce94d6002a701825cd0117cce8ed4bc74b891c48f92de1f7cb6f08023aaa8';
    default:
      return 'G9mi9hDoZBaQvwGlJEgVn3StkIfCoJNu6IyylHWL';
  }

  return hostname;
};

const sdlcService = () => {
  return 'GsWKrqDAY99Be7031KXjt56yoaUbMeYl9qlOGPi7';
};

export default (service = 'console') => {
  switch (service) {
    case 'console':
      return consoleService();
    case 'sdlc':
      return sdlcService();
  }

  return hostname;
};
