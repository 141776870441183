export default {
  id: 'entities',
  moduleId: 'entities',
  description: 'Entities',
  modules: [
    {
      id: 'manage-data',
      icon: 'database',
      description: 'Manage Data',
      moduleId: 'manage-data',
    },
  ],
};
