import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { ReactComponent as ConnexLogo } from '../../../assets/connex-logo.svg';
import style from './style';
import CSSTransition from 'react-transition-group/CSSTransition';

const LandingView = ({ className, children, visible }) => {
  if (!visible) return children;

  return (
    <CSSTransition in={visible} appear timeout={500} unmountOnExit className={className}>
      <div className={cn('cx-landing-view')}>
        <>
          <div className="logo">
            <ConnexLogo style={{ height: '50px' }} />
          </div>
          {children}
        </>
      </div>
    </CSSTransition>
  );
};

export default styled(LandingView)(style);
