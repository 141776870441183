import { API, Auth } from 'aws-amplify';
import moment from 'moment';
import { Runtime } from '../../core/configure';

Runtime.initialize('connex');

let useWebIdentity = window.location.hostname.includes('localhost');

export default async (EntityUID, params = {}) => {
  const request = {
    body: {
      jsonrpc: '2.0',
      id: '1',
      method: 'WDCRq',
      params,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  };

  if (useWebIdentity) {
    const Session = await Auth.currentSession();

    if (Session.idToken && Session.idToken.jwtToken) {
      request.headers['Authorization'] = `${Session.idToken.jwtToken}`;
    }
  }

  const startPostTime = moment();

  return API.post('platform', `/${EntityUID}/tableau`, request)
    .then(({ result, error }) => {
      const success = error === undefined;
      const runTime = moment().diff(startPostTime, 'milliseconds');

      if (success === true) {
        return {
          success,
          ...result,
          runTime,
        };
      }
      return {
        success,
        Error: error.data.details,
      };
    })
    .catch(error => {
      console.error(error);

      return {
        success: false,
        response: [error.message || error],
      };
    });
};
