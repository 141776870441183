import { css } from 'styled-components';

const BASE_HEADER_ICON_HEIGHT = 24;

export default css`
  .dropdown-content {
    display: flex;
    align-items: center;
    .avatar {
      .cx-avatar {
        height: ${({ theme }) => `${BASE_HEADER_ICON_HEIGHT * theme.fontSizeMultiplier - 4}px`};
        width: ${({ theme }) => `${BASE_HEADER_ICON_HEIGHT * theme.fontSizeMultiplier - 4}px`};
        transition: all 0.3s;
        svg {
          transition: all 0.3s;
          height: ${({ theme }) => `${BASE_HEADER_ICON_HEIGHT * theme.fontSizeMultiplier - 4}px`};
          width: ${({ theme }) => `${BASE_HEADER_ICON_HEIGHT * theme.fontSizeMultiplier - 4}px`};
        }
      }
      transition: all 0.3s;
      width: ${({ theme }) => `${BASE_HEADER_ICON_HEIGHT * theme.fontSizeMultiplier}px`};
      height: ${({ theme }) => `${BASE_HEADER_ICON_HEIGHT * theme.fontSizeMultiplier}px`};
      position: relative;
      border-radius: 50%;
      background-color: var(--avatarColor);
      color: white;
      text-align: center;
      vertical-align: middle;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
