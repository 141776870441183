import { css } from 'styled-components';

export default css`
  position: fixed;
  opacity: 0;
  transition: opacity 0.3s;

  z-index: 9999;
  height: 311px;
  width: 275px;

  overflow: hidden;
  display: flex;
  flex-direction: column;

  .body-wrapper {
    margin-top: 11px;
    height: 300px;
    background-color: var(--appSwitcherBackgroundColor);
    border: 1px solid var(--appSwitcherBorderColor);
    color: var(--appSwitcherColor);
    border-radius: 4px;
    position: relative;

    flex: 1;
    display: flex;
    flex-direction: column;

    &:before,
    &:after {
      position: absolute;
      left: 11px;
      width: 0;
      height: 0;
      content: '';
      border-left: 11px solid transparent;
      border-right: 11px solid transparent;
      border-bottom-width: 11px;
      border-bottom-style: solid;
    }

    &:before {
      top: -12px; /* extra -1 pixel offset at the top */
      border-bottom-color: var(--appSwitcherBorderColor);
    }

    &:after {
      top: -10px;
      border-bottom-color: var(--appSwitcherBackgroundColor);
    }
    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0;
      min-width: 0;
      overflow: auto;

      .apps {
        flex: 1;
        display: flex;
        flex-direction: column;
        min-height: 0;
        min-width: 0;
        overflow: auto;

        .app-list {
          flex: 1;
          display: flex;
          flex-direction: column;
          min-height: 0;
          min-width: 0;
          overflow: auto;

          .app {
            display: flex;
            height: 35px;
            align-items: center;
            font-weight: normal;
            cursor: pointer;
            padding: 0 15px;
            &:hover {
              background-color: var(--appSwitcherHoverBackgroundColor);
              color: var(--appSwitcherHoverColor);
            }
            .icon {
              width: 40px;
              text-align: center;
              i.fas {
                font-size: 1.3em;
                height: 25px;
              }
            }
            .label {
            }
          }
        }
      }
      .padded-wrapper {
        padding: 10px 15px;
        .section-label {
          font-weight: bold;
          font-size: 1.1em;
          margin: 10px 0;
        }
      }
    }
  }
`;
