import { css } from 'styled-components';

export default css`
  &&& {
    .ant-table-tbody {
      .ant-table-column-sort {
        background: transparent;
      }
    }
    .filter-search {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .ant-table {
      font-size: ${({ theme }) => `${theme.resolvedFontSize}px`};
      .ant-table-header {
        .ant-table-cell {
          background-color: var(--table-header-background-color);
        }
      }
      .ant-table-column-sorters {
        padding: 2px 16px;
      }
      .ant-table-tbody > tr > td,
      .ant-table-thead > tr > th,
      .ant-table tfoot > tr > td,
      .ant-table tfoot > tr > th {
        padding: 2px 16px;
      }
      .ant-table-body {
        background-color: var(--table-background-color);
        .ant-table-row {
          &.alt {
            background-color: var(--table-background-color-alt-row);
          }
        }
        .ant-table-cell {
          cursor: ${props => (props.onRow ? 'pointer' : 'default')};
        }
      }
    }

    .ant-pagination {
      margin: 16px;
    }
  }
`;
