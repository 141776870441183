import component from './view';
import i18n from './i18n';
import permissions from './permissions';

export default {
  id: 'features',
  moduleId: 'features',
  component,
  i18n,
  permissions,
};
