import styled from 'styled-components';
import { Header } from './Header';

const BASE_HEADER_ICON_HEIGHT = 25;
const BASE_HEADER_HEIGHT = 40;

export default styled(Header)`
  height: ${({ theme }) => `${BASE_HEADER_HEIGHT * theme.fontSizeMultiplier}px`};
  color: var(--headerTextColor);
  box-shadow: var(--headerBoxShadow);
  display: flex;
  padding: 0 10px;
  transition: all 0.3s;
  margin-bottom: 5px;

  .left-side {
    flex: 1;
    display: flex;
  }
  .right-side {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > :not(:last-child) {
      margin-right: 15px;
    }

    .user-info {
      font-weight: 400;
      display: flex;
      align-items: center;
      cursor: pointer;
      .avatar {
        margin-right: 10px;
        .ant-avatar {
          background-color: var(--avatarColor);
        }
      }
    }
  }

  .cx-profile-menu {
    .name {
      font-size: ${({ theme }) => `${0.1 + theme.fontSizeMultiplier}rem`};
      transition: all 0.3s;
      display: none;
    }
  }

  &.small {
    .cx-profile-menu {
      .name {
        display: none;
      }
    }
  }

  .cx-drawer-toggle {
    .icon {
      color: var(--drawer-toggle-color);
      display: flex;
      font-size: ${({ theme }) => `${BASE_HEADER_ICON_HEIGHT * theme.fontSizeMultiplier}px`};
      transition: font-size 0.3s;
    }
  }

  .app-switcher-wrapper {
    margin-right: 20px;
    display: flex;
    align-items: center;
  }
  .active-app-label {
    font-weight: bold;
    font-size: 1.1em;
    .ant-typography {
      color: var(--headerTextColor);
    }
  }

  .ant-dropdown {
    border: 1px solid #ccc;
  }
`;
