export default {
  appName: 'Інтегрувати',
  'integrate-dashboard': 'Панель приладів',
  airticket: 'AirTicket',
  'airticket-metrics': 'Метрики',
  'airticket-configure': 'Налаштуйте',
  'command-assurance': 'COMMANDAssurance',
  'command-assurance-metrics': 'Метрики',
  'command-assurance-configure': 'Налаштуйте',
};
