import { useCallback, useState } from 'react';
import SDLC from '../../../../../../api/sdlc';
import useHandleApiCall from 'util/handle-api-call';
import { useUserContext } from 'core/contexts/user/UserContextProvider';

export const usePullRequest = () => {
  const [start, setStart] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [pullRequestDetail, setPullRequestDetail] = useState(null);
  const [pullRequests, setPullRequests] = useState(null);
  const [pullRequest, setPullRequest] = useState(null);
  const [repositoryList, setRepositoryList] = useState(null);

  const {
    user: { username: author },
  } = useUserContext();

  const _getPullRequestDetail = useCallback(pullRequestId => {
    setPullRequestDetail(null);

    return SDLC('GetPullRequest', {
      pullRequestId,
    }).then(setPullRequestDetail);
  }, []);

  const _startPullRequest = useCallback(
    (repositoryName = 'connex') => {
      setStart(null);

      return SDLC('StartPullRequest', {
        repositoryName,
        author,
      }).then(setStart);
    },
    [author]
  );

  const _listRepositories = useCallback(() => {
    setRepositoryList(null);
    return SDLC('ListRepositories', {
      author,
    }).then(setRepositoryList);
  }, [author]);

  const _listPullRequests = useCallback(
    (repositoryName = 'connex', status = 'OPEN', withApprovals = true) => {
      setPullRequests(null);
      return SDLC('ListPullRequests', {
        repositoryName,
        maxResults: status === 'CLOSED' ? 10 : 20,
        status,
        withApprovals,
      }).then(setPullRequests);
    },
    []
  );

  const _analyzePullRequest = useCallback(
    (repositoryName, sourceBranch, targetBranch) => {
      setAnalysis(null);
      return SDLC('AnalyzePullRequest', {
        repositoryName,
        author,
        sourceBranch,
        targetBranch,
      }).then(setAnalysis);
    },
    [author]
  );

  const _createPullRequest = useCallback(
    CreatePullRequestRq => {
      setPullRequest(null);
      return SDLC('CreatePullRequest', {
        ...CreatePullRequestRq,
        author,
      }).then(setPullRequest);
    },
    [author]
  );

  const [analyzePullRequest, analyzing] = useHandleApiCall(_analyzePullRequest);
  const [createPullRequest, creating] = useHandleApiCall(_createPullRequest);
  const [getPullRequestDetail, getting] = useHandleApiCall(_getPullRequestDetail);
  const [startPullRequest, starting] = useHandleApiCall(_startPullRequest);
  const [listPullRequests, listing] = useHandleApiCall(_listPullRequests);
  const [listRepositories, listingRepositories] = useHandleApiCall(_listRepositories);

  return {
    startPullRequest,
    starting,
    start,
    getting,
    getPullRequestDetail,
    listing,
    listPullRequests,
    listRepositories,
    pullRequests,
    analyzePullRequest,
    analyzing,
    analysis,
    createPullRequest,
    pullRequest,
    pullRequestDetail,
    creating,
    listingRepositories,
    repositoryList,
  };
};
