import React from 'react';
import { Button } from 'components';
import { usePullRequest } from '../usePullRequest';

const CreatePullRequestButton = ({
  repositoryName,
  sourceBranch,
  targetBranch,
  issue = {},
  mergeable = true,
  onCreated,
}) => {
  const { createPullRequest, creating } = usePullRequest();

  const onCreate = () => {
    createPullRequest({
      repositoryName,
      sourceBranch,
      targetBranch,
      issueId: issue.id,
    }).then(() => {
      onCreated();
    });
  };

  return (
    <div className="actions">
      <Button
        id="create-btn"
        className="create-btn"
        type="primary"
        loading={creating}
        label="pull-requests.create-pull-request"
        onClick={onCreate}
        disabled={!mergeable}
      />
    </div>
  );
};

export default CreatePullRequestButton;
