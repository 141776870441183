import WDC from '../../api/wdc';

export const setCursor = async (entityRef, connectionName, cursor) => {
  return await WDC(entityRef, {
    Action: 'setCursor',
    EID: entityRef,
    connectionName,
    cursor,
  });
};

export const getCursor = async (entityRef, connectionName, defaultValue) => {
  const Rs = await WDC(entityRef, {
    Action: 'getCursor',
    EID: entityRef,
    connectionName,
    defaultValue,
  });

  return (Rs && Rs.Cursor) || defaultValue;
};
