import Metrics from './metrics/nav.config';
import Explorer from './explorer/nav.config';
import AuditLogs from './audit-logs/nav.config';
import PublicQueries from './queries/nav.config';

export default {
  id: 'data',
  icon: 'database',
  description: 'Data',
  moduleId: 'data',
  modules: [Metrics, Explorer, AuditLogs, PublicQueries],
};
