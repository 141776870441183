export default {
  id: 'organizations',
  moduleId: 'organizations',
  description: 'Organizations',
  modules: [
    {
      id: 'metrics',
      icon: 'chart-bar',
      description: 'Metrics',
      moduleId: 'metrics',
      feature: {
        id: 'FE-1487',
        description: 'CONNEX Developer (Beta)',
        url: 'https://jira.commandalkon.com/browse/FE-1487',
      },
    },
    {
      id: 'search',
      icon: 'search',
      description: 'Profile Search',
      moduleId: 'profile-search',
      feature: {
        id: 'FE-1487',
        description: 'CONNEX Developer (Beta)',
        url: 'https://jira.commandalkon.com/browse/FE-1487',
      },
    },
  ],
};
