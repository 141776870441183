export default {
  moduleDisplayLabel: 'Examples',
  customButton: 'Custom Button',
  defaultButton: 'Default Button',
  default: 'Default',
  'default.disabled': 'Default Disabled',
  'default.loading': ' Default Loading',
  primary: 'Primary',
  'primary.loading': 'Primary Loading',
  dashed: 'Dashed',
};
