import React from 'react';
import { Runtime } from './configure';
import { AmplifyAuth } from './AmplifyAuth';
import { Environment } from './contexts/environment';

export const AuthWrapper = ({ appId = 'connex', env, children }) => {
  const config = Runtime.initialize(appId, env);

  // TODO: turn StrictMode back on after antd addresses things.
  return (
    // <React.StrictMode>
    <Environment environment={config}>
      <AmplifyAuth>{config && children}</AmplifyAuth>
    </Environment>
    // </React.StrictMode>
  );
};
