export default {
  cancelled: 'Скасовано',
  cancelling: 'Скасування',
  'create-build': 'Створити збірку',
  failed: 'Помилка',
  'in-progress': 'В процесі',
  'max-results': 'Макс. Результати',
  pending: 'В очікуванні',
  status: 'Статус',
  success: 'Успіху',
  'timed-out': 'Час вийшов',
};
