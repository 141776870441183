import React from 'react';
import cx from 'classnames';
import styled from 'styled-components';
import { Checkbox as AntCheckbox } from 'antd';
import style from './style';
import propTypes from 'prop-types';

//TODO Checkbox.Group

export const Checkbox = ({ className, onChange, disabled, checked, label }) => {
  return (
    <AntCheckbox
      checked={checked}
      className={cx(className, 'cx-checkbox', { 'cx-checkbox-disabled': disabled })}
      onChange={onChange}
      disabled={disabled}
    >
      {label}
    </AntCheckbox>
  );
};

Checkbox.propTypes = {
  className: propTypes.string,
  onChange: propTypes.func,
  disabled: propTypes.bool,
  checked: propTypes.bool,
  label: propTypes.string,
};

export default styled(Checkbox)`
  ${style}
`;
