export default {
  id: 'integr8-dashboard',
  icon: 'chart-bar',
  description: 'Dashboard',
  moduleId: 'integrate-dashboard',
  feature: {
    id: 'FE-0000',
    description: 'Integr8 Dashboard',
    url: 'https://jira.commandalkon.com/browse/FE-0000',
  },
};
