import React from 'react';
import Module from './Module';
import { Route } from 'react-router-dom';
import Layout from '../../core/app-layout';
import { Header } from '../components/app-header';
import Footer from '../components/app-footer';
import InitialApp from './InitialApp';
import { APP_PATH, ERROR_PATH } from '../components/sidebar/constants';
import Error from '../views/Error';

const AppRoute = () => {
  return (
    <Layout header={<Header />} footer={<Footer />}>
      <Route path="/" exact component={InitialApp} />
      <Route path={`${APP_PATH}/:appId`} component={Module} />
      <Route path={`${ERROR_PATH}/:errorType`} component={Error} />
    </Layout>
  );
};

export default AppRoute;
