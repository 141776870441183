import Dashboard from './modules/dashboard/nav.config';
import AirTicket from './modules/air-ticket/nav.config';
import COMMANDassurance from './modules/command-assurance/nav.config';
import { MOUNT_POINT } from '../../core/constants';

export default {
  id: 'integr8',
  description: 'Integration',
  mountPoint: MOUNT_POINT.APP_SWITCHER,
  modules: [Dashboard, AirTicket, COMMANDassurance],
  hidden: true,
};
