import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';

const IconViewToggle = ({ className, toggle }) => {
  return (
    <div onClick={toggle} className={cn('cx-icon-view-toggle-container', className)}>
      <div className="cx-icon-view-toggle">
        <i className="fas fa-chevron-left"></i>
      </div>
    </div>
  );
};

export default styled(IconViewToggle)`
  ${style}
`;
