import React, { useCallback, useEffect, useState } from 'react';
import DetailFilter from './detail-filter';
import useEntityValues from '../../useEntityValues';
import { Input } from 'components';
import { Space } from 'antd';

export default ({ onChange, entityRef, filters }) => {
  const [options, setOptions] = useState({});
  const [custom, setCustom] = useState(null);

  const { productTypes, origins, projects, getFilterValues } = useEntityValues();

  useEffect(() => {
    filters && getFilterValues(entityRef, filters);
  }, [entityRef, filters, getFilterValues]);

  const optionChanged = useCallback((name, value) => {
    setOptions(s => ({ ...s, [name]: value }));
  }, []);

  useEffect(() => {
    onChange && onChange(options);
  }, [onChange, options]);

  const updateCustom = useCallback(() => {
    optionChanged('custom', custom);
  }, [optionChanged, custom]);

  return (
    <div style={{ padding: '10px' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <div>Product Types</div>
        <DetailFilter id="productType" onChange={optionChanged} options={productTypes} />

        <div>Plants</div>
        <DetailFilter id="originId" onChange={optionChanged} options={origins} />

        <div>Projects</div>
        <DetailFilter id="projectId" onChange={optionChanged} options={projects} />

        <div>Custom</div>
        <Input
          maxLength={256}
          addonBefore={<span>SQL</span>}
          onChange={e => setCustom(e.currentTarget.value)}
          addonAfter={<span onClick={updateCustom} className="fa fa-search" />}
        />
      </Space>
    </div>
  );
};
