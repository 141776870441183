import React from 'react';
import styled from 'styled-components';
import { ReactComponent as UnitedStates } from './UnitedStates.svg';
import { ReactComponent as Ukraine } from './Ukraine.svg';

const AutoScalingFlag = styled.div`
  svg {
    width: ${({ theme }) => `${25 * theme.fontSizeMultiplier}px`};
    transition: all 0.3s;
    display: flex;
  }
`;

const flags = {
  'en-US': UnitedStates,
  uk: Ukraine,
};

const Flag = ({ id }) => {
  const TargetFlag = flags[id];
  return (
    <AutoScalingFlag>
      <TargetFlag />
    </AutoScalingFlag>
  );
};

export default Flag;
