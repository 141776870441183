import SummaryWidget from './summary-widget';
import React from 'react';

export default ({ metrics }) => {
  const Values = metrics || {};
  const { TicketCount = 0, ProducedCount = 0, SharedCount = 0 } = Values;
  const Adoption = (TicketCount > 0 && Math.round((SharedCount / TicketCount) * 100)) || 0;

  return (
    <SummaryWidget
      loading={!metrics}
      icon="ticket-alt"
      total={TicketCount}
      headline={`Total Tickets (${Adoption}% Shared)`}
      leftTitle="Shipped"
      rightTitle="Received"
      leftButton={`${Math.round(ProducedCount / 100000) / 10}M`}
      rightButton={`${Math.round(SharedCount / 100000) / 10}M`}
    />
  );
};
