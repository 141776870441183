import { css } from 'styled-components';
import {
  MENU_ITEM_HEIGHT,
  SUB_MENU_ITEM_HEIGHT,
  ICON_VIEW_WIDTH,
  SIDEBAR_WIDTH_PX,
  MENU_ITEM_HIGHLIGHT_WIDTH,
  BASE_HEADER_HEIGHT,
  BASE_HEADER_LOGO_HEIGHT,
  BASE_FOOTER_HEIGHT,
} from '../../constants';

export default css`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0;

  :before {
    content: '';
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-image: url(//www.gstatic.com/mobilesdk/190424_mobilesdk/nav_nachos@2x.png);
    background-position: left 0 bottom 0;
    background-repeat: no-repeat;
    background-size: 256px 556px;
    opacity: var(--sidebarMenuBackgroundImageOpacity);
    z-index: -1;
  }

  &.drawer {
    .logo {
      .icon-logo {
        opacity: 0;
      }
    }
    .bottom {
      .cx-icon-view-toggle-container {
        display: none;
      }
    }
  }

  &.fixed {
    .bottom {
      border-top: 1px solid var(--sidebarDividerColor);
    }
  }

  &.fixed.fixed-full,
  &.drawer {
    .main {
      .menu-item {
        &.active {
          background-color: var(--sidebarActiveMenuItemBackgroundColor);
          .right-highlight {
            position: absolute;
            left: ${SIDEBAR_WIDTH_PX - MENU_ITEM_HIGHLIGHT_WIDTH}px;
            background-color: var(--sidebarActiveMenuItemHighlightColor);
            width: ${MENU_ITEM_HIGHLIGHT_WIDTH}px;
            height: ${SUB_MENU_ITEM_HEIGHT}px;
          }
        }
        &:not(.submenu-item) {
          &.active {
            .right-highlight {
              height: ${MENU_ITEM_HEIGHT}px;
            }
          }
        }
        &.submenu-item {
          &.active {
            .right-highlight {
              height: ${SUB_MENU_ITEM_HEIGHT}px;
            }
          }
        }
      }
    }
  }

  .menu-item {
    .right-highlight {
      transition: left 0.3s;
    }
  }

  &.fixed.fixed-icon {
    &:not(.drawer) {
      .main {
        .menu-item {
          &.active {
            background-color: var(--sidebarActiveMenuItemBackgroundColor);
            .right-highlight {
              position: absolute;
              left: ${ICON_VIEW_WIDTH - MENU_ITEM_HIGHLIGHT_WIDTH}px;
              width: ${MENU_ITEM_HIGHLIGHT_WIDTH}px;
              background-color: var(--sidebarActiveMenuItemHighlightColor);
            }
          }
          &:not(.submenu-item) {
            &.active {
              .right-highlight {
                height: ${MENU_ITEM_HEIGHT}px;
              }
            }
          }
          &.submenu-item {
            &.active {
              .right-highlight {
                height: ${SUB_MENU_ITEM_HEIGHT}px;
              }
            }
          }
        }
      }
    }
  }

  &.fixed.fixed-full {
    .logo {
      .full-logo {
        opacity: 1;
      }
      .icon-logo {
        opacity: 0;
        left: ${SIDEBAR_WIDTH_PX - 50}px;
      }
    }
    .main {
      .menu-item {
        .label {
          opacity: 1;
        }
      }
    }
    .bottom {
      .cx-icon-view-toggle {
        position: absolute;
        left: ${SIDEBAR_WIDTH_PX - 40}px;
        transition: transform 0.5s, left 0.3s;
      }
    }
  }

  &.fixed.fixed-icon {
    .logo {
      .full-logo {
        opacity: 0;
      }
      .icon-logo {
        opacity: 1;
        left: -5px;
      }
    }
    .main {
      .menu-item {
        .label {
          opacity: 0;
        }
      }
    }
    .bottom {
      .cx-icon-view-toggle {
        position: absolute;
        left: 20px;
        transition: transform 0.5s, left 0.3s;
        transform: rotate(180deg);
      }
    }
  }

  .logo {
    position: relative;
    background-color: var(--sidebarLogoBackgroundColor);
    width: ${SIDEBAR_WIDTH_PX}px;
    height: ${({ theme }) => `${BASE_HEADER_HEIGHT * theme.fontSizeMultiplier}px`};
    display: flex;
    align-items: center;
    margin-left: 15px;
    transition: height 0.3s;

    .full-logo {
      transition: opacity 0.3s;
      svg {
        height: ${({ theme }) => `${BASE_HEADER_LOGO_HEIGHT * theme.fontSizeMultiplier}px`};
        transition: height 0.3s;
      }
      .st0 {
        fill: var(--logoColor);
        transition: fill 4s cubic-bezier(0, 0.54, 0.42, 0.77) !important;
      }
      .st1 {
        fill: #e04e38;
      }
      .st2 {
        display: none;
      }
      .st3 {
        display: inline;
      }
      .st4 {
        fill: #ed5338;
      }
    }

    .icon-logo {
      transition: left 0.3s, opacity 0.3s;
      img {
        height: ${({ theme }) => `${BASE_HEADER_LOGO_HEIGHT * theme.fontSizeMultiplier}px`};
      }
      position: absolute;
      //top: 13px;
    }
  }

  .main {
    display: flex;
    flex-direction: column;

    flex: 1;

    overflow-x: hidden;
    overflow-y: auto;

    border-top: 1px solid var(--sidebarDividerColor);

    .toggle-icon-view-section {
      cursor: pointer;
      flex: 1;
    }

    .menu-item {
      display: flex;
      width: ${SIDEBAR_WIDTH_PX}px;
      cursor: pointer;
      align-items: center;
      position: relative;
      .ant-typography {
        color: var(--sidebarBaseTextColor);
      }

      &.active {
        background-color: var(--sidebarActiveMenuItemBackgroundColor);
      }

      &:hover {
        background-color: var(--sidebarClosedMenuItemHover);
      }

      &:not(.submenu-item) {
        height: ${MENU_ITEM_HEIGHT}px;
        align-items: center;
      }

      .icon {
        margin: 0 15px;
        width: 20px;
        height: 20px;
        background-color: transparent;
        color: var(--sidebarMenuItemIconColor);
      }
      .label {
        transition: opacity 0.3s;
      }
    }
  }
  .bottom {
    display: flex;
    flex-direction: column;
    height: ${({ theme }) => `${BASE_FOOTER_HEIGHT * theme.fontSizeMultiplier}px`};
    transition: all 0.3s;
  }
`;
