import React from 'react';
import { Collapse } from 'components';
import styled from 'styled-components';
import style from './style';
import cn from 'classnames';

const FileGroup = ({ className, group, files }) => {
  const total = files.length;

  return (
    <Collapse className={cn(className, 'change-group')}>
      <Collapse.Panel header={`${group} Differences (${total})`} key={group}>
        <div className="list">
          {files.map((file, index) => {
            return (
              <div key={index} className={`needs-review-${file.needsCodeReview === true}`}>
                {file.changeType} - {file.path}
              </div>
            );
          })}
        </div>
      </Collapse.Panel>
    </Collapse>
  );
};

const FileList = ({ className, analysis }) => {
  const {
    backend = [],
    frontend = [],
    scripts = [],
    services = [],
    tests = [],
    tools = [],
    other = [],
  } = analysis;
  const groups = [
    { group: 'Back End', files: backend },
    { group: 'Front End', files: frontend },
    { group: 'UI Test', files: tests },
    { group: 'Script', files: scripts },
    { group: 'Tool', files: tools },
    { group: 'Service', files: services },
    { group: 'Other', files: other },
  ].filter(g => g.files.length > 0);

  return (
    <div className={cn(className, 'file-list')}>
      {groups.map((group, index) => {
        return <FileGroup key={index} {...group} />;
      })}
    </div>
  );
};

export default styled(FileList)`
  ${style}
`;
