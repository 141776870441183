import common from '../common';
import columnHeaders from './columnHeaders';
import errors from './errors';

export default {
  ...common,
  clearAll: 'Очистити все ',
  notImplemented: 'Модуль не реалізований.',
  appNotPermitted: 'Дозвіл відмовлено.',
  notFound: 'Модуль не знайдено.',
  save: 'Зберегти',
  cancel: 'Скасувати',
  switchTheme: 'Переключити тему',
  poweredBy: 'Працює на',
  small: 'Маленький',
  medium: 'Середній',
  large: 'Великий',
  notProvisioned: 'Жодних програм не надано.',
  validationRequired: "Це поле є обов'язковим",
  notifications: 'Повідомлення',

  error: 'Помилка',
  signOut: 'Вийти з аккаунта',

  version: 'Версія {version}',

  dynamicFormSubmitButton: 'Подати',
  dynamicFormCancelButton: 'Скасувати',
  dynamicFormResetButton: 'Скинути',

  emptyText: 'Немає даних',

  csv: 'CSV',
  json: 'JSON',

  selectLoadingPlaceholder: 'отримання...',
  selectPlaceholder: 'Виберіть...',

  columnHeaders,
  errors,
};
