import React from 'react';
import { Card } from 'components';
import styled from 'styled-components';
import style from './style';
import cn from 'classnames';
import moment from 'moment';

const PullRequestSummary = ({ pullRequest, className }) => {
  const {
    createDate,
    id,
    title,
    status,
    author,
    approvals,
    sourceBranch,
    targetBranch,
  } = pullRequest;
  const href = `https://us-west-2.console.aws.amazon.com/codesuite/codecommit/repositories/connex/pull-requests/${id}/changes?region=us-west-2`;

  return (
    <Card className={cn('pull-request', className)} title="pull-requests.pullRequestDetails">
      <table>
        <tbody>
          <tr className="header">
            <td>Title</td>
            <td>Author</td>
            <td>Status</td>
            <td>Created</td>
          </tr>
          <tr>
            <td>
              <a target="_blank" href={href}>
                {id} - {title}
              </a>
            </td>
            <td>{author}</td>
            <td>{status}</td>
            <td>{moment(createDate).format('MM/DD/YY HH:MM A')}</td>
          </tr>
          <tr className="header">
            <td>Source Branch</td>
            <td>Target Branch</td>
            <td></td>
            <td>Approved By</td>
          </tr>
          <tr>
            <td>{sourceBranch}</td>
            <td>{targetBranch}</td>
            <td></td>
            <td>
              <td>
                {approvals &&
                  approvals.map((a, index) => {
                    return <div key={index}>{a.user}</div>;
                  })}
              </td>
            </td>
          </tr>
        </tbody>
      </table>
    </Card>
  );
};

export default styled(PullRequestSummary)`
  ${style}
`;
