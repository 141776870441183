import React, { useContext, useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import { find, values } from 'lodash';
import { consolidateMessages } from './util/consolidateMessages';
import { messages as defaultGlobalMessages } from './messages-global';
import { moduleDefinitions } from '../core/modules';
import { appDefinitions } from '../core/apps';
import { themeDefinitions } from '../themes';
import { cache } from '../util';

export const SUPPORTED_LANGUAGES = [
  { id: 'en-US', label: 'languages.english-us' },
  { id: 'uk', label: 'languages.ukrainian' },
];

export const IntlContext = React.createContext();

export const useIntlContext = () => {
  const context = useContext(IntlContext);
  if (!context) {
    throw new Error('useIntlContext cannot be called outside of the IntlContext context provider');
  }
  return context;
};

const moduleMessages = values(moduleDefinitions).reduce((acc, module) => {
  acc[module.id] = module.i18n;
  return acc;
}, {});

const appMessages = values(appDefinitions).reduce((acc, app) => {
  acc[app.id] = app.i18n;
  return acc;
}, {});

const themeMessages = values(themeDefinitions).reduce((acc, theme) => {
  acc[theme.id] = theme.i18n;
  return acc;
}, {});

const cachedLanguageId = cache.getItem('cx.language');

const supportedCachedLanguageId = () => {
  const known =
    cachedLanguageId &&
    find(SUPPORTED_LANGUAGES, l => l.id.toLowerCase() === cachedLanguageId.toLowerCase());

  return known ? known.id : null;
};

const browserLanguageId =
  (navigator.languages && navigator.languages[0]) || navigator.language || 'en-US';

const supportedBrowserLanguageId = () => {
  const known =
    find(SUPPORTED_LANGUAGES, l => l.id.toLowerCase() === browserLanguageId.toLowerCase()) ||
    'en-US';
  return known ? known.id : null;
};

export const Internationalization = ({ children, globalMessages = defaultGlobalMessages }) => {
  const [userLocale, setUserLocale] = useState(
    supportedCachedLanguageId() || supportedBrowserLanguageId()
  );

  const [messages, setMessages] = useState(
    consolidateMessages({ globalMessages, appMessages, moduleMessages, themeMessages })[
      supportedBrowserLanguageId()
    ]
  );

  useEffect(() => {
    if (userLocale) {
      cache.setItem('cx.language', userLocale);
    }
  }, [userLocale]);

  useEffect(() => {
    setMessages(
      consolidateMessages({ globalMessages, appMessages, moduleMessages, themeMessages })[
        userLocale
      ]
    );
  }, [userLocale, globalMessages]);

  return (
    <IntlContext.Provider value={{ userLocale: userLocale || 'en-US', setUserLocale }}>
      <IntlProvider locale={userLocale || 'en-US'} messages={messages}>
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};
