import React, { useCallback } from 'react';
import { get, find } from 'lodash';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useAppsConfigContext } from '../../../app-config/AppsConfigContext';
import AutoOpenSubMenu from '../auto-open-submenu';
import { Tooltip } from '../tooltip/Tooltip';
import MenuItem from '../menu-item';
import { I18nContextProvider } from '../i18n-context';
import RedirectToLastVisitedModule from '../RedirectToLastVisistedModule';
import { useUserContext } from '../../../../contexts/user/UserContextProvider';

const DynamicMenuItems = ({ onItemSelection }) => {
  const history = useHistory();
  const { appsConfig } = useAppsConfigContext();
  const { hasAppPermission } = useUserContext();

  const match = useRouteMatch('/app/:appId');
  const appId = get(match, 'params.appId');

  const navigateToModule = useCallback(
    path => {
      history.push(path);
    },
    [history]
  );

  const handleItemSelection = useCallback(
    path => {
      onItemSelection(() => navigateToModule(path));
    },
    [onItemSelection, navigateToModule]
  );

  const app = find(
    appsConfig.filter(app => hasAppPermission(app)),
    app => app.id === appId
  );

  if (!app) return null;

  return (
    <RedirectToLastVisitedModule>
      {app.modules.map((module, key) => {
        if (module.modules) {
          const subMenuItems = module.modules
            .filter(m => m.type === 'local' || !m.type)
            .filter(m => !m.hidden)
            .map(subModule => ({
              moduleId: subModule.moduleId,
              description: subModule.description,
              icon: subModule.icon,
              path: subModule.moduleConfig.path,
            }));

          return (
            <I18nContextProvider key={key} prefix={app.id}>
              <AutoOpenSubMenu
                key={module.moduleId}
                titleKey={module.moduleId}
                items={subMenuItems}
                handleItemSelection={handleItemSelection}
              />
            </I18nContextProvider>
          );
        }
        return module.hidden ? null : (
          <I18nContextProvider key={key} prefix={app.id}>
            <Tooltip key={module.id} text={module.description}>
              <MenuItem
                handleItemSelection={handleItemSelection}
                module={{ ...module, path: module.moduleConfig.path }}
              />
            </Tooltip>
          </I18nContextProvider>
        );
      })}
    </RedirectToLastVisitedModule>
  );
};

export default DynamicMenuItems;
