import React, { useCallback, useState } from 'react';
import FlexColumn from 'components/layout-flex-column';
import cn from 'classnames';
import styled from 'styled-components';
import { Space } from 'antd';
import style from './style';
import { Button, Select } from 'components';
import { useBuild } from '../useBuild';

const CreateBuild = ({ className, onCreated }) => {
  const [environment, setEnvironment] = useState('develop');
  const [buildType, setBuildType] = useState('full');

  const { createBuild, creating } = useBuild();

  const environmentList = [
    { id: 'develop', name: 'Develop' },
    { id: 'stage', name: 'Stage' },
  ];

  const buildTypeList = [
    { id: 'full', name: 'Full' },
    { id: 'incremental', name: 'Incremental' },
  ];

  const onCreate = () => {
    createBuild({
      environment,
      buildType,
    }).then(() => {
      onCreated();
    });
  };

  return (
    <FlexColumn className={cn('create-build', className)}>
      <Space>
        <Select
          id="branch-select"
          placeholder="pull-requests.select"
          defaultValue={environment}
          valuePath="id"
          displayPath="name"
          className="select-filter"
          onChange={setEnvironment}
          options={environmentList}
        />

        <Select
          id="build-type-select"
          placeholder="pull-requests.select"
          defaultValue={buildType}
          valuePath="id"
          displayPath="name"
          className="select-filter"
          onChange={setBuildType}
          options={buildTypeList}
        />

        <Button
          id="create-build-btn"
          className="create-btn"
          type="primary"
          loading={creating}
          label="builds.create-build"
          onClick={onCreate}
        />
      </Space>
    </FlexColumn>
  );
};

export default styled(CreateBuild)`
  ${style}
`;
