import { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { find } from 'lodash';
import { useAppsConfigContext } from '../../components/app-config/AppsConfigContext';

const findModule = (app, moduleId) => {
  let module = null;

  const searchModules = modules => {
    modules.forEach(m => {
      if (m.modules) {
        searchModules(m.modules);
      } else {
        if (m.moduleConfig.moduleId === moduleId) {
          module = m;
        }
      }
    });
  };

  searchModules(app.modules);

  return module;
};

export const useHandoff = () => {
  const { appsConfig } = useAppsConfigContext();
  const history = useHistory();
  const { pathname } = useLocation();

  const to = useCallback(
    ({ appId, moduleId, state }) => {
      const targetApp = find(appsConfig, app => app.id === appId);

      if (!targetApp) {
        throw new Error(`appId "${appId}" not found.`);
      }

      const targetModule = findModule(targetApp, moduleId);

      if (!targetModule) {
        throw new Error(`moduleId "${moduleId}" not found in app "${appId}".`);
      }

      history.push(targetModule.moduleConfig.path, {
        state,
        returnToPath: pathname,
      });
    },
    [appsConfig, history, pathname]
  );

  return { to };
};
