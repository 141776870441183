export default {
  appName: 'Адміністратор платформи',
  'pa-dashboard': 'Панель приладів',
  users: 'Користувачі',
  metrics: 'Метрики',
  'profile-search': 'Пошук профілю',
  organizations: 'Організації',
  entities: 'Entities',
  'manage-data': 'Manage Data',
};
