import React, { useCallback, useState } from 'react';
import Drawer from 'components/drawer';
import Button from 'components/button';
import AdditionalFilters from './additional-filters';

export default ({ entityRef, dateFilters, open, onClose, onApply }) => {
  const [fieldFilters, setFieldFilters] = useState(null);

  const selectedOptionsChanged = useCallback(options => {
    setFieldFilters({ ...options });
  }, []);

  return (
    <Drawer
      width="50%"
      title={<span>Filters</span>}
      visible={open}
      onClose={() => onClose && onClose(false)}
    >
      <AdditionalFilters
        onChange={selectedOptionsChanged}
        entityRef={entityRef}
        filters={dateFilters}
      />
      <Button
        label="pa-dashboard.apply"
        style={{ float: 'right', marginRight: '10px', marginTop: '10px' }}
        type="primary"
        onClick={() => onApply && onApply(fieldFilters)}
      />
    </Drawer>
  );
};
