import { css } from 'styled-components';
import { BASE_FOOTER_HEIGHT } from '../sidebar/constants';

export default css`
  background-color: var(--footerBackgroundColor);
  color: var(--footerTextColor);
  border-top: var(--footerBorderTop);
  transition: all 0.3s;
  height: ${({ theme }) => `${BASE_FOOTER_HEIGHT * theme.fontSizeMultiplier}px`};

  //margin-top: 5px;

  display: flex;
  align-items: center;

  padding: 5px 20px;

  .build-info {
    flex: 1;
    text-align: right;
    .ant-typography {
      color: var(--baseTextColor);
    }
  }
  .api-status {
  }
  > :not(:last-child) {
    margin-right: 30px;
  }

  &.small {
    .label {
      display: none;
    }
    > :not(:last-child) {
      margin-right: 10px;
    }
  }
`;
