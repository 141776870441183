import { css } from 'styled-components';

export default css`
  // margin: 20px;
  text-align: center;
  padding: 10px;

  // border: var(--custom-container-border);

  .isVisible {
    visibility: visible;
  }

  &:not(.isVisible) {
    visibility: hidden;
  }
`;
