import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { ViewHeader } from '../../../../../../components';
import { useConsole } from '../../../useConsole';
import DropdownMenu from '../../../../../../sdk_deprecated/ui/navigation/dropdown-menu';
import styled from 'styled-components';
import style from './style';
import Checkbox from 'components/checkbox';
import FeatureView from './feature-view';

const Styled = styled.div`
  ${style}
`;

export const View = ({ className }) => {
  const { getProductAreas, productAreas, getIdeas, ideas, factors, getRelatedFeature, feature } =
    useConsole();
  const [areas, setAreas] = useState([]);
  const [productArea, setProductArea] = useState(null);
  const [showLower, setShowLower] = useState(false);
  const [featureId, setFeatureId] = useState(null);

  useEffect(() => {
    getProductAreas();
  }, []);

  useEffect(() => {
    productAreas?.map && setAreas(productAreas.map(a => ({ ...a, displayLabel: a.name })));
  }, [productAreas]);

  useEffect(() => {
    productArea && getIdeas(productArea?.id);
  }, [productArea]);

  useEffect(() => {
    featureId && getRelatedFeature(featureId);
  }, [featureId]);

  useEffect(() => {
    feature && console.log(feature);
  }, [feature]);

  return (
    <Styled className={cn('features-view', className)}>
      <ViewHeader>
        <DropdownMenu
          id="productArea"
          className={cn('product-area', className)}
          title={(productArea && `RANKED ${productArea?.name} FEATURES`) ?? 'Select Product Area'}
          items={areas}
          onSelection={setProductArea}
        />
        <div className="options">
          {productArea && (
            <Checkbox
              label="Show Lower Priority Items"
              checked={showLower}
              onChange={() => setShowLower(!showLower)}
            />
          )}
        </div>
        {productArea && (
          <table className="ideas">
            <tbody>
              <tr>
                <td>Rank</td>
                <td>Feature</td>
                <td>{factors?.[0]?.title}</td>
                <td>{factors?.[1]?.title}</td>
                <td>{factors?.[2]?.title}</td>
                <td>{factors?.[3]?.title}</td>
                <td>WSJF Score</td>
              </tr>

              {ideas?.map((idea, index) => {
                const { rank, id, title, score, factors, featureId } = idea;
                return (
                  <tr
                    key={id}
                    onClick={() => setFeatureId(featureId)}
                    className={cn(
                      index < 10 ? 'idea' : `bottom-idea-${showLower ? 'visible' : 'hidden'}`,
                      className
                    )}
                  >
                    <td>{rank}</td>
                    <td>{title}</td>
                    {Object.keys(factors).map(factor => (
                      <td className="factor" key={`${id}-${factor}`}>
                        {factors[factor]}
                      </td>
                    ))}
                    <td>{score}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </ViewHeader>
      {featureId && (
        <FeatureView
          title={`FEATURE ${featureId}`}
          width={800}
          feature={feature}
          onClose={() => setFeatureId(null)}
          drawerStyle={{ display: 'flex' }}
          style={{ display: 'flex' }}
          destroyOnClose
        />
      )}
    </Styled>
  );
};

export default View;
