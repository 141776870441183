import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import NotificationLog from '../notification-log';
import style from './notificationLogIconStyle';
import { useThemeContext } from '../../theme-provider';
import { useNotificationStateContext } from '../../contexts/notifications';
import { useDrawerContext } from '../../contexts/right-drawer';

const ICONS = {
  disabled: <i className="far fa-bell" />,
  enabled: <i className="far fa-bell" />,
};

const STATUS = {
  ENABLED: 'enabled',
  DISABLED: 'disabled',
};

export const NotificationLogIcon = ({ className }) => {
  const { openRightDrawer, closeRightDrawer } = useDrawerContext();
  const { notificationLog } = useNotificationStateContext();
  const [status, setStatus] = useState(STATUS.DISABLED);
  const { fontSizeMultiplier } = useThemeContext();

  const handleBellClick = useCallback(() => {
    if (status === STATUS.ENABLED) {
      openRightDrawer({
        content: <NotificationLog onEmpty={closeRightDrawer} />,
        width: 400 * fontSizeMultiplier,
        title: <NotificationLog.Title />,
      });
    }
  }, [openRightDrawer, closeRightDrawer, status, fontSizeMultiplier]);

  useEffect(() => {
    setStatus(notificationLog.length ? STATUS.ENABLED : STATUS.DISABLED);
  }, [notificationLog]);

  return (
    <div className={cn('notification-icon', status, className)} onClick={handleBellClick}>
      {ICONS[status]}
    </div>
  );
};

export default styled(NotificationLogIcon)`
  ${style}
`;
