import accounting from './accounting/nav.config';
import developer from './developer/nav.config';
import integr8 from './integr8/nav.config';
import platformAdmin from './platform-admin/nav.config';
import settings from './settings/nav.config';
import support from './support/nav.config';
import sandbox from './sandbox/nav.config';
import sdlc from './sdlc/nav.config';

export default [platformAdmin, support, sdlc, developer, integr8, settings, sandbox];
