import getSchema from './getSchema';
import getData from './getData';
import { encrypt } from './secrets';
import WDC from '../../api/wdc';

const tableau = window.tableau;

const init = initCallback => {
  tableau.authType = tableau.authTypeEnum.none;

  initCallback();
};

export const connect = async (
  entityRef,
  username,
  password,
  userAlias,
  dataSet,
  params = [],
  iterator = null,
  connectionName = 'CONNEX WDC',
  privateKey
) => {
  tableau.log(`Configuring ${connectionName}`);

  tableau.connectionName = connectionName;
  tableau.username = username;
  tableau.usernameAlias = userAlias;
  tableau.password = null;

  const { Schemas: schemas } = await WDC(entityRef, {
    Action: 'getSchemas',
    EID: entityRef,
    ID: dataSet,
  });

  tableau.connectionData = JSON.stringify({
    connectionName,
    entityRef,
    dataSet,
    schemas,
    username,
    params,
    iterator,
    apiKey: encrypt(password, entityRef),
    privateKey: encrypt(privateKey, entityRef),
  });

  tableau.submit();
};

export const wdcInit = () => {
  const entityConnector = tableau.makeConnector();

  entityConnector.getSchema = getSchema;

  entityConnector.getData = getData;

  entityConnector.init = init;

  tableau.registerConnector(entityConnector);
};
