import React, { useCallback } from 'react';
import { Space } from 'antd';
import cn from 'classnames';
import {
  Button,
  Collapse,
  DatePicker,
  Exporter,
  FormItemWrapper,
  InputNumber,
  Select,
  Text,
  TimePicker,
} from 'components';
import { useFilterFormLists } from './useFilterFormLists';

const { Panel } = Collapse;

const AuditLogHeader = ({ className, data, loading, executeQuery, filters }) => {
  const { filterForm, setFilterForm, resetFilterForm } = filters;
  const { formLists, formListsBusy } = useFilterFormLists();

  const handleSearchClick = useCallback(() => {
    executeQuery(filterForm);
  }, [filterForm, executeQuery]);

  return (
    <div className={cn('audit-log-header', className)} data-testid="auditLogHeader">
      <Collapse defaultActiveKey={['1']} border={false}>
        <Panel header="Filters" key="1">
          <div className="filter-container">
            <div className="options">
              <FormItemWrapper htmlFor="select-entity" label="audit-logs.entity">
                <Select
                  id="select-entity"
                  value={filterForm.entity}
                  options={formLists.entities.list}
                  valuePath="crn"
                  displayPath="name"
                  className="select-filter"
                  onChange={value => setFilterForm(value, 'entity')}
                  loading={formLists.entities.busy}
                />
              </FormItemWrapper>
              <FormItemWrapper htmlFor="select-status" label="audit-logs.status">
                <Select
                  id="select-status"
                  value={filterForm.status}
                  className="select-filter"
                  options={formLists.statuses.list}
                  onChange={value => setFilterForm(value, 'status')}
                  valuePath="id"
                  displayPath="value"
                  loading={formLists.statuses.busy}
                />
              </FormItemWrapper>
              <FormItemWrapper htmlFor="select-statement-name" label="audit-logs.statement-name">
                <Select
                  id="select-statement-name"
                  value={filterForm.statementName}
                  className="select-filter"
                  options={formLists.statements.list}
                  onChange={value => setFilterForm(value, 'statementName')}
                  valuePath="id"
                  displayPath="name"
                  loading={formLists.statements.busy}
                />
              </FormItemWrapper>
              <FormItemWrapper htmlFor="select-user-name" label="audit-logs.user-name">
                <Select
                  id="select-user-name"
                  value={filterForm.userName}
                  className="select-filter"
                  options={formLists.users.list}
                  onChange={value => setFilterForm(value, 'userName')}
                  valuePath="UserName"
                  displayPath="Label"
                  loading={formLists.users.busy}
                />
              </FormItemWrapper>
              <FormItemWrapper htmlFor="select-container-name" label="audit-logs.container-name">
                <Select
                  id="select-container-name"
                  value={filterForm.containerName}
                  className="select-filter"
                  options={formLists.containers.list}
                  onChange={value => setFilterForm(value, 'containerName')}
                  valuePath="ContainerName"
                  displayPath="Label"
                  loading={formLists.containers.busy}
                />
              </FormItemWrapper>
              <FormItemWrapper htmlFor="select-limit" label="audit-logs.limit-search">
                <Select
                  id="select-limit"
                  value={filterForm.limit}
                  onChange={value => setFilterForm(value, 'limit')}
                  options={formLists.limits.list}
                  valuePath="id"
                  displayPath="value"
                  loading={formLists.limits.busy}
                />
              </FormItemWrapper>
              <FormItemWrapper
                htmlFor="select-duration-minimum"
                label="audit-logs.duration-minimum-search"
              >
                <InputNumber
                  id="select-duration-minimum"
                  min={0}
                  value={filterForm.duration}
                  onChange={value => setFilterForm(value, 'duration')}
                />
              </FormItemWrapper>
              <FormItemWrapper htmlFor="select-item-minimum" label="audit-logs.item-minimum-search">
                <InputNumber
                  id="select-item-minimum"
                  value={filterForm.itemCount}
                  min={0}
                  onChange={value => setFilterForm(value, 'itemCount')}
                />
              </FormItemWrapper>
            </div>
            <div className="dates">
              <FormItemWrapper htmlFor="input-begin-time" label="audit-logs.begin-time">
                <Space>
                  <DatePicker
                    id="input-begin-time"
                    value={filterForm.beginDate}
                    onChange={date => setFilterForm(date, 'beginDate')}
                    format="MM-DD-YYYY"
                    allowClear={false}
                  />
                  <TimePicker
                    value={filterForm.beginTime}
                    onChange={value => setFilterForm(value, 'beginTime')}
                    format="h:mm:ss a"
                    allowClear={false}
                  />
                </Space>
              </FormItemWrapper>
              <FormItemWrapper htmlFor="input-end-time" label="audit-logs.end-time">
                <Space>
                  <DatePicker
                    id="input-end-time"
                    value={filterForm.endDate}
                    onChange={value => setFilterForm(value, 'endDate')}
                    format="MM-DD-YYYY"
                    allowClear={false}
                  />
                  <TimePicker
                    value={filterForm.endTime}
                    onChange={value => setFilterForm(value, 'endTime')}
                    format="h:mm:ss a"
                    allowClear={false}
                  />
                </Space>
              </FormItemWrapper>
            </div>
            <div className="buttons">
              <Space>
                <Button
                  label={<Text text="audit-logs.search" />}
                  icon={<i className="fas fa-play" />}
                  onClick={handleSearchClick}
                  loading={loading}
                  data-testid="searchButton"
                />
                <Button
                  label={<Text text="audit-logs.clear-filters" />}
                  icon={<i className="fa fa-search-minus" />}
                  onClick={resetFilterForm}
                  loading={formListsBusy}
                />
              </Space>
            </div>
          </div>
        </Panel>
      </Collapse>
      <div className="table-utils">
        <Space className={'export-buttons'}>
          <Exporter data={data} fileName="query-logs" type="CSV" />
          <Exporter data={data} fileName="query-logs" type="JSON" />
        </Space>
      </div>
    </div>
  );
};

export default AuditLogHeader;
