import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Table as AntTable } from 'antd';
import { SizeMe } from 'react-sizeme';
import propTypes from 'prop-types';
import style from './style';

const rowClassname = (record, index) => cn({ alt: index % 2 === 0 });

const SizedTable = ({ className, dataSource, columns, ...props }) => {
  return (
    <SizeMe monitorHeight monitorWidth refreshRate={100}>
      {({ size }) => {
        return (
          <div className={cn('cx-base-table', className)} style={{ flex: '1' }}>
            <div style={{ position: 'relative', height: '100%' }}>
              <div style={{ position: 'absolute', width: '100%', height: size.height || 0 }}>
                <AntTable
                  rowClassName={rowClassname}
                  columns={columns}
                  dataSource={dataSource}
                  pagination={{ pageSize: props.pageSize || 50 }}
                  scroll={{ y: (size.height || 80) - 80 }}
                  {...props}
                />
              </div>
            </div>
          </div>
        );
      }}
    </SizeMe>
  );
};

SizedTable.propTypes = {
  className: propTypes.string,
  dataSource: propTypes.arrayOf(propTypes.object),
  columns: propTypes.arrayOf(propTypes.object),
  pageSize: propTypes.number,
};

export default styled(SizedTable)`
  ${style}
`;
