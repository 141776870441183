import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { get } from 'lodash';
import { Select as AntSelect } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import propTypes from 'prop-types';
import style from './style';
import Dropdown from './Dropdown';
import useIntlProps from '../../components/i18n/useIntlProps';

const Select = ({
  id,
  name,
  mode,
  valuePath = 'id',
  displayPath = 'description',
  options = [],
  disabled,
  className,
  defaultValue,
  onChange,
  placeholder,
  showArrow,
  style,
  value,
  allowClear,
  error,
  touched,
  onFocus,
  innerRef,
  onBlur,
  showSearch= true,
  loading,
  loadingPlaceholder = 'selectLoadingPlaceholder',
}) => {
  const { Option } = AntSelect;

  AntSelect.displayName = 'AntSelect';

  const [translatedPlaceholder, translatedLoadingPlaceholder] = useIntlProps(
    placeholder,
    loadingPlaceholder
  );

  return loading ? (
    <div className={cn(className, 'cx-loading-field')}>
      <div className="loading-spinner">
        <LoadingOutlined />
      </div>
      <div className="loading-placeholder">{translatedLoadingPlaceholder}</div>
    </div>
  ) : (
    <>
      <AntSelect
        id={id}
        name={name}
        mode={mode}
        disabled={disabled}
        showSearch={showSearch}
        filterOption={(input, option) => {
          if (option.children && typeof option.children === 'string') {
            return (
              option.children
                .toLowerCase()
                .replace(/\s/g, '')
                .indexOf(input.toLowerCase().replace(/\s/g, '')) >= 0
            );
          }
          return false;
        }}
        defaultValue={defaultValue}
        value={value}
        className={cn(className, 'cx-select', { 'cx-select-disabled': disabled })}
        size="medium"
        labelInValue={false}
        onChange={onChange}
        placeholder={translatedPlaceholder}
        style={style || { minWidth: '200px' }}
        allowClear={allowClear}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={innerRef}
        showArrow={showArrow}
        dropdownRender={menu => <Dropdown>{menu}</Dropdown>}
        dropdownStyle={{ backgroundColor: 'var(--select-dropdown-background-color)' }}
      >
        {options.map(option => {
          return (
            <Option
              key={`${option[valuePath]}__${option[displayPath]}`}
              className="cx-option"
              value={get(option, valuePath)}
            >
              {get(option, displayPath) || get(option, valuePath)}
            </Option>
          );
        })}
        )}
      </AntSelect>
      {error && touched && <div className="validation-error">{error}</div>}
    </>
  );
};
Select.Option = AntSelect.Option;

Select.propTypes = {
  id: propTypes.string,
  name: propTypes.string,
  mode: propTypes.string,
  valuePath: propTypes.string,
  displayPath: propTypes.string,
  options: propTypes.array,
  disabled: propTypes.bool,
  className: propTypes.string,
  defaultValue: propTypes.oneOfType([propTypes.string, propTypes.number]),
  onChange: propTypes.func,
  placeholder: propTypes.string,
  showArrow: propTypes.bool,
  style: propTypes.object,
  value: propTypes.oneOfType([propTypes.string, propTypes.number]),
  allowClear: propTypes.bool,
  error: propTypes.string,
  touched: propTypes.bool,
  onFocus: propTypes.func,
  innerRef: propTypes.string,
  onBlur: propTypes.func,
  loading: propTypes.bool,
  loadingPlaceholder: propTypes.string,
};

Select.defaultProps = {
  valuePath: 'id',
  displayPath: 'description',
  options: [],
  loadingPlaceholder: 'selectLoadingPlaceholder',
};

export default styled(Select)`
  ${style}
`;
