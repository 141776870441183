import Dashboard from './index';
import i18n from './i18n';

export default {
  component: Dashboard,
  i18n,
  id: 'developer-dashboard',
  moduleId: 'developer-dashboard',
  permissions: {},
  hidden: true,
};
