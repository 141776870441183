export default () => {
  return [
    {
      title: 'Destination',
      dataIndex: 'target_branch',
      key: 'target_branch',
      visible: true,
      hideFilter: true,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      visible: true,
      hideFilter: true,
    },
    {
      title: 'Author',
      dataIndex: 'author',
      key: 'author',
      visible: true,
      hideFilter: true,
    },
    {
      title: 'Source',
      dataIndex: 'source_branch',
      key: 'source_branch',
      visible: true,
      hideFilter: true,
    },
    {
      title: 'Approvals',
      dataIndex: 'approvalCount',
      key: 'approvalCount',
      visible: true,
      hideFilter: true,
    },
    {
      title: 'Last Activity',
      dataIndex: 'lastActivityDate',
      key: 'lastActivityDate',
      hideFilter: true,
      visible: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      visible: false,
    },
  ]
    .filter(c => c.visible === true)
    .map(c => {
      return {
        ...c,
        hideFilter: true,
        hideSort: true,
      };
    });
};
