import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import style from './style';
import { Drawer } from 'components/index';
import moment from 'moment';

const Styled = styled.div`
  ${style}
`;

const extractFeatureId = f => {
  if (f?.toUpperCase().includes('CX') || f?.toUpperCase().includes('FE')) {
    return 'FE';
  }
};

export const FeatureView = ({ feature, onClose }) => {
  const [featureId, setFeatureId] = useState(null);

  useEffect(() => {
    if (feature?.description) {
      setFeatureId(extractFeatureId(feature.description));
    }
  }, [feature]);

  return (
    <Drawer
      title={`${feature?.key ?? ''}: ${feature?.summary ?? ''}`}
      width={800}
      onClose={onClose}
      visible={feature}
      drawerStyle={{ display: 'flex' }}
      style={{ display: 'flex' }}
      destroyOnClose
    >
      <Styled>
        <div>
          <h4>
            <span>Created By {feature?.creator?.name}</span>
            <span>On {moment(feature?.created)?.format('MM/DD/YYYY')}</span>
          </h4>
          {feature?.key && (
            <h4>
              <a target={'_blank'} href={`https://jira.commandalkon.com/browse/${feature.key}`}>
                View in Jira <i className="fa fa-jira" />{' '}
              </a>
            </h4>
          )}
          <h4>Description</h4>
          <p>{feature?.description ?? 'Jira Description Unavailable'}</p>
        </div>
      </Styled>
    </Drawer>
  );
};

export default FeatureView;
