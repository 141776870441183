import React, { useMemo } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Badge as AntBadge } from 'antd';
import { useIntl } from 'react-intl';
import style from './style';

const Badge = ({ className, 'data-testid': dataTestId, id, color, count, style, ...props }) => {
  const { formatMessage } = useIntl();

  const intlCount = useMemo(() => {
    if (count && typeof count === 'string') return formatMessage({ id: count });
    return count;
  }, [formatMessage, count]);

  return (
    <AntBadge
      id={id}
      className={cn('cx-badge', className)}
      data-testid={dataTestId}
      style={style}
      color={color || null}
      count={intlCount}
      {...props}
    />
  );
};

export default styled(Badge)`
  ${style}
`;
