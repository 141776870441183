export default {
  id: 'users',
  moduleId: 'users',
  description: 'Users',
  modules: [
    {
      id: 'metrics',
      icon: 'chart-bar',
      description: 'Metrics',
      moduleId: 'metrics',
      type: 'local', // optional. 'local' is default
    },
    {
      id: 'search',
      icon: 'search',
      description: 'Profile Search',
      moduleId: 'profile-search',
      feature: {
        id: 'FE-1487',
        description: 'CONNEX Developer (Beta)',
        url: 'https://jira.commandalkon.com/browse/FE-1487',
      },
    },
  ],
};
