import { decrypt } from './secrets';

import queryLambda from './queryLambda';
import fetchIncremental from './fetch-incremental';
import fetchByDate from './fetchByDate';

const tableau = window.tableau;

export default (table, doneCallback) => {
  if (tableau.connectionData) {
    const settings = JSON.parse(tableau.connectionData);

    const { entityRef, privateKey = '', dataSet } = settings;

    const key = decrypt(privateKey, entityRef);

    if (dataSet.endsWith('ByDate')) {
      fetchByDate(table, settings, doneCallback, key).catch(tableau.log);
    } else {
      switch (dataSet) {
        case 'ListTickets':
          fetchIncremental(table, settings, doneCallback, key).catch(tableau.log);
          break;
        // case 'ListTicketsByDate':
        //   fetchByDate(table, settings, doneCallback, key).catch(tableau.log);
        //   break;
        default:
          queryLambda(table, settings, doneCallback, key).catch(tableau.log);
          break;
      }
    }
  } else {
    tableau.log(table);
    tableau.log('No Connection Data Found!');

    doneCallback();
  }
};
