import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import SearchInput from '../../../../components/input-search';
import { FormattedMessage } from 'react-intl';

// TODO: WHen searching, enter key should pick the first one in the list.
// TODO: perhaps levenshtein distance.

const AppSwitcherBody = ({ className, style, apps, execute }) => {
  const [filterString, setFilterString] = useState('');
  const [filteredApps, setFilteredApps] = useState(apps);
  const [isAutoClosedArmed, setIsAutoClosedArmed] = useState(false);

  const handleChange = useCallback(e => {
    setFilterString(e.target.value);
  }, []);

  useEffect(() => {
    const filtered = apps.filter(
      app => app.id.toUpperCase().indexOf(filterString.toUpperCase()) >= 0
    );
    setFilteredApps(filtered);
  }, [filterString, apps]);

  const armAutoClose = useCallback(() => {
    setIsAutoClosedArmed(true);
  }, []);

  const autoClose = useCallback(() => {
    if (isAutoClosedArmed) {
      execute();
    }
  }, [isAutoClosedArmed, execute]);

  return (
    <div
      className={cn('cx-app-switcher', className)}
      style={style}
      onMouseEnter={armAutoClose}
      onMouseLeave={autoClose}
    >
      <div className="body-wrapper">
        <div className="content">
          <div className="padded-wrapper">
            <div className="search">
              <SearchInput
                placeholder="Search for Apps..."
                value={filterString}
                onChange={handleChange}
                allowClear
              />
            </div>
            <div className="section-label">Apps</div>
          </div>
          <div className="apps">
            <div className="app-list">
              {filteredApps.map(app => {
                return (
                  <div key={app.id} className="app" onClick={() => execute(app.id)}>
                    <div className="icon">
                      <i className={`fas fa-${app.appConfig.icon}`} />
                    </div>
                    <div className="label">
                      <FormattedMessage id={`${app.id}.appName`} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default styled(AppSwitcherBody)(style);
