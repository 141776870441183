export default {
  Attributes: 'Атрибути',
  Duration: 'Тривалість',
  Entity: 'Сутність',
  JSON: 'JSON',
  StatementName: 'Назва заяви',
  Timestamp: 'Мітка часу',
  Row_Count: 'Кількість рядів',
  SQL: 'SQL',
  State: 'стан',
  Status: 'Статус',
  Name: "Ім'я",
  Creator: 'Творець',
  Modified: 'Модифікований',
  Tags: 'Теги',
  Actions: 'Дії',
  UserName: "ім'я користувача",
  ContainerName: 'Назва контейнера',
};
