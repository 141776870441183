import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { isObject, isArray, get, set } from 'lodash';

export const useIntlUtils = () => {
  const { formatMessage, formatNumber } = useIntl();

  const translate = useCallback(
    id => {
      return formatMessage({ id });
    },
    [formatMessage]
  );

  const translateObject = useCallback(
    (incomingObjects, incomingKeys, options = {}) => {
      const objects = [].concat(incomingObjects || []);
      const keys = [].concat(incomingKeys || []);

      return objects.map((object, index) => {
        const newObject = { ...object };
        [...keys].forEach(key => {
          if (!isObject(get(object, key))) {
            if (options.includeIndexes && options.includeIndexes.includes(index)) {
              set(newObject, key, formatMessage({ id: get(object, key) }));
            } else if (!options.includeIndexes) {
              set(newObject, key, formatMessage({ id: get(object, key) }));
            }
          }
        });
        return newObject;
      });
    },

    [formatMessage]
  );

  const translateComplexObject = useCallback(
    incomingObject => {
      const objectToTranslate = { ...incomingObject };
      const paths = [];

      const traverse = (object, path = '') => {
        const trimmedPath = path.startsWith('.') ? path.substring(1) : path;

        if (isArray(object)) {
          object.forEach((o, index) => {
            traverse(o, `${trimmedPath}[${index}]`);
          });
        } else {
          if (isObject(object)) {
            Object.keys(object).forEach(key => {
              //if objects only key is 'translate' replace object with value of .translate
              if (key === 'translate' && Object.keys(object).length === 1) {
                set(
                  objectToTranslate,
                  trimmedPath,
                  get(objectToTranslate, `${trimmedPath}.translate`)
                );
                paths.push(trimmedPath);
              } else {
                traverse(object[key], `${trimmedPath}.${key}`);
              }
            });
          }
        }
      };

      traverse(incomingObject);

      const translatedObject = translateObject(objectToTranslate, paths);

      return translatedObject[0];
    },
    [translateObject]
  );

  const formatObject = useCallback(
    (incomingObjects, incomingKeys, options = {}) => {
      const objects = [].concat(incomingObjects || []);
      const keys = [].concat(incomingKeys || []);

      return objects.map((object, index) => {
        const newObject = { ...object };
        [...keys].forEach(key => {
          if (options.includeIndexes && options.includeIndexes.includes(index)) {
            newObject[key] = formatNumber({ id: object[key] });
          } else if (!options.includeIndexes) {
            newObject[key] = formatNumber({ id: object[key] });
          }
        });
        return newObject;
      });
    },

    [formatNumber]
  );

  return { translate, translateObject, translateComplexObject, formatObject };
};
