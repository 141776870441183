import SummaryWidget from './summary-widget';
import React from 'react';

export default ({ metrics }) => {
  const Values = metrics || {};
  const { AcceptedCount = 0, RejectedCount, SharedCount = 0 } = Values;
  const Adoption =
    (SharedCount > 0 && Math.round(((AcceptedCount + RejectedCount) / SharedCount) * 100)) || 0;

  return (
    <SummaryWidget
      loading={!metrics}
      icon="handshake"
      total={SharedCount}
      headline={`Shared (${Adoption}% Acknowledged)`}
      leftTitle="Accepted"
      rightTitle="Rejected"
      leftButton={`${Math.round(AcceptedCount / 1000)}K`}
      rightButton={`${Math.round(RejectedCount / 1000)}K`}
    />
  );
};
