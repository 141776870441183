export default {
  appName: 'Пісочниця',
  'sandbox-dashboard': 'Панель приладів',
  'custom-components': 'Спеціальні компоненти',
  'sdk-components': 'Компоненти SDK',
  examples: 'Приклади',
  'dynamic-form': 'Динамічна форма',
  'theme-test': 'Тематичний тест',
  workspace: 'робоча область',
};
