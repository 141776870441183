import React from 'react';
import { Module } from '../../../../core/modules/Module';

const UserProfile = React.lazy(() => import('./view'));

export default ({ module }) => {
  return (
    <Module module={module}>
      <UserProfile />
    </Module>
  );
};
