import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import FlexColumn from 'components/layout-flex-column';
import style from './style';
import BuildList from './build-list';

const View = ({ className, module }) => {
  return (
    <FlexColumn className={cn('content', className)}>
      <BuildList module={module} />
    </FlexColumn>
  );
};

export default styled(View)`
  ${style}
`;
