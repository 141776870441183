import { chunk } from 'lodash';
import { executeQueryLambda } from './rockset';
import WDC from '../../api/wdc';

const tableau = window.tableau;

const dispatchData = (table, Items) => {
  const chunks = chunk(Items, 50000);

  chunks.forEach((c, index) => {
    table.appendRows(c);
  });
};

const report = msg => {
  tableau.reportProgress(msg);
  tableau.log(msg);
};

function apiLog(entityRef, dataSet, ACTION) {
  WDC(entityRef, {
    Action: 'log',
    DataSet: dataSet,
    Fields: ACTION.Fields,
  })
    .then(console.log)
    .catch(console.error);
}

const execute = async (table, settings, doneCallback, privateKey) => {
  const { entityRef, dataSet, params = [] } = settings;

  const ACTION = {
    Action: 'getData',
    Type: 'QueryLambda',
    QueryLambda: dataSet,
    Fields: [
      {
        name: 'EID',
        type: 'string',
        value: entityRef,
      },
      {
        name: 'Offset',
        type: 'int',
        value: 0,
      },
    ].concat(params),
  };

  report('Starting full extract');

  const limit = params.find(p => p.name === 'Limit');
  const offset = ACTION.Fields.find(p => p.name === 'Offset');

  const requestedLimit = parseInt(limit.value); // Limit requested by Connector configuration

  limit.value = `${Math.min(100000, requestedLimit)}`;

  let rowsReceived = 0,
    loops = 0,
    done = false;

  do {
    offset.value = rowsReceived;

    apiLog(entityRef, dataSet, ACTION);

    const Items = await executeQueryLambda(dataSet, ACTION.Fields, privateKey)
      .then(({ Items = [] }) => {
        return Items;
      })
      .catch(e => {
        console.error(e);
        tableau.log(e);

        // done = true;
        return [];
      });

    rowsReceived += Items.length;

    report(`[${loops + 1}] Received ${Items.length} Items :: Total ${rowsReceived}`);

    if (Items.length < parseInt(limit.value) || rowsReceived >= requestedLimit) {
      done = true;
    }

    if (Items.length > 0) {
      dispatchData(table, Items);
    }
  } while (!done);

  doneCallback();
};

export default execute;
