import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { formatBytes } from './format-bytes';

const DrawerTitleWithSize = ({ className, title, data }) => {
  const { formatMessage, formatNumber } = useIntl();

  const translatedTitle = React.useMemo(() => formatMessage({ id: title }), [formatMessage, title]);

  const formattedSize = React.useMemo(() => {
    if (!data) return '';

    const dataLength = JSON.stringify(data).length;

    return `${formatNumber(dataLength)} ${formatBytes(dataLength).scale}`;
  }, [data, formatNumber]);

  return (
    <div className={cn(className)}>
      <div className="title">{translatedTitle}</div>
      <div className="size">{formattedSize}</div>
    </div>
  );
};

export default styled(DrawerTitleWithSize)`
  .title {
    font-weight: bold;
  }
  .size {
    font-size: ${({ theme }) => `${theme.fontSizeMultiplier}rem`};
    height: ${({ theme }) => `${22 * theme.fontSizeMultiplier}px`};
  }
`;
