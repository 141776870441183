import React from 'react';
import cx from 'classnames';
import Menu from '../menu';
import Overlay from '../overlay';
import SidebarWrapper from '../sidebar-wrapper';
import useSidebar from './useSidebar';
import SidebarContext from '../sidebar-context';

const Sidebar = ({ children, className }) => {
  const {
    toggleIcon,
    toggleDrawer,
    mode,
    fixed,
    isDrawerOpen,
    onItemSelection,
    collapseToIconView,
    open,
  } = useSidebar();
  return (
    <SidebarContext
      value={{ toggleIcon, toggleDrawer, mode, fixed, isDrawerOpen, collapseToIconView, open }}
    >
      <SidebarWrapper
        className={cx('cx-sidebar-wrapper', mode, fixed, { open: isDrawerOpen }, className)}
      >
        <Overlay
          className="cx-overlay"
          open={isDrawerOpen}
          onClick={() => {
            toggleDrawer();
          }}
          mode={mode}
        />
        <Menu
          className={cx('cx-sidebar-menu', mode, fixed, { open: isDrawerOpen })}
          toggleIcon={toggleIcon}
          fixed={fixed}
          onItemSelection={onItemSelection}
        />
        <div className={cx('cx-content')}>{children}</div>
      </SidebarWrapper>
    </SidebarContext>
  );
};

export default Sidebar;
