import { API, Auth } from 'aws-amplify';
import moment from 'moment';
import getApiKey from './getApiKey';

let useWebIdentity = true;

const possibleJsonString = str => {
  try {
    return JSON.parse(str);
  } catch {
    return str;
  }
};

export default async (ServiceID, method, params = {}) => {
  const request = {
    body: {
      jsonrpc: '2.0',
      id: new Date().toISOString(),
      method,
      params,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': getApiKey(),
    },
  };

  if (useWebIdentity) {
    const Session = await Auth.currentSession();

    if (Session.idToken && Session.idToken.jwtToken) {
      request.headers['authorization'] = `${Session.idToken.jwtToken}`;
    }
  }

  const startPostTime = moment();
  return API.post('platform', `/console/${ServiceID}`, request)
    .then(({ result, error }) => {

      if(window.location.href.indexOf('localhost') >= 0) {
        // Check to see if the result is larger than 6MB and throw an error(this would be an error in lambda)
        if(JSON.stringify(result).length > 6000000) {
          throw new Error(`'${method}' request response larger than 6MB.`);
        }
      }

      const success = error === undefined;
      if (success === true) {
        return {
          success,
          response: result,
          runTime: moment().diff(startPostTime, 'milliseconds'),
        };
      }
      return {
        success,
        response: possibleJsonString(error.data.details),
        runTime: moment().diff(startPostTime, 'milliseconds'),
      };
    })
    .catch(error => {
      return {
        success: false,
        response: error.message || error,
        runTime: moment().diff(startPostTime, 'milliseconds'),
      };
    });
};
