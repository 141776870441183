export default () => {
  return [
    {
      title: 'Command ID',
      dataIndex: 'CommandId',
      key: 'CommandId',
      visible: true,
      hideFilter: true,
    },
    {
      title: 'Status',
      dataIndex: 'Status',
      key: 'Status',
      visible: true,
      hideFilter: true,
    },
    {
      title: 'Requested Date',
      dataIndex: 'RequestedDateTime',
      key: 'RequestedDateTime',
      visible: true,
      hideFilter: true,
    },
    {
      title: 'Document Name',
      dataIndex: 'DocumentName',
      key: 'DocumentName',
      visible: true,
      hideFilter: true,
    },
    {
      title: 'Comment',
      dataIndex: 'Comment',
      key: 'Comment',
      visible: true,
      hideFilter: true,
    },
    {
      title: '# targets',
      dataIndex: 'TargetCount',
      key: 'TargetCount',
      hideFilter: true,
      visible: true,
    },
    {
      title: '# error',
      dataIndex: 'ErrorCount',
      key: 'ErrorCount',
      hideFilter: true,
      visible: true,
    },
    {
      title: '# delivery timed out',
      dataIndex: 'DeliveryTimedOutCount',
      key: 'DeliveryTimedOutCount',
      hideFilter: true,
      visible: true,
    },
    {
      title: '# completed',
      dataIndex: 'CompletedCount',
      key: 'CompletedCount',
      hideFilter: true,
      visible: true,
    },
  ]
    .filter(c => c.visible === true)
    .map(c => {
      return {
        ...c,
        hideFilter: true,
        hideSort: true,
      };
    });
};
