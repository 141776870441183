import { ChartRq } from '../../../api';
import { useCallback, useState } from 'react';

export const useEntityValues = () => {
  const [customerStatuses, setCustomerStatuses] = useState(null);
  const [supplierStatuses, setSupplierStatuses] = useState(null);
  const [productTypes, setProductTypes] = useState(null);
  const [products, setProducts] = useState(null);
  const [origins, setOrigins] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [carriers, setCarriers] = useState(null);
  const [projects, setProjects] = useState(null);
  const [jobTimes, setJobTimes] = useState(null);

  const getTicketJobTimes = useCallback((EID, Filters) => {
    ChartRq({
      Action: 'getTicketJobTimes',
      EID,
      Filters: {
        ...Filters,
      },
    }).then(({ response: Items = { Items: [], TicketCount: 0 } }) => {
      setJobTimes(Items);
    });
  }, []);

  const createRequest = useCallback((EID, Filters, Field, Label, OtherLabel = 'Other') => {
    return ChartRq({
      Action: 'getEntityUniqueItems',
      EID,
      Filters: {
        ...Filters,
        Field,
        Label: Label || Field,
        OtherLabel,
      },
    }).then(({ response: Items = { Items: [], TicketCount: 0 } }) => {
      return Items;
    });
  }, []);

  const getEntityTicketMonths = useCallback((EID, Filters) => {
    return ChartRq({
      Action: 'getEntityTicketMonths',
      EID,
      Filters,
    }).then(({ response: Items = { Items: [], TicketCount: 0 } }) => {
      return Items;
    });
  }, []);

  const getCustomerStatuses = useCallback(
    (EID, Filters) => {
      return createRequest(EID, Filters, 'customerStatus', 'customerStatus', 'Status');
    },
    [createRequest]
  );

  const getSupplierStatuses = useCallback(
    (EID, Filters) => {
      return createRequest(EID, Filters, 'supplierStatus', 'supplierStatus', 'Status');
    },
    [createRequest]
  );

  const getProductTypes = useCallback(
    (EID, Filters) => {
      return createRequest(EID, Filters, 'productType', 'productType', 'Product Type');
    },
    [createRequest]
  );

  const getProducts = useCallback(
    (EID, Filters) => {
      return createRequest(EID, Filters, 'productId', 'productDescription', 'Product');
    },
    [createRequest]
  );

  const getCarriers = useCallback(
    (EID, Filters) => {
      return createRequest(EID, Filters, 'carrierId', 'carrierName', 'Carrier');
    },
    [createRequest]
  );

  const getOrigins = useCallback(
    (EID, Filters) => {
      return createRequest(EID, Filters, 'originId', 'originDescription', 'Origin');
    },
    [createRequest]
  );

  const getCustomers = useCallback(
    (EID, Filters) => {
      return createRequest(EID, Filters, 'customerId', 'customerName', 'Customer');
    },
    [createRequest]
  );

  const getProjects = useCallback(
    (EID, Filters) => {
      return createRequest(EID, Filters, 'projectId', 'projectName', 'Project');
    },
    [createRequest]
  );

  const getFilterValues = useCallback(
    (entityRef, Filters = {}) => {
      const params = {
        ...Filters,
      };

      setProductTypes(null);
      getProductTypes(entityRef, params).then(({ Items = [] }) => {
        setProductTypes(Items);
      });

      setProducts(null);
      getProducts(entityRef, params).then(({ Items = [] }) => {
        setProducts(Items);
      });

      setOrigins(null);
      getOrigins(entityRef, params).then(({ Items = [] }) => {
        setOrigins(Items);
      });

      setCustomers(null);
      getCustomers(entityRef, params).then(({ Items = [] }) => {
        setCustomers(Items);
      });

      setCarriers(null);
      getCarriers(entityRef, params).then(({ Items = [] }) => {
        setCarriers(Items);
      });

      setProjects(null);
      getProjects(entityRef, params).then(({ Items = [] }) => {
        setProjects(Items);
      });
    },
    [getCarriers, getCustomers, getOrigins, getProductTypes, getProducts, getProjects]
  );

  const getStatusValues = useCallback(
    (entityRef, Filters = {}) => {
      const params = {
        ...Filters,
      };

      setCustomerStatuses(null);
      getCustomerStatuses(entityRef, params).then(({ Items = [] }) => {
        const Sorted = [];

        ['PENDING', 'ACCEPTED', 'REJECTED'].forEach(Status => {
          const Item = Items.find(Item => Item.ID.startsWith(Status));

          Item && Sorted.push(Item);
        });

        setCustomerStatuses(Sorted);
      });

      setSupplierStatuses(null);
      getSupplierStatuses(entityRef, params).then(({ Items = [] }) => {
        setSupplierStatuses(Items);
      });
    },
    [getCustomerStatuses, getSupplierStatuses]
  );

  return {
    getStatusValues,
    getFilterValues,
    customerStatuses,
    supplierStatuses,
    productTypes,
    products,
    origins,
    customers,
    carriers,
    projects,
    jobTimes,
    getEntityTicketMonths,
    getTicketJobTimes,
  };
};

export default useEntityValues;
