import Dashboard from './index';
import i18n from './i18n';

export default {
  id: 'integr8-dashboard',
  i18n,
  disabled: false,
  component: Dashboard,

  permissions: {},
};
