import React, { Suspense } from 'react';
import styled from 'styled-components';
import cx from 'classnames';
import CSSTransition from 'react-transition-group/cjs/CSSTransition';
import { SizeMe } from 'react-sizeme';
import { Viewport } from './Viewport';
import FlexColumn from '../../components/layout-flex-column';
import Sidebar from '../components/sidebar';
import RightDrawer from './right-drawer';
import { NotificationLogProvider, NotificationsProvider } from '../contexts/notifications';
import ModuleSpinner from '../../core/modules/ModuleSpinner';
import { RightDrawerProvider } from '../contexts/right-drawer';

const AppLayout = ({ className, children, header, footer }) => {
  return (
    <NotificationLogProvider>
      <NotificationsProvider>
        <RightDrawerProvider>
          <CSSTransition in appear timeout={500} unmountOnExit className={className}>
            <div className={cx('layout')} style={{ overflow: 'hidden' }}>
              <div style={{ display: 'flex', height: '100vh' }}>
                <Sidebar>
                  <SizeMe monitorWidth monitorHeight>
                    {({ size }) => {
                      return (
                        <FlexColumn style={{ position: 'relative', height: '100%' }}>
                          <FlexColumn
                            style={{ position: 'absolute', width: '100%', height: size.height }}
                          >
                            <div className="app-header">{header}</div>
                            <div className="app-content" style={{ width: size.width }}>
                              <Suspense fallback={<ModuleSpinner />}>{children}</Suspense>
                            </div>
                            <div className="app-footer">{footer}</div>
                            <RightDrawer />
                          </FlexColumn>
                        </FlexColumn>
                      );
                    }}
                  </SizeMe>
                </Sidebar>
              </div>
            </div>
          </CSSTransition>
        </RightDrawerProvider>
      </NotificationsProvider>
    </NotificationLogProvider>
  );
};

const StyledAppLayout = styled(AppLayout)`
  font-family: 'Fira Sans', Helvetica, Arial, sans-serif;
  font-weight: 400;
  height: 100vh;
  min-height: 0;
  flex: 1;
  font-size: ${({ theme }) => `${theme.resolvedFontSize}px`};

  &.appear {
    opacity: 0.01;
  }

  &.appear-done {
    opacity: 1;
    transition: opacity 0.3s ease-in;
  }

  .ant-menu-submenu-open {
    font-weight: bold;
    background-color: var(--sidebarOpenBackgroundColor) !important;
    .ant-menu {
      background-color: transparent !important;
    }
  }

  .app-content {
    background-color: var(--contentBackgroundColor);
    overflow: auto;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    min-height: 0;
    min-width: 0;
    flex: 1;
    position: relative;
    //margin: 0 10px 10px 10px;
    //border: 1px solid var(--contentBorderColor);
  }
`;

export default props => (
  <Viewport>
    <StyledAppLayout {...props} />
  </Viewport>
);
