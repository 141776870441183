import { css } from 'styled-components';

export default css`
  .change-group {
    margin-top: 10px;
  }

  .change-group .list {
    padding: 10px;
  }

  .needs-review-true {
    color: red;
  }

  .needs-review-false {
  }
`;
