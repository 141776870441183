import apiLog from './api-log';
import { executeQueryLambda } from './rockset';
import { chunk } from 'lodash';

function normalizeValue(c, Item) {
  const { id, dataType } = c;
  const value = `${Item[id] || null}`;

  switch (dataType) {
    case 'int': // 0
      Item[id] = parseInt(value);
      break;
    case 'float': // 0.00
      Item[id] = parseFloat(value);
      break;
    case 'bool': // true, false
      Item[id] = value === 'true';
      break;
    case 'date': // 2020-01-01
      Item[id] = Item[id] === null || value.length < 10 ? '1776-07-04' : value.substr(1, 10);
      break;
    case 'datetime': // 2020-01-01T00:00:00Z
      Item[id] = Item[id] === null || value.length < 20 ? '1776-07-04' : `${value.substr(1, 19)}Z`;
      break;
    default:
      Item[id] = value;
      break;
  }
}

export default async (Fields, entityRef, dataSet, privateKey, doneCallback, table, columns) => {
  const { incrementValue } = table;
  const Limit = Fields.find(p => p.name === 'Limit');
  const Offset = Fields.find(p => p.name === 'Offset');
  const SearchDt = Fields.find(p => p.name === 'SearchDt');

  let rowsReceived = 0,
    Items = [];

  do {
    Offset.value = rowsReceived;

    await apiLog(entityRef, dataSet, Fields, `Execute Query Lambda :: ${dataSet}`, false);

    const startTm = Date.now();

    Items = await executeQueryLambda(dataSet, Fields, privateKey)
      .then(({ Items = [] }) => {
        return Items;
      })
      .catch(e => async () => {
        const Message = `QL::ERROR:: ${e.message || 'Error executing query lambda'}`;
        await apiLog(entityRef, dataSet, Fields, Message);

        doneCallback();
        return [];
      });

    rowsReceived += Items.length;

    const Duration = Date.now() - startTm;
    const Increment = parseInt(incrementValue || '0') + 1;
    const Message = `[${SearchDt.value}] ***SIZE ${JSON.stringify(Items).length}*** :: ${
      Items.length
    } Items :: Duration ${Duration}ms :: Increment ${Increment} :: Total ${rowsReceived}`;

    await apiLog(entityRef, dataSet, Fields, Message);

    if (Items.length > 0) {
      Items.forEach(Item => {
        Item['_incremental'] = `${Increment}`;

        columns.forEach(c => {
          normalizeValue(c, Item);
        });
      });

      const chunks = chunk(Items, 50000);

      chunks.forEach(table.appendRows);

      // table.appendRows(Items);
    }
  } while (Items.length >= parseInt(Limit.value));

  return rowsReceived;
};
