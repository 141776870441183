import React, { useCallback, useEffect, useState } from 'react';
import { Col, DatePicker, Row } from 'antd';
import moment from 'moment';
import { useEntityValues } from '../useEntityValues';
import SupplierFilters from './supplier-filters';
import DateFilters from './date-filters';

export default ({ entityRef, loading, entity, filtersChanged }) => {
  const [supplierFilter, setSupplierFilter] = useState(null);
  const [captureDates, setCaptureDates] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const [StartDt, setStartDt] = useState(null);
  const [EndDt, setEndDt] = useState(null);
  const [ticketMonths, setTicketMonths] = useState(null);

  const { getEntityTicketMonths } = useEntityValues();

  const apply = useCallback(() => {
    if (supplierFilter && filtersChanged) {
      filtersChanged({
        ...supplierFilter,
        DateRange: dateRange,
        StartDt,
        EndDt,
      });
    }
  }, [EndDt, StartDt, dateRange, filtersChanged, supplierFilter]);

  useEffect(() => {
    const showDates = dateRange && dateRange.type === 'custom';

    setCaptureDates(showDates);

    !showDates && apply();
  }, [apply, dateRange]);

  useEffect(() => {
    if (supplierFilter) {
      getEntityTicketMonths(entityRef, supplierFilter).then(({ Years }) => {
        setTicketMonths(Years);

        setDateRange({ type: 'fixed', year: 'ALL' });
      });
    }
  }, [entityRef, getEntityTicketMonths, supplierFilter]);

  useEffect(() => {
    apply();
  }, [StartDt, EndDt, apply]);

  const setDates = useCallback((dates = []) => {
    if (dates.length === 2) {
      setStartDt(`${dates[0].toISOString().substr(0, 19)}Z`);
      setEndDt(`${dates[1].toISOString().substr(0, 19)}Z`);
    }
  }, []);

  return (
    <Row>
      <Col span={8}>
        <SupplierFilters loading={loading} entity={entity} onSelected={setSupplierFilter} />
      </Col>
      <Col span={8}>
        {supplierFilter && <DateFilters ticketMonths={ticketMonths} onSelected={setDateRange} />}
      </Col>
      <Col span={8}>
        {captureDates && (
          <div style={{ width: '100%', paddingRight: '10px' }}>
            <DatePicker.RangePicker
              defaultPickerValue={[moment(), moment()]}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              onOk={setDates}
            />
          </div>
        )}
      </Col>
    </Row>
  );
};
