import { AmplifyAuthenticator, AmplifySignIn } from '@aws-amplify/ui-react';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import React, { useEffect, useState, useCallback } from 'react';
import { UserProvider } from './contexts/user/UserContextProvider';
import { Auth } from 'aws-amplify';
import AppsConfig from './components/app-config/AppsConfig';
import LandingView from './views/landing/LandingView';
import { Theme } from './theme-provider';
import { Internationalization } from '../i18n';

const STATE = {
  SIGNED_IN: 'signedin',
  SIGNED_OUT: 'signedout',
};

export const AmplifyAuth = props => {
  const [authState, setAuthState] = useState(null);
  const [user, setUser] = useState();

  const getUser = useCallback(() => {
    return Auth.currentAuthenticatedUser()
      .then(user => {
        setUser(user);
        return user;
      })
      .catch(e => {
        setUser(null);
      });
  }, []);

  useEffect(() => {
    getUser();
  }, [authState, getUser]);

  useEffect(() => {
    return onAuthUIStateChange(newAuthState => {
      setAuthState(newAuthState);
    });
  }, []);

  return (
    <Internationalization>
      <UserProvider user={user}>
        <Theme defaultTheme="dark">
          <LandingView
            authState={authState}
            visible={!!(authState && authState !== STATE.SIGNED_IN)}
          >
            <AmplifyAuthenticator className="CONNEX-Login">
              <AmplifySignIn slot="sign-in" hideSignUp />
              {authState === STATE.SIGNED_IN && user && <AppsConfig>{props.children}</AppsConfig>}
            </AmplifyAuthenticator>
          </LandingView>
        </Theme>
      </UserProvider>
    </Internationalization>
  );
};
