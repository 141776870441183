import { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const TooltipPortal = ({ children }) => {
  const [element] = useState(document.createElement('div'));

  useEffect(() => {
    document.body.appendChild(element);
    return () => {
      document.body.removeChild(element);
    };
  }, [element]);

  return ReactDOM.createPortal(children, element);
};

export default TooltipPortal;
