import WDC from '../../api/wdc';

const Tableau = window['tableau'];

const log = (msg, progress) => {
  Tableau.log(msg);

  progress && Tableau.reportProgress(msg);
};

export default (entityRef, dataSet, Params, Message = '', progress = true) => {
  if (Message !== '') {
    log(Message, progress);
  }

  return WDC(entityRef, {
    Action: 'Log',
    DataSet: dataSet,
    Params,
    Message,
  })
    .then(console.log)
    .catch(console.error);
};
