import { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { find } from 'lodash';
import { useSidebarContext } from '../components/sidebar/components/sidebar-context';
import { useAppsConfig } from '../components/app-config/useAppsConfig';

const findModule = (app, level, moduleId) => {
  let module = null;
  const targetPath = `/app/${app.id}/${level}/${moduleId}`;

  const searchModules = modules => {
    modules.forEach(m => {
      if (m.modules) {
        searchModules(m.modules);
      } else {
        if (m.moduleConfig.path === targetPath) {
          module = m;
        }
      }
    });
  };

  searchModules(app.modules);

  return module;
};

const ManageSidebar = ({ children }) => {
  // TODO: Consider whether or not the user prefers the sidebar to remain open.

  const { collapseToIconView, open } = useSidebarContext();

  const { params } = useRouteMatch();
  const { appsConfig } = useAppsConfig();

  useEffect(() => {
    if (appsConfig && params) {
      const { appId, level, moduleId } = params;

      const app = find(appsConfig, app => app.id === appId);

      const module = findModule(app, level, moduleId);

      const { autoCloseSidebar } = module.moduleConfig;

      if (autoCloseSidebar) {
        collapseToIconView();
      } else {
        open();
      }
    }
  }, [params, appsConfig, collapseToIconView, open]);

  return children;
};

export default ManageSidebar;
