import { useEffect, useState } from 'react';
import cn from 'classnames';
import propTypes from 'prop-types';
import ReactDOM from 'react-dom';

const Portal = ({ children, id }) => {
  const [element] = useState(document.createElement('div'));

  useEffect(() => {
    element.className = cn('cx-portal');
    element.id = id;
    document.body.appendChild(element);
    return () => {
      document.body.removeChild(element);
    };
  }, [element, id]);

  return ReactDOM.createPortal(children, element);
};

Portal.propTypes = {
  children: propTypes.oneOfType([propTypes.arrayOf(propTypes.element), propTypes.element]),
  id: propTypes.string,
};

export default Portal;
