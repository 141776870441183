import Amplify from 'aws-amplify';
import envConfig from './env-dev';

const DEFAULT_CONFIG = {
  region: 'us-west-2',
  identityPoolId: 'us-west-2:0604e5b5-df8b-4635-8cf1-d8da04e9ff07',
  userPoolId: 'us-west-2_j3geU6mey',
  appId: '7kjgl0g7ksrgdsjhtf61mqmf9k',
  endpoints: {
    platform: 'https://connex-team-cx-develop-api.commandalkon.io',
  },
};

const amplify = (appId, env) => {
  const p = window.location.hostname.split('.');

  const appConfig = envConfig[appId] || {};
  const config = appConfig[env || p[0]] || DEFAULT_CONFIG;
  const aws_cloud_logic_custom = [];

  Object.keys(config.endpoints).forEach(name => {
    const endpoint = config.endpoints[name];
    aws_cloud_logic_custom.push({
      name,
      endpoint,
      region: config.region,
    });
  });

  const AmplifyConfig = {
    aws_project_region: config.region,
    aws_cognito_region: config.region,
    aws_cognito_identity_pool_id: config.identityPoolId,
    aws_user_pools_id: config.userPoolId,
    aws_user_pools_web_client_id: config.appId,
    aws_cloud_logic_custom,
  };

  Amplify.configure(AmplifyConfig);

  return config;
};

export const Runtime = {
  initialize: (appId, env) => amplify(appId, env),
};
