import { css } from 'styled-components';

export default css`
  &&&& {
    &.cx-checkbox-disabled {
      & > *:nth-child(2) {
        color: var(--checkbox-disabled-label-color);
      }
      .ant-checkbox-inner {
        border-color: var(--checkbox-disabled-border-color) !important;
        background-color: var(--checkbox-disabled-background-color) !important;
        &:after {
          border-color: var(--checkbox-disabled-check-color) !important;
        }
      }
    }

    .ant-checkbox {
      &:not(.ant-checkbox-checked) {
        &:hover {
          .ant-checkbox-inner {
            border-color: var(--checkbox-selected-border-color);
          }
        }
        .ant-checkbox-inner {
          background-color: var(--checkbox-background-color);
          border-color: var(--checkbox-border-color);
        }
      }
      &.ant-checkbox-checked {
        &:after {
          border-color: var(--checkbox-selected-border-color);
        }
        .ant-checkbox-inner {
          background-color: var(--checkbox-selected-background-color);
          border-color: var(--checkbox-selected-border-color);
        }
      }
    }
    height: var(--base-height);
    font-size: var(--checkbox-font-size);
    color: var(--checkbox-color);

    &:active,
    &:focus {
      box-shadow: none;
    }
  }
`;
