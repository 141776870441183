import React, { useEffect } from 'react';
import cn from 'classnames';
import { useRouteMatch, Route, useParams } from 'react-router-dom';
import useDataSets from './useDataSets';
import { Spinner } from '../../../../../../../components';
import DataSetList from './DataSetList';

const DataSetView = ({ className }) => {
  const { entityRef } = useParams();
  const { path } = useRouteMatch();
  const { dataSets, getDataSets, busy } = useDataSets();

  useEffect(() => {
    getDataSets(entityRef);
  }, [getDataSets, entityRef]);

  return (
    <Spinner spinning={busy} className={cn(className)}>
      <Route path={`${path}`} exact>
        <DataSetList dataSets={dataSets} />
      </Route>
    </Spinner>
  );
};

export default DataSetView;
