import { css } from 'styled-components';

export default css`
  &&& {
    font-size: ${({ theme }) => `${theme.fontSizeMultiplier}em`};
    color: var(--baseTextColor);
    transition: all 0.3s;
    &.error {
      color: var(--failureColor);
    }
    &.success {
      color: var(--successColor);
    }
  }
`;
