import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { Space } from 'antd';
import { Button, Table, Text } from 'components';
import useHandleApiCall from 'util/handle-api-call';
import { useDrawerContext } from 'core/contexts/right-drawer';
import { useModulesContext } from 'core/contexts/modules';
import { API } from 'api/index';
import useExecuteQuery from './useExecuteQuery';
import ShowSql from './show-sql';
import { useQueryLogsCache } from 'core/contexts/modules/useQueryLogsCache';
import { divergingLog } from 'd3-scale/src/diverging';

const QueryLogContext = createContext({});

const SqlLookup = API.Developer.Sql.SqlLookupRq;

const useQueryLog = () => {
  const { queryLogsCache } = useModulesContext();

  const [lookupResults, setLookupResults] = useState(_.get(queryLogsCache, 'selectedItem.results'));
  const [columns, setColumns] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalSQL, setShowModalSQL] = useState(false);
  const [activeRow, setActiveRow] = useState(_.get(queryLogsCache, 'selectedItem.item'));
  const [sqlLookupAPI, sqlLookupBusy] = useHandleApiCall(SqlLookup);
  const { moduleHandoff } = useModulesContext();
  const { HeaderText } = Table;
  const { openRightDrawer } = useDrawerContext();

  const { executeQuery, results, rowCount, loading } = useExecuteQuery();

  const handleShowSQL = useCallback(
    data => {
      queryLogsCache.setSelectedItem(s => ({ ...s, item: data.id }));
      setActiveRow(data.id);
      openRightDrawer({
        content: <ShowSql data={data} moduleHandoff={moduleHandoff} />,
        width: 800,
        title: <Text text="audit-logs.SQL" />,
      });
    },
    [moduleHandoff, openRightDrawer]
  );

  const showJSONModal = useCallback(
    data => {
      if (_.get(queryLogsCache, 'selectedItem.item') === data) {
        setShowModal(true);
      } else {
        setLookupResults(null);
        sqlLookupAPI(data).then(results => {
          setLookupResults(results.response.Items || []);
          queryLogsCache.setSelectedItem(s => ({ ...s, results: results.response.Items || [] }));
        });
        queryLogsCache.setSelectedItem(s => ({ ...s, item: data }));
        setActiveRow(data);
        setShowModal(true);
      }
    },
    [lookupResults, sqlLookupAPI, queryLogsCache.setSelectedItem]
  );

  const firstColumnRender = useCallback(
    data => {
      return (
        <Space size="middle">
          <Button
            icon={<span className="fa fa-eye" />}
            type="link"
            title="audit-logs.view-json"
            onClick={() => showJSONModal(data.id)}
          />
          <Button
            icon={<i className="fa">SQL</i>}
            type="link"
            title="audit-logs.view-sql-statement"
            onClick={() => handleShowSQL(data)}
          />
        </Space>
      );
    },
    [handleShowSQL, showJSONModal]
  );

  const [firstColumn, setFirstColumn] = useState({});

  useEffect(() => {
    setFirstColumn({
      width: '100px',
      hideFilter: true,
      hideSort: true,
      title: '',
      render: firstColumnRender,
    });
  }, [firstColumnRender, setFirstColumn]);

  useEffect(() => {
    const columnValues = {};

    const dataExists = results && results.length > 0;

    if (!dataExists) {
      return;
    }

    for (const result of results) {
      const newTableResult = {};
      Object.keys(result).forEach(key => {
        if (!(key in columnValues)) {
          columnValues[key] = key;
        }
        let value = result[key];
        if (typeof value === 'object' || _.isArray(value)) {
          value = JSON.stringify(value);
        }
        newTableResult[key] = value;
      });
    }

    const dataColumns = Object.keys(columnValues).map(k => {
      if (k === 'Duration') {
        return {
          title: <HeaderText text={k} />,
          dataIndex: k,
          width: 150,
          render: value => `${(value * 0.001).toFixed(3)} sec`,
        };
      }
      if (k === 'Status') {
        return {
          title: <HeaderText text={k} />,
          dataIndex: k,
          width: 150,
          customSorter: (a, b) => {
            if ((a.Status && b.Status) || (!a.Status && !b.Status)) {
              return 0;
            } else if (a.Status && !b.Status) {
              return -1;
            }
            return 1;
          },
          render: value =>
            value ? (
              <Text className="error" text="audit-logs.error" />
            ) : (
              <Text className="success" text="audit-logs.success" />
            ),
        };
      }
      if (k === 'Timestamp') {
        return {
          title: <HeaderText text={k} />,
          dataIndex: k,
          render: value => moment(value).toISOString(),
          defaultSortOrder: 'descend',
          width: 200,
        };
      }

      return {
        title: <HeaderText text={k} />,
        dataIndex: k,
        width: 200,
      };
    });

    const allColumns = _.cloneDeep(dataColumns);
    const retColumns = allColumns.filter(k => k.dataIndex !== 'parameters');
    retColumns[0] = firstColumn;

    setColumns(retColumns);
  }, [results, firstColumn, handleShowSQL]);

  return {
    loading,
    lookupResults,
    columns,
    modalLoading: sqlLookupBusy,
    rowCount,
    showModal,
    setShowModal,
    showModalSQL,
    setShowModalSQL,
    results,
    activeRow,
    executeQuery,
  };
};

export const QueryLogContextProvider = ({ children }) => {
  const contextProps = useQueryLog();
  return (
    <QueryLogContext.Provider value={{ ...contextProps }}>{children}</QueryLogContext.Provider>
  );
};

export const useQueryLogContext = () => {
  const value = useContext(QueryLogContext);
  if (!value) {
    throw new Error('QueryLog Context must be used beneath QueryLogContextProvider');
  }
  return value;
};

export default { QueryLogContextProvider, useQueryLogContext };
