import React from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';
import cn from 'classnames';
import propTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import style from './style';

const { Text: AntText } = Typography;

const TextWithoutTranslation = ({ className, text }) => {
  return <AntText className={cn('cx-text', className)}>{text}</AntText>;
};

const TextWithTranslation = ({ className, text, values, styled = true }) => {
  return styled ? (
    <AntText className={cn('cx-text', 'translated', className)}>
      <FormattedMessage id={text} values={values} />
    </AntText>
  ) : (
    <FormattedMessage id={text} values={values} />
  );
};

const Text = ({ noTranslation, ...props }) => {
  return noTranslation ? <TextWithoutTranslation {...props} /> : <TextWithTranslation {...props} />;
};

TextWithoutTranslation.propTypes = {
  className: propTypes.string,
  text: propTypes.string,
};

TextWithTranslation.propTypes = {
  className: propTypes.string,
  text: propTypes.string,
  values: propTypes.oneOfType([propTypes.string, propTypes.object]),
  styled: propTypes.bool,
};

Text.propTypes = {
  noTranslation: propTypes.bool,
};

export default styled(Text)`
  ${style}
`;
