import { css } from 'styled-components';

export default css`
  font-size: 25px;
  color: var(--appSwitcherIconColor);
  margin-left: 20px;
  transition: all 0.3s;
  &.drawer {
    margin-left: 35px;
  }
  .cx-toggle-app-switcher {
    display: flex;

    svg {
      transition: all 0.3s;
      .s {
        fill: #ccc;
        transition: all 0.3s;
      }
    }

    &.enabled {
      cursor: pointer;

      &.active,
      &:hover {
        svg {
          .s1 {
            fill: var(--app-switcher-color-cell-1);
          }
          .s2 {
            fill: var(--app-switcher-color-cell-2);
          }
          .s3 {
            fill: var(--app-switcher-color-cell-3);
          }

          .s4 {
            fill: var(--app-switcher-color-cell-4);
          }
          .s5 {
            fill: var(--app-switcher-color-cell-5);
          }
          .s6 {
            fill: var(--app-switcher-color-cell-6);
          }

          .s7 {
            fill: var(--app-switcher-color-cell-7);
          }
          .s8 {
            fill: var(--app-switcher-color-cell-8);
          }
          .s9 {
            fill: var(--app-switcher-color-cell-9);
          }
        }
      }
    }
  }
`;
