import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import propTypes from 'prop-types';
import { useIntlProps } from '../_stories/util/useIntlProps';
import style from './style';

const Label = ({ className, htmlFor, text }) => {
  const [intlText] = useIntlProps(text);

  return (
    <label htmlFor={htmlFor} className={cn(className, 'cx-label')}>
      {intlText}
    </label>
  );
};

Label.propTypes = {
  className: propTypes.string,
  htmlFor: propTypes.string,
  text: propTypes.string,
};

export default styled(Label)`
  ${style}
`;
