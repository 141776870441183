// TODO: externally configurable.

export const BASE_FONT_SIZE_PX = 14;
export const SIDEBAR_WIDTH_PX = 200;
export const MENU_ITEM_HIGHLIGHT_WIDTH = 3;
export const ICON_VIEW_WIDTH = 50;
export const MENU_ITEM_HEIGHT = 40;
export const SUB_MENU_ITEM_HEIGHT = 30;
export const DRAWER_BREAKPOINT = 900;

export const BASE_HEADER_HEIGHT = 40;
export const BASE_HEADER_LOGO_HEIGHT = 20;
export const BASE_FOOTER_HEIGHT = 40;

export const APP_PATH = '/app';
export const ERROR_PATH = '/error';

export const MODE = {
  FIXED: 'fixed',
  DRAWER: 'drawer',
};

export const FIXED = {
  FULL: 'fixed-full',
  ICON: 'fixed-icon',
};
