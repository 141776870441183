import moment from 'moment';
import apiLog from './api-log';
import extractItems from './extract-items';
import { setCursor, getCursor } from './extract-cursor';

const getStartDt = async (table, settings) => {
  const { entityRef, connectionName, params = [] } = settings;

  const { incrementValue } = table;

  const StartDt = params.find(p => p.name === 'SearchDt').value;

  if (incrementValue) {
    const LastDt = await getCursor(entityRef, connectionName, StartDt);

    return moment(LastDt).add(1, 'day').format('YYYY-MM-DD');
  }

  return StartDt;
};

const fetchByDate = async (table, settings, doneCallback, privateKey) => {
  const { entityRef, dataSet, connectionName, params = [], schemas = [] } = settings;
  const { incrementValue } = table;
  const columns = (schemas[0] && schemas[0].columns) || [];
  const StartDt = await getStartDt(table, settings);

  const SearchDt = {
    name: 'SearchDt',
    type: 'string',
    value: `${StartDt}`,
  };

  const EndDt = (incrementValue && moment().format(`YYYY-MM-DD`)) || StartDt;

  let totalItems = 0,
    dailyItems = 0;

  do {
    await apiLog(
      entityRef,
      dataSet,
      { SearchDt, privateKey },
      `Search Date ${SearchDt.value} :: Stop Date ${EndDt}`
    );

    const Fields = [
      { name: 'EID', type: 'string', value: entityRef },
      { name: 'Offset', type: 'int', value: 0 },
      { name: 'Limit', type: 'int', value: 250000 },
      SearchDt,
    ];

    dailyItems = await extractItems(
      Fields,
      entityRef,
      dataSet,
      privateKey,
      doneCallback,
      table,
      columns
    );

    totalItems += dailyItems;

    await setCursor(entityRef, connectionName, SearchDt.value);

    SearchDt.value = moment(SearchDt.value).add(1, 'day').format('YYYY-MM-DD');
  } while (SearchDt.value < EndDt); // Stops at the previous date

  await apiLog(entityRef, dataSet, params, `Extract Completed. ${totalItems} Items Extracted.`);

  doneCallback();
};

export default fetchByDate;
