import React from 'react';
import i18n from './i18n';
import permissions from './permissions';

const component = React.lazy(() => import('./view'));

export default {
  id: 'theme-test',
  moduleId: 'theme-test',
  component,
  i18n,
  permissions,
};
