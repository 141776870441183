import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';
import FlexColumn from '../../../../../../../components/layout-flex-column';
import Tabs from '../../../../../../../components/tabs';
import { useIntl } from 'react-intl';
import Input from '../../../../../../../components/input';
import Button from '../../../../../../../components/button';
import { Field, Form, Formik, useFormikContext } from 'formik';
import style from './DataSetDetailsStyle';

const { TabPane } = Tabs;

const newValue = {
  General: {
    ID: '',
    Description: '',
    Frequency: '',
    Iterator: '',
  },
  Schema: '',
  Params: '',
};

const ActionButtons = () => {
  const { submitForm } = useFormikContext();
  return <Button htmlType="submit" label="save" onClick={submitForm} />;
};

const prepareDataSet = dataSet => {
  if (!dataSet) return null;

  return {
    ...dataSet,
    Schema: JSON.stringify(dataSet.Schema, null, 2),
    Params: JSON.stringify(dataSet.Params, null, 2),
  };
};

export const DataSetDetails = ({ className }) => {
  const { state: dataSet } = useLocation();

  const { formatMessage } = useIntl();

  const general = formatMessage({ id: 'datasets.general' });
  const schema = formatMessage({ id: 'datasets.schema' });
  const parameters = formatMessage({ id: 'datasets.parameters' });

  return (
    <Formik
      initialValues={prepareDataSet(dataSet) || newValue}
      onSubmit={(values, { isSubmitting }) => {
        const preparedValues = {
          ...values,
          Schema: JSON.parse(values.Schema),
          Params: JSON.parse(values.Params),
        };
        console.log('submitting', preparedValues);
      }}
    >
      {props => {
        return (
          <FlexColumn className={cn(className)}>
            Entity - DataSet
            <Form>
              <Tabs
                type="card"
                className={cn('data-set-details')}
                tabBarExtraContent={<ActionButtons />}
              >
                <TabPane tab={general} key="1" className="general-tab">
                  <FlexColumn
                    className="outer-wrapper"
                    style={{ padding: '5px 10px', overflow: 'hidden' }}
                  >
                    <FlexColumn
                      className="inner-wrapper"
                      style={{ padding: '0', overflow: 'auto' }}
                    >
                      <div>
                        ID:
                        <Field as={Input} name="ID" />
                      </div>
                      <div>
                        Description: <Field as={Input} name="Description" />
                      </div>
                      <div>
                        Frequency:
                        <Field as={Input} name="Frequency" />
                      </div>
                      <div>
                        Iterator:
                        <Field as={Input} name="Iterator" />
                      </div>
                    </FlexColumn>
                  </FlexColumn>
                </TabPane>
                <TabPane tab={schema} key="2" className="schemas-tab">
                  <Field as="textarea" name="Schema" />
                </TabPane>
                <TabPane tab={parameters} key="3" className="parameters-tab">
                  <Field as="textarea" name="Params" />
                </TabPane>
              </Tabs>
            </Form>
          </FlexColumn>
        );
      }}
    </Formik>
  );
};

export default styled(DataSetDetails)`
  ${style}
`;
