import { Area, AreaChart, Tooltip, XAxis, YAxis } from 'recharts';
import MonthTip from './MonthTip';
import React from 'react';

const Chart = ({
  width,
  height = 250,
  data,
  labelKey = 'Label',
  dataKey = 'TicketCount',
  yAxis = true,
  onClick,
}) => {
  return (
    <AreaChart
      width={width > 0 ? width : 600}
      height={height > 0 ? height : 480}
      data={data}
      margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      onClick={e => onClick && onClick(e.activeLabel)}
    >
      <defs>
        <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
          <stop offset="2%" stopColor="#337CD0" stopOpacity={0.9} />
          <stop offset="98%" stopColor="#337CD0" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis dataKey={labelKey} />
      {yAxis === true && data && <YAxis />}
      <Tooltip content={<MonthTip />} />
      <Area
        type="monotone"
        dataKey={dataKey}
        stroke="#666666"
        fillOpacity={1}
        fill="url(#color1)"
      />
    </AreaChart>
  );
};

export default Chart;
