import React from 'react';
import styled from 'styled-components';
import BaseTable from '../table';
import AdvancedTable from './advanced-table';
import cn from 'classnames';
import propTypes from 'prop-types';
import style from './style';
import Text from '../typography/text';
import sha256 from 'crypto-js/sha256';
import Empty from 'components/empty';
import { useIntlUtils } from 'components/i18n/useIntlUtils';

const ROW_KEY = '__rowKey';

const createRowKeys = arr =>
  arr.map((item, index) => ({
    ...item,
    [ROW_KEY]: sha256(`${index}::${JSON.stringify(item)}`).toString(),
  }));

const Table = ({
  className,
  columns,
  dataSource = [],
  showFilters = false,
  showSort = false,
  rowKey,
  ...props
}) => {
  const { translate } = useIntlUtils();

  const locale = React.useMemo(() => ({ emptyText: translate('emptyText') }), [translate]);

  const preparedDataSource = React.useMemo(() => {
    if (rowKey) return dataSource;
    return createRowKeys(dataSource);
  }, [rowKey, dataSource]);

  if (showFilters || showSort) {
    return preparedDataSource.length ? (
      <AdvancedTable
        className={cn('tables', className)}
        columns={columns}
        dataSource={preparedDataSource}
        showFilters={showFilters}
        showSort={showSort}
        rowKey={rowKey || ROW_KEY}
        locale={locale}
        {...props}
      />
    ) : (
      <Empty />
    );
  }

  return (
    <BaseTable
      className={cn('tables', className)}
      columns={columns}
      dataSource={preparedDataSource}
      rowKey={rowKey || ROW_KEY}
      locale={locale}
      {...props}
    />
  );
};

const HeaderText = ({ text }) => <Text text={`columnHeaders.${text}`} />;
Table.HeaderText = HeaderText;

HeaderText.propTypes = {
  text: propTypes.string,
};

Table.propTypes = {
  className: propTypes.string,
  columns: propTypes.arrayOf(propTypes.object),
  dataSource: propTypes.arrayOf(propTypes.object),
  showFilters: propTypes.bool,
  showSort: propTypes.bool,
  rowKey: propTypes.oneOfType([propTypes.number, propTypes.string]),
};

Table.defaultProps = {
  dataSource: [],
  showFilters: false,
  showSort: false,
};

export default styled(Table)`
  ${style}
`;
