import { Text } from 'components';
import React from 'react';

export default {
  cancelled: 'Cancelled',
  cancelling: 'Cancelling',
  'create-build': 'Create Build',
  failed: 'Failed',
  'in-progress': 'In Progress',
  'max-results': 'Max Results',
  pending: 'Pending',
  status: 'Status',
  success: 'Success',
  'timed-out': 'Timed Out',
};
