import { css } from 'styled-components';

export default css`
  &&&.cx-input-search {
    border: 1px solid var(--inputBorderColor);
    input.ant-input {
      color: var(--inputColor);
      &::placeholder {
        color: var(--inputPlaceholderColor);
      }
    }
    .ant-input-suffix {
      .ant-input-search-icon {
        &:before {
          border-left: 1px solid var(--inputBorderColor);
        }
        color: var(--inputPlaceholderColor);
      }
    }
  }
`;
