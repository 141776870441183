import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import AuthenticatedRoute from './AuthenticatedRoute';

const BaseRoute = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/app">
          <AuthenticatedRoute />
        </Route>

        <Route path="/:componentId">
          <PublicRoute />
        </Route>

        <Route path="/">
          <AuthenticatedRoute />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default BaseRoute;
