import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Spin } from 'antd';
import CSSTransition from 'react-transition-group/CSSTransition';
import propTypes from 'prop-types';
import style from './style';
import FlexColumn from '../layout-flex-column';

const Spinner = ({ spinning, size = 'large', additionalAction, children, className }) => {
  if (spinning) {
    return (
      <div className={cn('cx-spinner', className)}>
        <div>
          <Spin spinning={spinning} size={size} />
        </div>
        <div className="additional-action">{additionalAction}</div>
      </div>
    );
  }

  return (
    <CSSTransition in={!spinning} appear timeout={500} unmountOnExit classNames="spinner">
      <FlexColumn className={className}>{children}</FlexColumn>
    </CSSTransition>
  );
};

Spinner.propTypes = {
  spinning: propTypes.bool,
  size: propTypes.string,
  additionalAction: propTypes.oneOfType([propTypes.object, propTypes.string, propTypes.element]),
  children: propTypes.any,
  className: propTypes.string,
};

Spinner.defaultProps = {
  size: 'large',
};

export default styled(Spinner)`
  ${style}
`;
