import React, { useCallback, useState } from 'react';
import { isFunction } from 'lodash';

const DrawerStateContext = React.createContext();
const DrawerContext = React.createContext();

export const useDrawerStateContext = () => {
  const context = React.useContext(DrawerStateContext);
  if (!context) {
    throw new Error(
      '`useDrawerStateContext cannot be rendered outside of the DrawerStateContext context provider`'
    );
  }
  return context;
};

export const useDrawerContext = () => {
  const context = React.useContext(DrawerContext);
  if (!context) {
    throw new Error(
      '`useDrawerContext cannot be rendered outside of the DrawerContext context provider`'
    );
  }
  return context;
};

export const RightDrawerProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);
  const [footer, setFooter] = useState(null);
  const [onCancel, setOnCancel] = useState(() => () => {});
  const [width, setWidth] = useState();
  const [title, setTitle] = useState();

  const closeRightDrawer = useCallback(() => {
    setIsOpen(false);
    setContent(null);
  }, []);

  const cancelRightDrawer = useCallback(() => {
    onCancel();
    closeRightDrawer();
  }, [closeRightDrawer, onCancel]);

  const openRightDrawer = useCallback(({ content, width, title, footer, onCancel }) => {
    setWidth(width);
    setContent(content);
    setFooter(footer);
    setIsOpen(true);
    setTitle(title);
    if (isFunction(onCancel)) setOnCancel(() => onCancel);
  }, []);

  return (
    <DrawerStateContext.Provider
      value={{
        isOpen,
        content,
        footer,
        onCancel,
        width,
        title,
      }}
    >
      <DrawerContext.Provider
        value={{
          setIsOpen,
          setContent,
          setFooter,
          setOnCancel,
          setWidth,
          closeRightDrawer,
          cancelRightDrawer,
          openRightDrawer,
        }}
      >
        {children}
      </DrawerContext.Provider>
    </DrawerStateContext.Provider>
  );
};
