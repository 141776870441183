export default {
  id: 'entities',
  moduleId: 'entities',
  description: 'Entities',
  modules: [
    {
      id: 'ps-entities-metrics',
      icon: 'chart-bar',
      description: 'Metrics',
      moduleId: 'ps-entities-metrics',
      feature: {
        id: 'FE-1487',
        description: 'Entity Metrics',
        url: 'https://jira.commandalkon.com/browse/FE-1487',
      },
    },
    {
      id: 'ps-entities-search',
      icon: 'search',
      description: 'Search Entities',
      moduleId: 'ps-entities-search',
      feature: {
        id: 'FE-1487',
        description: 'Entity Search',
        url: 'https://jira.commandalkon.com/browse/FE-1487',
      },
    },
  ],
};
