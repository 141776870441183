import React, { useEffect, useState, Suspense } from 'react';
import ModuleSpinner from './ModuleSpinner';
import { useUserContext } from '../contexts/user/UserContextProvider';

export const Module = ({ module = {}, ...props }) => {
  const [children, setChildren] = useState(null);
  const { hasPermission, permissions } = useUserContext();

  useEffect(() => {
    if (module.permissions && permissions) {
      const cxPermissions = {
        ...permissions(module.id),
        has: hasPermission(module.id),
      };

      React.Children.only(props.children);

      setChildren(
        React.Children.map(props.children, child => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              ...child.props,
              cxPermissions,
              moduleConfig: module,
            });
          }
          return child;
        })
      );
    }
  }, [props.children, module, permissions, hasPermission]);

  return !children ? null : (
    <Suspense fallback={<ModuleSpinner module={module} />}>{children}</Suspense>
  );
};
