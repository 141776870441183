import React, { useCallback, useEffect, useState } from 'react';
import { Cascader } from 'antd';
import { useIntl } from 'react-intl';

export default ({ entity, onSelected }) => {
  const [supplierFilters, setSupplierFilters] = useState(null);
  const [selected, setSelected] = useState(null);
  const [defaultSelected, setDefaultSelected] = useState(null);

  const { formatNumber } = useIntl();

  const notify = useCallback(
    options => {
      if (onSelected && Array.isArray(options) && options.length >= 2) {
        onSelected({
          Source: options[0],
          Supplier: options[1].substr(0, options[1].indexOf('::')),
        });
      }
    },
    [onSelected]
  );

  useEffect(() => {
    notify(selected);
  }, [notify, selected]);

  useEffect(() => {
    notify(defaultSelected);
  }, [defaultSelected, notify]);

  const selectedOptions = useCallback((options = []) => {
    setSelected(options);
  }, []);

  const suppliersForSource = useCallback(
    (Suppliers = []) => {
      return Suppliers.map((Supplier, index) => {
        return {
          value: `${Supplier.SupplierId}::${index}`, // Adding index to create unique 'key'
          label: `${Supplier.Supplier} (${formatNumber(Supplier.TicketCount)})`,
          isLeaf: true,
          disabled: Supplier.TicketCount < 1,
        };
      });
    },
    [formatNumber]
  );

  const sourceOptions = useCallback(
    Sources => {
      const productionCount = Sources.production.TicketCount || 0;
      const sharedCount = Sources.shared.TicketCount || 0;
      const shipSuppliers = suppliersForSource(Sources.production.Suppliers);
      const shareSuppliers = suppliersForSource(Sources.shared.Suppliers);

      if (productionCount > 0 && shipSuppliers.length > 0) {
        setDefaultSelected(['production', shipSuppliers[0].value]);
      } else if (sharedCount > 0 && shareSuppliers.length > 0) {
        setDefaultSelected(['shared', shareSuppliers[0].value]);
      }

      return [
        {
          value: 'production',
          label: `Shipped (${formatNumber(productionCount)})`,
          isLeaf: false,
          disabled: productionCount < 1,
          children: shipSuppliers,
        },
        {
          value: 'shared',
          label: `Received (${formatNumber(sharedCount)})`,
          isLeaf: false,
          disabled: sharedCount < 1,
          children: shareSuppliers,
        },
      ];
    },
    [formatNumber, suppliersForSource]
  );

  const summarizeEntity = useCallback(
    summary => {
      summary && summary.Sources && setSupplierFilters(sourceOptions(summary.Sources));
    },
    [sourceOptions]
  );

  useEffect(() => {
    entity && summarizeEntity(entity);
  }, [entity, summarizeEntity]);

  return (
    <div style={{ width: '100%', paddingRight: '10px' }}>
      {supplierFilters && (
        <Cascader
          style={{ float: 'left', width: '100%' }}
          options={supplierFilters}
          onChange={selectedOptions}
          defaultValue={defaultSelected}
          changeOnSelect
          autoFocus
          placeholder="Select View..."
        />
      )}
    </div>
  );
};
