import { useCallback, useEffect, useState } from 'react';
import { isFunction } from 'lodash';
import { useViewport } from '../../../../app-layout/Viewport';
import { MODE, FIXED, DRAWER_BREAKPOINT } from '../../constants';

export const useSidebar = () => {
  const { width } = useViewport();

  const [mode, setMode] = useState(MODE.DRAWER);
  const [fixed, setFixed] = useState(FIXED.FULL);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleIcon = useCallback(() => {
    setFixed(s => (fixed === FIXED.FULL ? FIXED.ICON : FIXED.FULL));
  }, [fixed]);

  const collapseToIconView = useCallback(() => {
    setFixed(FIXED.ICON);
  }, []);

  const open = useCallback(() => {
    setFixed(FIXED.FULL);
  }, []);

  const toggleDrawer = useCallback(() => {
    if (mode === MODE.DRAWER) {
      setIsDrawerOpen(s => !s);
    }
  }, [mode]);

  useEffect(() => {
    if (mode === 'drawer') {
      setIsDrawerOpen(false);
    }
  }, [mode]);

  const getMode = useCallback(() => {
    return width <= DRAWER_BREAKPOINT ? MODE.DRAWER : MODE.FIXED;
  }, [width]);

  useEffect(() => {
    setMode(getMode(width));
  }, [width, getMode]);

  const onItemSelection = useCallback(
    fn => {
      if (mode === 'drawer') {
        setIsDrawerOpen(false);
      }
      if (isFunction(fn)) {
        fn();
      }
    },
    [mode, setIsDrawerOpen]
  );
  return {
    mode,
    fixed,
    isDrawerOpen,
    toggleIcon,
    collapseToIconView,
    open,
    toggleDrawer,
    getMode,
    onItemSelection,
  };
};

export default useSidebar;
