import { useCallback, useEffect, useRef, useState } from 'react';
import { useSidebarContext } from '../sidebar-context';

const useTooltip = topOffset => {
  const [visible, setVisible] = useState();
  const elementRef = useRef();
  const [style, setStyle] = useState({
    position: 'fixed',
    opacity: 0,
    transition: 'opacity .3s',
    zIndex: '9999',
  });
  const { mode, fixed } = useSidebarContext();

  useEffect(() => {
    setTimeout(() => {
      setStyle(s => {
        return { ...s, opacity: visible ? 1 : 0 };
      }, 500);
    });
  }, [visible]);

  const showTooltip = useCallback(() => {
    if (mode === 'drawer' || fixed === 'fixed-full') return;

    const dimensions = elementRef.current.getBoundingClientRect();

    setStyle(s => {
      return {
        ...s,
        left: '65px',
        top: dimensions.top + topOffset,
      };
    });

    setVisible(true);
  }, [elementRef, mode, fixed, topOffset]);

  const hideTooltip = useCallback(() => {
    setVisible(false);
  }, []);

  return { style, visible, showTooltip, hideTooltip, elementRef };
};

export default useTooltip;
