import 'codemirror/addon/display/autorefresh';
import 'codemirror/addon/fold/brace-fold';
import 'codemirror/addon/fold/comment-fold';
import 'codemirror/addon/fold/foldcode';
import 'codemirror/addon/fold/foldgutter';
import 'codemirror/addon/fold/foldgutter.css';
import 'codemirror/addon/fold/indent-fold';
import 'codemirror/addon/fold/markdown-fold';
import 'codemirror/addon/fold/xml-fold';

import 'codemirror/lib/codemirror';
import 'codemirror/lib/codemirror.css';
import 'codemirror/mode/css/css';
import 'codemirror/mode/htmlmixed/htmlmixed';
import 'codemirror/mode/javascript/javascript';
import 'codemirror/mode/sql/sql';
import 'codemirror/mode/xml/xml';
import 'codemirror/theme/eclipse.css';
import 'codemirror/theme/material.css';

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import propTypes from 'prop-types';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import style from './style';

export const CodeEditor = ({
  defaultValue = '',
  value = '',
  mode = 'javascript',
  getValue,
  disabled = false,
  showLineNumbers = true,
  lineWrapping = false,
  width,
  className,
}) => {
  const editorRef = useRef();
  const localValue = useRef(defaultValue || value);
  const onChange = (editor, data, value) => {
    localValue.current = value;
    getValue && getValue(value);
  };

  useEffect(() => {
    if (editorRef.current) {
      const incomingValue = defaultValue || value;
      const matches = incomingValue === localValue.current;
      if (!matches) {
        editorRef.current.setValue(incomingValue);
        editorRef.current.setSize('100%', '100%');
        editorRef.current.refresh();
      }
    }
  }, [value, defaultValue]);

  return (
    <div className={cn('codemirror-wrapper', className)}>
      <CodeMirror
        editorDidMount={editor => {
          editor.setValue(defaultValue || value);
          editorRef.current = editor;

          editor.setSize('100%', '100%');
          editor.refresh();
        }}
        options={{
          mode: mode,
          autoRefresh: true,
          lineNumbers: showLineNumbers,
          foldGutter: !disabled,
          lineWrapping,
          gutters: !disabled ? ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'] : [],
          fold: 'brace',
          fixedGutter: true,
          tabSize: 4,
          indentWithTabs: true,
          readOnly: disabled,
          extraKeys: {
            'Shift-Tab': 'indentLess',
            Tab: 'indentMore',
          },
          width: (width && `${width}px`) || '100%',
          height: '100%',
        }}
        onChange={onChange}
      />
    </div>
  );
};

CodeEditor.propTypes = {
  defaultValue: propTypes.string,
  value: propTypes.string,
  mode: propTypes.oneOfType([propTypes.string, propTypes.object]),
  getValue: propTypes.func,
  disabled: propTypes.bool,
  showLineNumbers: propTypes.bool,
  lineWrapping: propTypes.bool,
  width: propTypes.string,
  className: propTypes.string,
};

export default styled(CodeEditor)`
  ${style}
`;
