import React from 'react';
import i18n from './i18n';

const component = React.lazy(() => import('./view'));

export default {
  id: 'pa-dashboard',
  moduleId: 'pa-dashboard',
  component,
  i18n,
  permissions: {},
};
