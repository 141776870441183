import React from 'react';
import { Route, useRouteMatch, useParams } from 'react-router-dom';
import { find, get } from 'lodash';
import { useAppsConfigContext } from '../components/app-config/AppsConfigContext';
import Result, { STATUS } from './result';
import { ModulesContextProvider } from '../contexts/modules';
import CacheLastVisitedModule from './CacheLastVisitedModule';
import AppPermission from './app-permission';

const findModule = (app, level, moduleId) => {
  let module = null;
  const targetPath = `/app/${app.id}/${level}/${moduleId}`;

  const searchModules = modules => {
    modules.forEach(m => {
      if (m.modules) {
        searchModules(m.modules);
      } else {
        if (m.moduleConfig.path === targetPath) {
          module = m;
        }
      }
    });
  };

  searchModules(app.modules);

  return module;
};

const ModuleLoader = () => {
  const { appId, level, moduleId } = useParams();
  const { appsConfig } = useAppsConfigContext();

  const app = find(appsConfig, app => app.id === appId);

  if (!app) {
    return null;
  }

  const module = findModule(app, level, moduleId);

  if (module && !module.hidden) {
    const Component = get(module, 'moduleConfig.component');

    if (Component) {
      return (
        <CacheLastVisitedModule>
          <Component {...module.params} module={module.moduleConfig || module} />
        </CacheLastVisitedModule>
      );
    }
    return <Result module={module} title="notImplemented" status={STATUS.WARNING} />;
  }
  return <Result module={{ moduleId }} title="notFound" status={STATUS.ERROR} />;
};

const Module = () => {
  const { appId } = useParams();
  const { path } = useRouteMatch();

  return (
    <AppPermission appId={appId}>
      <ModulesContextProvider>
        <Route path={`${path}/:level/:moduleId`} component={ModuleLoader} />
      </ModulesContextProvider>
    </AppPermission>
  );
};

export default Module;
