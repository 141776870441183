// import API from './modules/api/nav.config';
import data from './modules/data/nav.config';
import dashboard from './modules/dashboard/nav.config';
import integrations from './modules/integrations/nav.config';
import sdlc from './modules/sdlc/nav.config.js';

import { MOUNT_POINT } from '../../core/constants';

export default {
  id: 'developer',
  moduleId: 'developer',
  mountPoint: MOUNT_POINT.APP_SWITCHER,
  modules: [dashboard, data, integrations, sdlc],
};
