import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TotalByMonth from './total-by-month';
import Widget from '../../../../../../../../components/widget';
import FilterDrawer from '../filter-drawer';
import SelectFieldsButton from './total-by-month/SelectFieldsButton';
import getTicketsByMonth from './total-by-month/getTicketsByMonth';
import { Col, Row } from 'antd';
import useEntityValues from '../useEntityValues';
import TicketStatus from './ticket-status';
import moment from 'moment';
import { useHistory, useRouteMatch } from 'react-router-dom';

export default ({ entityRef, dateFilters }) => {
  const [fieldFilters, setFieldFilters] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [statuses, setStatuses] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const history = useHistory();
  const { url } = useRouteMatch();

  const { getStatusValues, customerStatuses } = useEntityValues();

  const refresh = useCallback(
    (statuses, fields = {}) => {
      setLoading(true);

      const additionalFilters = {
        ...fields,
      };

      if (statuses && Array.isArray(statuses)) {
        additionalFilters.customerStatus = statuses;

        setStatusFilter(statuses);
      }

      if (dateFilters) {
        dateFilters &&
          getTicketsByMonth(entityRef, dateFilters, additionalFilters)
            .then(({ Items }) => {
              setData(Items);
            })
            .finally(() => setLoading(false));
      }
    },
    [dateFilters, entityRef]
  );

  const selectedOptionsChanged = useCallback(
    options => {
      setFieldFilters({ ...options });

      setOpen(false);

      refresh(statusFilter, options);
    },
    [refresh, statusFilter]
  );

  useEffect(() => {
    getStatusValues(entityRef, dateFilters);
  }, [dateFilters, entityRef, getStatusValues]);

  useEffect(() => {
    customerStatuses &&
      setStatuses(
        customerStatuses.map(s => {
          return {
            name: s.ID.substr(0, s.ID.indexOf(' ')),
            value: s.TicketCount,
          };
        })
      );
  }, [customerStatuses]);

  useEffect(() => {
    statusFilter && console.log(statusFilter);
  }, [statusFilter]);

  useEffect(() => {
    dateFilters && refresh();
  }, [dateFilters, refresh]);

  const onClickStatus = useCallback(
    e => {
      e && e.name && refresh([e.name], fieldFilters);
    },
    [fieldFilters, refresh]
  );

  const onClickMonth = useCallback(
    my => {
      if (my && my.length === 5) {
        const StartDt = `${moment(`20${my.substr(3)}-${my.substr(0, 2)}-01`)
          .toISOString()
          .substr(0, 19)}Z`;
        const EndDt = `${moment(StartDt).endOf('month').toISOString().substr(0, 19)}Z`;

        history.push(`${url}/tickets`, { StartDt, EndDt });
      }
    },
    [history, url]
  );

  const title = useMemo(
    () => `${dateFilters.Source === 'production' ? 'Shipments' : 'Receipts'} By Month`,
    [dateFilters.Source]
  );

  return (
    <>
      <FilterDrawer
        entityRef={entityRef}
        dateFilters={dateFilters}
        open={open}
        onClose={() => setOpen(false)}
        onApply={selectedOptionsChanged}
      />
      <Widget>
        <div style={{ padding: '10px' }}>
          {loading && <span style={{ marginRight: '10px' }} className="fa fa-sync fa-spin" />}
          {statusFilter && <span>{`${statusFilter[0]} `}</span>}
          <span>{title}</span>
          <SelectFieldsButton onSelect={() => setOpen(true)} />
        </div>
        <Row>
          <Col span={10}>
            <TicketStatus statuses={statuses} onClick={onClickStatus} />
          </Col>
          <Col span={14}>
            <TotalByMonth onClick={onClickMonth} data={data} />
          </Col>
        </Row>
      </Widget>
    </>
  );
};
