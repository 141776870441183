export default {
  refresh: 'Refresh',
  platformTicketAnalysis: 'Platform Ticket Analysis',
  title: 'Ticket Summary',
  totalTickets: 'Total Tickets',
  totalProduced: 'Total Shipped',
  totalShared: 'Total Received',
  concreteDelivered: 'Concrete Delivered (CY)',
  aggregateDelivered: 'Aggregate Delivered (TN)',
  apply: 'Apply',
  search: 'Search',
  openFilters: 'More...',
};
