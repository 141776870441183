import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { Result as AntResult, Tag } from 'antd';
import { useIntl } from 'react-intl';
import style from './style';

//TODO: Implement all statuses & move to UI folder.
export const STATUS = {
  // SUCCESS: 'success',
  ERROR: 'error',
  // INFO: 'info',
  WARNING: 'warning',
  // 404: '404',
  // 403: '403',
  // 500: '500',
};

const AdditionalInfo = ({ module }) => {
  return !module ? null : (
    <div className="additional-info">
      <div>Module ID: {module.moduleId}</div>
      {module.feature && (
        <div>
          <div className="feature-url">
            <Tag>
              <a href={module.feature.url} target="_blank" rel="noopener noreferrer">
                {module.feature.id} - {module.feature.description}
              </a>
            </Tag>
          </div>
        </div>
      )}
    </div>
  );
};

const Result = ({ className, status, title, module, appId }) => {
  const intl = useIntl();

  const intlTitle = intl.formatMessage({ id: title });

  return (
    <div className={cn(className)}>
      <AntResult
        status={status}
        title={intlTitle}
        extra={<AdditionalInfo appId={appId} module={module} />}
      />
    </div>
  );
};

export default styled(Result)(style);
