import { useCallback } from 'react';
import useApiCall from '../use-api-call';
import SpecialPromise from './Promise';
import { useNotifyContext } from '../../core/contexts/notifications';

const useHandleApiCall = fn => {
  const { error } = useNotifyContext();

  const [call, busy, cancel] = useApiCall(fn);

  const apiCall = useCallback(
    (...args) => {
      return call(...args).catch(e => {
        error(e);
        return new SpecialPromise();
      });
    },
    [call, error]
  );

  return [apiCall, busy, cancel];
};

export default useHandleApiCall;
