import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import propTypes from 'prop-types';
import { InputNumber as AntInputNumber } from 'antd';
import style from './style';

const Input = ({ className, innerRef, password, ...props }) => {
  return (
    <AntInputNumber
      type={password ? 'password' : 'text'}
      className={cn('cx-input-number', className)}
      {...props}
      ref={innerRef}
    />
  );
};

Input.propTypes = {
  className: propTypes.string,
  innerRef: propTypes.string,
  password: propTypes.oneOfType([propTypes.string, propTypes.bool]),
};

export default styled(Input)`
  ${style}
`;
