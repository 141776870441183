import { css } from 'styled-components';

export default css`
  .tag-input {
    width: 150px;
    display: inline;
  }
  .ant-tag {
    background: var(--tagBackgroundColor);
    color: var(--tagTextColor);
    border-color: var(--tagBorderColor);
  }
`;
