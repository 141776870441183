import React, { useContext } from 'react';

export const EnvironmentContext = React.createContext();

export const useEnvironmentContext = () => {
  const context = useContext(EnvironmentContext);
  if (!context) {
    throw new Error(
      `useEnvironmentContext cannot be rendered outside of the EnvironmentContext context provider`
    );
  }
  return context;
};

export const Environment = ({ children, environment }) => {
  return <EnvironmentContext.Provider value={environment}>{children}</EnvironmentContext.Provider>;
};
