import React from 'react';
import noContextError from '../noContextError';
import { useHandoff } from './useHandoff';
import { useQueryLogsCache } from 'core/contexts/modules/useQueryLogsCache';

export const ModulesContext = React.createContext();

export const useModulesContext = () => {
  const context = React.useContext(ModulesContext);

  if (!context) {
    noContextError('ModulesContext');
  }

  return context;
};

export const ModulesContextProvider = ({ children }) => {
  const queryLogsCache = useQueryLogsCache();
  const moduleHandoff = useHandoff();

  return (
    <ModulesContext.Provider value={{ moduleHandoff, queryLogsCache }}>
      {children}
    </ModulesContext.Provider>
  );
};
