import { SUPPORTED_LANGUAGES } from '../index';

import { flattenMessages } from './flattenMessages';

export const consolidateMessages = ({
  globalMessages,
  moduleMessages,
  appMessages,
  themeMessages,
}) => {
  // stub out consolidatedMessages with keys matching all supported locales
  const consolidatedMessages = SUPPORTED_LANGUAGES.reduce((acc, locale) => {
    acc[locale.id] = {};
    return acc;
  }, {});

  // Merge globalMessages and each moduleMessage into consolidatedMessages
  Object.keys(consolidatedMessages).forEach(key => {
    consolidatedMessages[key] = globalMessages[key];

    Object.keys(moduleMessages).forEach(moduleKey => {
      if (consolidatedMessages[key] && moduleMessages[moduleKey]) {
        consolidatedMessages[key][moduleKey] = moduleMessages[moduleKey][key];
      } else {
        console.error(`[I18N] Module '${moduleKey}': No translations found for locale '${key}'`);
      }
    });

    Object.keys(appMessages).forEach(appKey => {
      if (consolidatedMessages[key]) {
        consolidatedMessages[key][appKey] = appMessages[appKey][key];
      } else {
        console.error(`[I18N] App '${appKey}': No translations found for locale '${key}'`);
      }
    });

    Object.keys(themeMessages).forEach(themeKey => {
      if (consolidatedMessages[key]) {
        consolidatedMessages[key][themeKey] = themeMessages[themeKey][key];
      } else {
        console.error(`[I18N] Theme '${themeKey}': No translations found for locale '${key}'`);
      }
    });
  });

  // Flatten messages
  Object.keys(consolidatedMessages).forEach(key => {
    if (consolidatedMessages[key]) {
      consolidatedMessages[key] = flattenMessages(consolidatedMessages[key]);
    }
  });

  return consolidatedMessages;
};
