import React from 'react';
import converter from 'json-2-csv';
import { Button } from '../';

const Exporter = ({ className, data, fileName, type = 'JSON' }) => {
  const exportToCSV = data => {
    let objectKeys = {};

    let cleanedData = [];

    for (const item of data) {
      for (const key of Object.keys(item)) {
        const value = item[key];
        if (value && typeof value === 'object') {
          objectKeys[key] = true;
        }
      }
    }

    for (const item of data) {
      const newItem = { ...item };
      for (const key of Object.keys(objectKeys)) {
        if (newItem[key]) {
          delete newItem[key];
        }
      }
      cleanedData.push(newItem);
    }

    converter.json2csv(cleanedData, (err, csv) => {
      if (err) {
        throw err;
      }
      // print CSV string
      download(`${fileName}-${Date.now()}.csv`, csv, 'csv');
    });
  };

  const download = (filename, text, type) => {
    const element = document.createElement('a');
    let mimeString = 'data:text/json;charset=utf-8;,';
    let href = mimeString + encodeURIComponent(JSON.stringify(text, null, 2));
    if (type === 'csv') {
      mimeString = 'data:text/csv;charset=utf-8,';
      href = mimeString + encodeURIComponent(text);
    }
    element.setAttribute('href', href);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  if (type === 'CSV') {
    return (
      <Button
        className={className}
        onClick={() => exportToCSV(data)}
        icon={<i className="fa fa-download" />}
        label={'csv'}
      />
    );
  }

  return (
    <Button
      className={className}
      onClick={() => download(`${fileName}-${Date.now()}.json`, data, 'json')}
      icon={<i className="fa fa-download" />}
      label={'json'}
    />
  );
};

export default Exporter;
