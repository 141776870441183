import React from 'react';
import { Col, Row } from 'antd';
import TotalWidget from './TotalWidget';
import ReceivedWidget from './ReceivedWidget';
import ConnectedWidget from './ConnectedWidget';

export default ({ metrics }) => {
  return (
    <Row>
      <Col span={8}>
        <TotalWidget metrics={metrics} />
      </Col>
      <Col span={8} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        <ReceivedWidget metrics={metrics} />
      </Col>
      <Col span={8}>
        <ConnectedWidget metrics={metrics} />
      </Col>
    </Row>
  );
};
