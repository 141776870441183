import React, { useCallback } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import { QueryLogContextProvider, useQueryLogContext } from './useQueryLogContext';
import Table from 'components/tables/table';
import AuditLogHeader from './AuditLogHeader';
import style from './style';
import { useDrawer } from 'components/drawer/useDrawer';
import Spinner from 'components/spinner';
import ItemResults from './item-results';
import DrawerTitleWithSize from '../../explorer/view/components/data-results/DrawerTitleWithSize';
import { useOnMount } from 'util/useOnMount';
import { useModulesContext } from 'core/contexts/modules';
import { useFiltersForm } from './useFiltersForm';

const QueryLogs = className => {
  const {
    clearHeaderFilters,
    lookupResults,
    columns,
    entityList,
    modalLoading,
    showModal,
    setShowModal,
    statementNameList,
    refreshTable,
    userNames,
    containerNames,
    executeQuery,
    results,
    rowCount,
    loading,
    activeRow,
  } = useQueryLogContext();

  const { Drawer } = useDrawer();
  const filters = useFiltersForm();
  const { queryLogsCache } = useModulesContext();

  useOnMount(() => {
    if (!queryLogsCache.results) {
      executeQuery(filters.filterForm);
    }
  });

  const cachePageNumber = useCallback(
    (pageNumber, pageSize) => {
      queryLogsCache.setPagination({ pageNumber, pageSize });
    },
    [queryLogsCache]
  );

  return (
    <div className={cn('audit-logs', className)}>
      <AuditLogHeader
        filters={filters}
        clearHeaderFilters={clearHeaderFilters}
        executeQuery={executeQuery}
        entities={entityList}
        statementNames={statementNameList}
        rowCount={rowCount}
        data={results}
        refreshTable={refreshTable}
        loading={loading}
        userNames={userNames}
        containerNames={containerNames}
      />
      <div className="audit-log-body">
        <Spinner spinning={loading} size="large">
          <Table
            className="table-audit-logs"
            columns={columns}
            dataSource={results || []}
            showSort
            isCompact
            rowClassName={record => cn({ active: record.id === activeRow })}
            pagination={{
              onChange: cachePageNumber,
              defaultCurrent: queryLogsCache.pagination.pageNumber || 1,
              defaultPageSize: queryLogsCache.pagination.pageSize || 25,
              pageSizeOptions: [10, 25, 50, 100],
            }}
          />
        </Spinner>
        {showModal && (
          <Drawer
            title={<DrawerTitleWithSize title="audit-logs.item-details" data={lookupResults} />}
            width={800}
            onClose={() => setShowModal(false)}
            visible={showModal}
            drawerStyle={{ display: 'flex' }}
            style={{ display: 'flex' }}
            destroyOnClose
          >
            <ItemResults lookupResults={lookupResults} modalLoading={modalLoading} />
          </Drawer>
        )}
      </div>
    </div>
  );
};

const AuditLogs = ({ className }) => {
  return (
    <QueryLogContextProvider>
      <div className={cn('content', className)}>
        <QueryLogs />
      </div>
    </QueryLogContextProvider>
  );
};

export default styled(AuditLogs)`
  ${style}
`;
