import Developer from './';
import i18n from './i18n';

export default {
  id: 'data-explorer',
  moduleId: 'data-explorer',
  i18n,
  disabled: false,
  autoCloseSidebar: true,
  component: Developer,
  permissions: {},
};
