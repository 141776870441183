import React, { useCallback, useRef, useState } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import style from './style';
import { Input, Tag, Tooltip } from 'antd';

const TagGroup = ({ className, tags = [], onChange, readOnly = false }) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');

  const inputRef = useRef();
  const editInputRef = useRef();

  const handleEditInputChange = useCallback(
    e => {
      setEditInputValue(e.target.value);
    },
    [setEditInputValue]
  );

  const handleEditInputConfirm = useCallback(() => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;

    onChange && onChange(newTags);
    setEditInputIndex(-1);
    setEditInputValue('');
  }, [onChange, setEditInputIndex, setEditInputValue, tags, editInputValue, editInputIndex]);

  const handleInputChange = useCallback(
    e => {
      setInputValue(e.target.value);
    },
    [setInputValue]
  );

  const handleInputConfirm = useCallback(() => {
    let newTags = tags;
    if (inputValue && tags.indexOf(inputValue) === -1) {
      newTags = [...tags, inputValue];
    }

    onChange && onChange(newTags);
    setInputVisible(false);
    setInputValue('');
  }, [inputValue, tags, setInputVisible, setInputValue, onChange]);

  const showInput = useCallback(() => {
    if (!readOnly) {
      setInputVisible(true);
    }
  }, [setInputVisible, readOnly]);

  const handleClose = useCallback(
    removedTag => {
      const newTags = tags.filter(tag => tag !== removedTag);
      onChange && onChange(newTags);
    },
    [tags, onChange]
  );

  return (
    <div className={cn(className)}>
      {tags.map((tag, index) => {
        if (editInputIndex === index) {
          return (
            <Input
              ref={ref => {
                editInputRef.current = ref;
                if (ref) {
                  ref.focus();
                }
              }}
              key={tag}
              size="small"
              className="tag-input"
              value={editInputValue}
              onChange={handleEditInputChange}
              onBlur={handleEditInputConfirm}
              onPressEnter={handleEditInputConfirm}
            />
          );
        }

        const isLongTag = tag.length > 20;

        const tagElem = (
          <Tag
            className={readOnly ? '' : 'edit-tag'}
            key={tag}
            closable={!readOnly}
            onClose={() => handleClose(tag)}
          >
            <span
              onDoubleClick={e => {
                if (!readOnly) {
                  setEditInputIndex(index);
                  setEditInputValue(tag);
                  e.preventDefault();
                }
              }}
            >
              {isLongTag ? `${tag.slice(0, 20)}...` : tag}
            </span>
          </Tag>
        );
        return isLongTag ? (
          <Tooltip title={tag} key={tag}>
            {tagElem}
          </Tooltip>
        ) : (
          tagElem
        );
      })}
      {inputVisible && (
        <Input
          ref={ref => {
            inputRef.current = ref;
            if (ref) {
              ref.focus();
            }
          }}
          type="text"
          size="small"
          className="tag-input"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && !readOnly && (
        <Tag className="site-tag-plus" onClick={showInput}>
          <i className={'fas fa-plus'} /> New Tag
        </Tag>
      )}
    </div>
  );
};

export default styled(TagGroup)(style);
