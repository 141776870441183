import { css } from 'styled-components';

export default css`
  &&& {
    &.warning {
      .ant-badge-count {
        background-color: var(--warning);
        color: white;
      }
      .ant-badge-status-dot {
        background-color: var(--warning);
      }
      .ant-badge-status-text {
        color: var(--base-text-color);
      }
    }
    &.info {
      .ant-badge-count {
        background-color: var(--info);
        color: white;
      }
      .ant-badge-status-dot {
        background-color: var(--info);
      }
      .ant-badge-status-text {
        color: var(--base-text-color);
      }
    }
    &.error {
      .ant-badge-count {
        background-color: var(--error);
        color: white;
      }
      .ant-badge-status-dot {
        background-color: var(--error);
      }
      .ant-badge-status-text {
        color: var(--base-text-color);
      }
    }
    &.success {
      .ant-badge-count {
        background-color: var(--success);
        color: white;
      }
      .ant-badge-status-dot {
        background-color: var(--success);
      }
      .ant-badge-status-text {
        color: var(--base-text-color);
      }
    }
  }
`;
