export const moduleDefinitions = {};

// HOC that iterates over modules
export const ModuleDefinitions = ({ children, mountPoint }) => {
  return Object.entries(moduleDefinitions)
    .filter(([key, module]) => module.mountPoint === mountPoint)
    .map(([key, module]) => children(module));
};

const moduleCache = {};

function importAll(r) {
  r.keys().forEach(key => {
    return (moduleCache[key] = r(key));
  });
}

importAll(require.context('../../apps', true, /module.config.js$/));

Object.entries(moduleCache).forEach(([key, value]) => {
  const def = value.default;

  if (!def.hidden) {
    moduleDefinitions[key] = { ...def, type: 'module' };
  }
});
