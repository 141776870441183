import { css } from 'styled-components';

export default css`
  font-size: ${({ theme }) => `${24 * theme.fontSizeMultiplier}px`};
  cursor: pointer;
  color: var(--notification-enabled-icon-color);
  transition: all 0.3s;
  &.disabled {
    color: var(--notification-disabled-icon-color);
    cursor: not-allowed;
  }
`;
