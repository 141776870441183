const copyToClipboard = data => {
  const temporaryInput = document.createElement('textarea');
  temporaryInput.value = data;
  document.body.appendChild(temporaryInput);
  temporaryInput.select();
  document.execCommand('copy');
  document.body.removeChild(temporaryInput);
};

export default copyToClipboard;
