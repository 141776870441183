import React, { useCallback, useContext, useEffect, useState } from 'react';
import { moduleDefinitions } from '../../modules';
import { get, values } from 'lodash';

const PLATFORM_ADMIN = 'platform-admin';

export const UserContext = React.createContext();

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(
      `useUserContext cannot be rendered outside of the UserContext context provider`
    );
  }
  return context;
};

const getEmailUserName = (emailAddress = '') => {
  const userName = emailAddress.split('@');
  return userName[0];
};

const granularPermissions = [];

export const UserProvider = React.memo(({ user, onAuthStateChange, children }) => {
  const [userName, setUserName] = useState();
  const [userPermissions, setUserPermissions] = useState([]);
  const [resolvedPermissions, setResolvedPermissions] = useState();

  const userGroups = React.useMemo(
    () => get(user, 'signInUserSession.idToken.payload.cognito:groups', []),
    [user]
  );

  useEffect(() => {
    // User has just logged in.
    if (user) {
      const name = get(user, 'signInUserSession.idToken.payload.name');
      const firstName = get(user, 'signInUserSession.idToken.payload.given_name', '');
      const lastName = get(user, 'signInUserSession.idToken.payload.family_name', '');

      const fullName = `${firstName} ${lastName}`.trim();

      const userName = get(user, 'signInUserSession.idToken.payload.username');
      const email = get(user, 'signInUserSession.idToken.payload.email');
      const emailUserName = getEmailUserName(email);

      setUserName(fullName || name || userName || emailUserName);
      setUserPermissions(granularPermissions);
    }
  }, [user]);

  useEffect(() => {
    if (userPermissions.length > 0) {
      const modulePermissions = values(moduleDefinitions);

      //TODO: comment/explain and possibly refactor

      const stepOne = modulePermissions.reduce((acc, def) => {
        acc[def.id] = def.permissions;
        return acc;
      }, {});

      const stepTwo = Object.keys(stepOne || {}).reduce((acc, moduleId) => {
        acc[moduleId] = Object.keys(stepOne[moduleId] || {}).reduce((acc, permissionKey) => {
          acc[permissionKey] = {
            permission: stepOne[moduleId][permissionKey],
            granted: userPermissions.includes(stepOne[moduleId][permissionKey]),
          };
          return acc;
        }, {});
        return acc;
      }, {});

      setResolvedPermissions(stepTwo);
    }
  }, [userPermissions]);

  const permissions = useCallback(
    moduleId => {
      return resolvedPermissions && resolvedPermissions[moduleId];
    },
    [resolvedPermissions]
  );

  const hasPermission = useCallback(
    moduleId => permission => {
      if (resolvedPermissions) {
        const resolvedPermission = resolvedPermissions[moduleId][permission];
        if (!resolvedPermission) {
          throw new Error(
            `Permission '${permission}' has not been defined in ${moduleId}/module-config.`
          );
        }
        return resolvedPermission.granted === true;
      }
    },
    [resolvedPermissions]
  );

  const hasAppPermission = useCallback(
    app => app.unrestricted || userGroups.includes(PLATFORM_ADMIN) || userGroups.includes(app.id),
    [userGroups]
  );

  const isPlatformAdmin = useCallback(() => userGroups.includes(PLATFORM_ADMIN), [userGroups]);

  return (
    <UserContext.Provider
      value={{
        user,
        userName,
        userUID: get(user, 'attributes.sub'),
        hasPermission,
        isPlatformAdmin,
        permissions,
        onAuthStateChange,
        hasAppPermission,
      }}
    >
      {children}
    </UserContext.Provider>
  );
});
