import { useHistory, useRouteMatch } from 'react-router-dom';
import { find, get } from 'lodash';
import { cache } from '../../../../util';
import useAppsConfig from 'core/components/app-config/useAppsConfig';
import { useEffect, useMemo } from 'react';

const findFirstModule = ({ appsConfig, appId }) => {
  const app = find(appsConfig, a => a.id === appId);
  let module = null;

  const searchModules = modules => {
    if (modules[0].modules) {
      searchModules(modules[0].modules);
    } else {
      module = modules[0].moduleConfig;
    }
  };

  searchModules(app.modules);

  return module;
};

const RedirectToLastVisitedModule = ({ children }) => {
  const match = useRouteMatch('/app/:appId');
  const history = useHistory();
  const { appsConfig } = useAppsConfig();

  const appId = useMemo(() => {
    return get(match, 'params.appId');
  }, [match]);

  const cachedModulePath = useMemo(() => {
    return cache.getItem(`cx.lastVisitedModule.${appId}`);
  }, [appId]);

  useEffect(() => {
    if (appsConfig) {
      if (match.isExact) {
        if (cachedModulePath) {
          history.replace(cachedModulePath);
        } else {
          const firstModule = findFirstModule({ appsConfig, appId });
          history.replace(firstModule.path);
        }
      }
    }
  }, [appId, appsConfig, cachedModulePath, history, match]);

  return children;
};
export default RedirectToLastVisitedModule;
