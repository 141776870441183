import React, { useMemo } from 'react';
import { ViewHeader } from 'components';
import TotalWidgets from './total-widgets';
import PrimaryFilters from '../primary-filters';

export default ({ entity, entityRef, filtersChanged, summary = {}, loading = false }) => {
  const sources = useMemo(
    () =>
      (entity && entity.Sources) || {
        production: { TicketCount: 0 },
        shared: { TicketCount: 0 },
      },
    [entity]
  );

  return (
    <div>
      <ViewHeader>{summary && summary.Name}</ViewHeader>
      <TotalWidgets loading={loading} summary={summary} sources={sources} />
      <PrimaryFilters
        loading={loading}
        entityRef={entityRef}
        entity={entity}
        filtersChanged={filtersChanged}
      />
    </div>
  );
};
