import { css } from 'styled-components';

export default css`
  &&& {
    minheight: 200px;
    margin-top: 10px;
    background: var(--widget-background-color);
    border: 1px solid var(--widget-border-color);
    transition: all 0.3s;
    margin-bottom: 10px;
    margin-right: 10px;
    width: 100%;

    .ant-btn {
      min-width: 80px;
      font-size: 1em;
    }

    .headline {
      font-size: 2em;
    }

    .widget-header {
      font-size: 0.9em;
      padding: 10px;
    }

    .details {
      font-size: 0.9em;
      padding: 10px;
    }

    .icon {
      font-size: 1.5em;
      color: var(--widget-icon-color);
    }

    .waiting {
    }
  }
`;
