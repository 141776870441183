import { css } from 'styled-components';
import { SIDEBAR_WIDTH_PX } from '../../../constants';

export default css`
  width: ${SIDEBAR_WIDTH_PX}px;
  height: 50px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  .cx-icon-view-toggle {
    font-size: 1.3em;
    font-weight: bold;
  }
`;
