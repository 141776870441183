import { ChartRq } from '../../../../../api';

export default (EID, Filters = {}, Select = {}) => {
  return ChartRq({
    Action: 'getTicketsByMonth',
    EID,
    Filters: {
      ...Filters,
      Select,
    },
  })
    .then(({ response }) => {
      return {
        TicketCount: response.TicketCount,
        Items: response.Items,
      };
    })
    .catch(console.error);
};
