import React from 'react';
import cn from 'classnames';
import TooltipBody from './ToolTipBody';
import TooltipPortal from './TooltipPortal';
import useTooltip from './useTooltip';

export const TOP_OFFSET = {
  STANDARD: 11,
  SUB_MENU: 7,
};

export const Tooltip = ({ className, topOffset = 11, children, text = '' }) => {
  const { style, visible, showTooltip, hideTooltip, elementRef } = useTooltip(topOffset);

  return (
    <div
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
      className={cn('tooltip-trigger-text', className)}
      ref={elementRef}
    >
      {children}

      {visible && (
        <TooltipPortal>
          <TooltipBody className="tooltip-body" style={style}>
            {text}
          </TooltipBody>
        </TooltipPortal>
      )}
    </div>
  );
};
