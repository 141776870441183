import { useState, useCallback } from 'react';
import Drawer from './index';

export const useDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openDrawer = useCallback(() => {
    setIsOpen(true);
  }, []);

  const closeDrawer = useCallback(() => {
    setIsOpen(false);
  }, []);

  return { Drawer, isOpen, openDrawer, closeDrawer };
};
