import React from 'react';
import cx from 'classnames';
import { useIntl } from 'react-intl';
import { useI18nContext } from '../i18n-context';
import Text from '../../../../../components/typography/text';

const ItemsList = ({ items }) => {
  const { prefix } = useI18nContext();
  const { formatMessage } = useIntl();

  const translatedItems = React.useMemo(
    () => items.map(item => formatMessage({ id: `${prefix}.${item.moduleId}` })),
    [formatMessage, items, prefix]
  );

  return translatedItems.join(', ');
};

const SubMenuTitle = ({ className, titleKey, onToggle, items }) => {
  const { prefix } = useI18nContext();

  return (
    <button className={cx(className, 'cx-submenu-title-container')} onClick={onToggle}>
      <span className="toggle-icon">
        <i className="fas fa-chevron-up"></i>
      </span>
      <div>
        <div className="title">
          <Text text={`${prefix}.${titleKey}`} />
        </div>
        <div className="items">
          <h6>
            <ItemsList items={items} />
          </h6>
        </div>
      </div>
    </button>
  );
};

export default SubMenuTitle;
