import Dashboard from '../platform-admin/modules/dashboard/nav.config';
import Users from './modules/users/nav.config';
import Entities from './modules/entities/nav.config';

import { MOUNT_POINT } from '../../core/constants';

export default {
  id: 'support',
  description: 'Platform Support',
  mountPoint: MOUNT_POINT.APP_SWITCHER,
  modules: [Dashboard, Entities, Users],
  hidden: false,
};
