import cx from 'classnames';
import styled from 'styled-components';
import { ReactComponent as ConnexLogo } from '../../../../../assets/connex-logo.svg';
import TriCon from '../../../../../assets/Default_CA_TriCon_CO_Web.png';
import DynamicMenuItems from '../dynamic-menu-items';
import IconViewToggle from './icon-view-toggle';
import React from 'react';
import style from './style';

const Menu = ({ className, toggleIcon, onItemSelection }) => {
  return (
    <div className={cx(className)}>
      <div className="logo">
        <div className="full-logo">
          <ConnexLogo />
        </div>
        <div className="icon-logo">
          <img src={TriCon} alt="logo" />
        </div>
      </div>
      <div className="main">
        <DynamicMenuItems onItemSelection={onItemSelection} />
        <div className="toggle-icon-view-section" onClick={toggleIcon} />
      </div>
      <div className="bottom">
        <IconViewToggle toggle={toggleIcon} />
      </div>
    </div>
  );
};

export default styled(Menu)`
  ${style}
`;
