import React, { useState } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import style from './style';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import FlexColumn from 'components/layout-flex-column';
import EntityView from './entity-view';
import GlobalView from './global-view';
import TicketsView from './tickets-view';

const Styled = styled(FlexColumn)`
  ${style}
`;

const Workspace = ({ className }) => {
  const [visible] = useState(true);
  const { path } = useRouteMatch();

  return (
    <Styled className={cn('workspace', { isVisible: visible }, className)}>
      <Switch>
        <Route exact path={`${path}`} component={GlobalView} />
        <Route exact path={`${path}/entities/:entityRef`} component={EntityView} />
        <Route exact path={`${path}/entities/:entityRef/tickets`} component={TicketsView} />
      </Switch>
    </Styled>
  );
};

export default Workspace;
