import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import propTypes from 'prop-types';
import style from './style';

const Code = ({ className, id, value }) => {
  return (
    <pre>
      <code id={id} className={cn('cx-code', className)}>
        {value}
      </code>
    </pre>
  );
};

Code.propTypes = {
  className: propTypes.string,
  id: propTypes.string,
  value: propTypes.any,
};

export default styled(Code)`
  ${style}
`;
