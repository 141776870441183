import { useMemo } from 'react';

export default ({ label, payload }) => {
  const TicketCount = useMemo(() => payload && payload.length > 0 && payload[0].value, [payload]);

  return (
    <div>
      <p>
        {label} - {TicketCount} Tickets
      </p>
    </div>
  );
};
