import { css } from 'styled-components';

export default css`
  &&& {
    height: ${({ theme }) => `calc(var(--input-height-px) * ${theme.fontSizeMultiplier}px)`};
    font-size: ${({ theme }) => `${theme.resolvedFontSize}px`};
    color: var(--inputColor);
    border-color: var(--input-border-color);
    border-width: var(--input-border-width);
    border-style: var(--input-border-style);
    border-radius: var(--input-border-radius);
    background-color: var(--input-background-color);
    width: 100%;

    &:focus {
      border-color: var(--input-selected-border-color);
    }

    &::placeholder {
      color: var(--input-placeholder-color);
    }

    &.has-error {
      border-color: var(--input-error-border-color);
      background-color: var(--input-error-background-color);
      color: var(--input-error-color);
      & + .validation-error {
        color: var(--input-error-validation-message);
      }
      &:focus {
        border-color: var(--input-error-focus-border-color);
        box-shadow: var(--input-error-focus-box-shadow);
      }
    }

    &.ant-input-number-disabled {
      background-color: var(--input-disabled-background-color);
      border-color: var(--input-disabled-border-color);
      color: var(--input-disabled-color);
      &::placeholder {
        color: var(--input-disabled-placeholder-color);
      }
    }
  }
`;
