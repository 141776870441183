export default {
  moduleDisplayLabel: 'Динамічна форма',
  exampleArray: 'Приклад масиву',
  firstName: "Ім'я",
  thing: 'Річ',
  product: 'Продукт',
  dob: 'Дата народження',
  enabled: 'Увімкнено',
  timeZone: 'Часовий пояс',
  currency: 'Валюта',
  dateRange: 'Проміжок часу',
};
