import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Select } from 'components';

export default ({ id, onChange, options, placeholder = 'selectPlaceholder' }) => {
  const { formatNumber } = useIntl();

  const preparedOptions = useMemo(() => {
    if (options) {
      return options.map(option => {
        return {
          _display: `${option.ID} ${formatNumber(option.TicketCount)}`,
          _value: option.ID.substr(0, option.ID.indexOf('/') - 1),
        };
      });
    }
    return [];
  }, [formatNumber, options]);

  return (
    <Select
      id={id}
      mode={'multiple'}
      allowClear
      placeholder={placeholder}
      defaultValue={[]}
      onChange={option => onChange && onChange(id, option)}
      displayPath="_display"
      valuePath="_value"
      options={preparedOptions}
      loading={!options}
    />
  );
};
