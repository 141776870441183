import Call from '../call';
import { TransformListQueryRq } from 'api/developer/TransformListQueryRq';
import { TransformGetQueryRq } from 'api/developer/TransformGetQueryRq';
import { TransformSaveQueryRq } from 'api/developer/TransformSaveQueryRq';
import { TransformQuerySqlRq } from 'api/developer/TransformQuerySqlRq';

const GetSchemaRq = async id => {
  return Call('developer', 'GetSchemaRq', { id });
};

const ListSchemasRq = async () => {
  return Call('developer', 'ListSchemasRq', {});
};

const QuerySqlRq = (sql, parameters, columns = [], name, type='sql', performanceMetrics=null) => {
  const transformed = TransformQuerySqlRq.transformRequest({
    sql,
    parameters,
    columns,
    name,
    type,
    performanceMetrics,
  });
  return Call('developer', 'QuerySqlRq', transformed);
};

const SaveQueryRq = async (sql, name, state, parameters, uid, tags, columns, type) => {
  const transformed = TransformSaveQueryRq.transformRequest({
    sql,
    name,
    state,
    parameters,
    uid,
    tags,
    columns,
    type,
  });
  return Call('developer', 'SaveQueryRq', transformed).then(response =>
    TransformSaveQueryRq.transformResponse(response)
  );
};

const GetQueryRq = async UID => {
  return Call('developer', 'GetQueryRq', { UID }).then(response =>
    TransformGetQueryRq.transformIncoming(response)
  );
};

const GetRecordRq = async (PK, CRN, Table) => {
  return Call('developer', 'GetRecordRq', { PK, CRN, Table });
};

const UpdateRecordRq = async (PK, CRN, Table, Item) => {
  return Call('developer', 'UpdateRecordRq', { PK, CRN, Table, Item });
};

const PatchQueryRq = async (UID, Query) => {
  return Call('developer', 'PatchQueryRq', { ...Query, UID });
};

const GetQueryDetailsRq = async ({ UID, Sql, Parameters }) => {
  return Call('developer', 'GetQueryDetailsRq', { UID, Sql, Parameters });
};

const ListQueryRq = async (States = ['PUBLIC', 'PRIVATE']) => {
  return Call('developer', 'ListQueryRq', { States }).then(response => {
    return TransformListQueryRq.transformIncoming(response);
  });
};

const DeleteQueryRq = async UID => {
  return Call('developer', 'DeleteQueryRq', { UID });
};

const GetQueryExecutionPlanRq = async (Query, Parameters) => {
  return Call('developer', 'GetQueryExecutionPlanRq', { Query, Parameters });
};

const GetQueryExecutionStatusRq = async QueryStatusUID => {
  return Call('developer', 'GetQueryExecutionStatusRq', { QueryStatusUID });
};

const GetSQLEventsRq = async ({
  duration,
  entity,
  itemCount,
  statementName,
  status,
  startDate,
  endDate,
  limit = 300,
  containerName,
  userName,
}) => {
  return Call('developer', 'GetSQLEventsRq', {
    Duration: duration,
    Entity: entity,
    ItemCount: itemCount,
    StatementName: statementName,
    Status: status,
    StartDate: startDate,
    EndDate: endDate,
    Limit: limit,
    ContainerName: containerName,
    UserName: userName,
  });
};

const ZapDataRq = async body => {
  return Call('developer', 'ZapDataRq', body);
};

const ZapDataSummaryRq = async body => {
  return Call('developer', 'ZapDataSummaryRq', body);
};

const ListEntitiesForPurgeRq = async () => {
  return Call('developer', 'ListEntitiesForPurgeRq', {});
};

const ListEntitiesRq = async () => {
  return Call('developer', 'ListEntitiesRq', {});
};

const ListAuditEntitiesRq = async () => {
  return Call('developer', 'ListAuditEntitiesRq', {});
};

const ListAuditStatementNamesRq = async () => {
  return Call('developer', 'ListAuditStatementNamesRq', {});
};

const SqlLookupRq = async ID => {
  return Call('developer', 'SqlLookupRq', { ID });
};

const ListStatementContainerNames = () => {
  return Call('developer', 'ListStatementContainerNamesRq', {});
};

const ListStatementUserNames = () => {
  return Call('developer', 'ListStatementUserNamesRq', {});
};

export const Schema = {
  GetSchemaRq,
  ListSchemasRq,
};

export const Sql = {
  QuerySqlRq,
  SaveQueryRq,
  GetQueryRq,
  GetQueryDetailsRq,
  GetRecordRq,
  UpdateRecordRq,
  ListQueryRq,
  DeleteQueryRq,
  PatchQueryRq,
  GetQueryExecutionPlanRq,
  GetQueryExecutionStatusRq,
  GetSQLEventsRq,
  ListEntitiesRq,
  ListEntitiesForPurgeRq,
  ListAuditEntitiesRq,
  ListAuditStatementNamesRq,
  ListStatementContainerNames,
  ListStatementUserNames,
  SqlLookupRq,
  ZapDataRq,
  ZapDataSummaryRq,
};
