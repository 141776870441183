import React, { useCallback } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import style from './style';
import AppSwitcherBody from './app-switcher-body';
import useAppSwitcher from './useAppSwitcher';
import Portal from '../../../components/portal';
import Icon from './icon';
import { cache } from '../../../util';

const AppSwitcher = ({ className, mountPoint, onSelection }) => {
  const {
    style,
    appSwitcherApps,
    iconRef,
    mode,
    hideAppSwitcher,
    toggleAppSwitcher,
    visible,
    enabled,
  } = useAppSwitcher(mountPoint);

  const handleExecution = useCallback(
    appId => {
      if (appId) {
        cache.setItem('cx.app', appId);
        onSelection(appId);
      }
      hideAppSwitcher();
    },
    [onSelection, hideAppSwitcher]
  );

  return (
    <div className={cn('cx-app-switcher', mode, className)} data-testid="appSwitcher">
      <div>
        <span
          className={cn('cx-toggle-app-switcher', { enabled, active: visible })}
          onClick={toggleAppSwitcher}
          data-testid="appSwitcher"
        >
          <Icon innerRef={iconRef} active={visible} />
        </span>

        {visible && (
          <Portal id="cx-portal-app-switcher">
            <AppSwitcherBody style={style} apps={appSwitcherApps} execute={handleExecution} />
          </Portal>
        )}
      </div>
    </div>
  );
};

export default styled(AppSwitcher)(style);
