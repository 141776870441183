export default {
  viewHeader: 'Query Logs',
  SQL: 'SQL',
  all: 'All',
  'begin-time': 'Begin Time',
  'clear-filters': 'Clear Filters',
  'clear-table-filters': 'Clear Table Filters',
  'compact-size': 'Compact Size',
  'copy-json': 'Copy JSON',
  'duration-minimum-search': 'Minimum Duration (seconds)',
  'edit-query': 'Edit Query',
  'end-time': 'End Time',
  entity: 'Entity',
  error: 'Error',
  'full-size': 'Full Size',
  'item-details': 'Item Details',
  'item-minimum-search': 'Minimum Item Count',
  'limit-search': 'Limit Search',
  max: 'Max',
  search: 'Search',
  'showing-item-count': 'Showing {rowLimit} of {rowCount} items.',
  'statement-name': 'Statement Name',
  status: 'Status',
  success: 'Success',
  'view-json': 'View JSON',
  'view-sql-statement': 'View SQL Statement',
  'copy-parameters': 'Copy Parameters',
  'copy-sql': 'Copy SQL',
  parameters: 'Parameters',
  'user-name': 'User Name',
  'container-name': 'Container Name',
};
