import React from 'react';
import { useRouteMatch } from 'react-router-dom';
import cn from 'classnames';
import Text from '../../../../../components/typography/text';
import { useI18nContext } from '../i18n-context';

const MenuItem = ({ className, handleItemSelection, module }) => {
  const match = useRouteMatch(module.path);
  const { prefix } = useI18nContext();

  return (
    <div
      className={cn('menu-item', className, { active: match })}
      onClick={() => handleItemSelection(module.path)}
    >
      <div className="right-highlight" />
      <div className="icon">
        <i className={`fas fa-${module.icon}`}></i>
      </div>
      <div className="label">
        <Text text={`${prefix}.${module.moduleId}`} />
      </div>
    </div>
  );
};

export default MenuItem;
