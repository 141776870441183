import { useEffect, useState } from 'react';
import getAppConfig from './apps.config';
import { values } from 'lodash';
import { moduleDefinitions } from '../../modules';
import { useUserContext } from '../../contexts/user/UserContextProvider';

const knownModules = values(moduleDefinitions).reduce((acc, module) => {
  acc[module.id] = module;
  return acc;
}, {});

const mergeWithKnownModules = apps => {
  const mergeModules = ({ modules, path }) => {
    return modules.map(module => {
      if (module.modules) {
        return {
          ...module,
          modules: mergeModules({
            modules: module.modules.filter(m => !m.hidden),
            path: `${path}/${module.moduleId}`,
          }),
        };
      }

      /*
        In order for routing to work, modules intended as a root-level menu-item
        have an underscore as part of their path.  This will have to be re-thought
        if we ever need a menu with more than one level of submenus.
       */
      const numberOfLevels = (path.match(/\//g) || []).length;
      let adjustedPath = path;
      if (numberOfLevels === 2) {
        adjustedPath += '/_';
      }

      return {
        ...module,
        moduleConfig: {
          ...knownModules[module.moduleId],
          path: `${adjustedPath}/${module.moduleId}`,
        },
      };
    });
  };

  const merged = apps.map(app => {
    return {
      ...app,
      modules: mergeModules({
        modules: app.modules.filter(m => !m.hidden),
        path: `/app/${app.id}`,
      }),
    };
  });

  return merged;
};

export const useAppsConfig = () => {
  const { hasAppPermission } = useUserContext();
  const [appsConfig, setAppsConfig] = useState();
  const [busy, setBusy] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const apps = await getAppConfig();
      const merged = mergeWithKnownModules(apps);
      const permitted = merged.filter(hasAppPermission);

      setAppsConfig(permitted);
      setBusy(false);
    };

    fetch();
  }, [hasAppPermission]);

  return { appsConfig, busy };
};

export default useAppsConfig;
