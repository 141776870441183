export default {
  appName: 'Developer',
  builds: 'Builds',
  dashboard: 'Dashboard',
  metrics: 'Metrics',
  'data-explorer': 'Query Editor',
  'audit-logs': 'Query Logs',
  'api-docs': 'Api Docs',
  'developer-dashboard': 'Dashboard',
  data: 'Data',
  api: 'API',
  'api-metrics': 'API Metrics',
  queries: 'Saved Queries',
  integrations: 'Integrations',
  datasets: 'Data Sets',
  save: 'Save',
  run: 'Run',
  format: 'Format',
  public: 'Public',
  saveQuery: 'Save Query',
  saveAsQuery: 'Query - Save As',
  name: 'Name',
  elapsedTime:
    'Found {itemCount, plural, =0 {0 items} one {1 item} other {{itemCount} items}} ({size} {sizeType}) in {runTime, plural, =0 {0.00 seconds} one {1 second} other {{runTime} seconds}}',
  Results: 'Results',
  Parameters: 'Parameters',
  AddNewParameter: 'Add New Parameter',
  Details: 'Details',
  Metrics: 'Metrics',
  EditQuery: 'Edit Query',
  Columns: 'Columns',
  Visibility: 'Visibility',
  sdlc: 'SDLC',
  'pull-requests': 'Pull Requests',
};
