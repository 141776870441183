import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Spinner } from 'components';
import cn from 'classnames';
import style from './style';
import { usePullRequest } from '../usePullRequest';
import FileList from '../components/file-list';
import ProblemList from '../components/problem-list';
import SelectBranches from './SelectBranches';
import JiraIssue from '../components/jira-issue';
import CreatePullRequestButton from './CreatePullRequestButton';

const CreatePullRequest = ({ className, repositoryName = 'connex', onCreated }) => {
  const {
    startPullRequest,
    starting,
    start,
    analyzePullRequest,
    analyzing,
    analysis,
  } = usePullRequest();
  const [sourceBranch, setSourceBranch] = useState(null);
  const [targetBranch, setTargetBranch] = useState('develop');
  const [analyzed, setAnalyzed] = useState(false);
  const [files, setFiles] = useState(null);
  const [problems, setProblems] = useState(null);
  const [issue, setIssue] = useState(null);
  const [mergeable, setMergeable] = useState(true);

  useEffect(() => {
    startPullRequest(repositoryName);
  }, []);

  useEffect(() => {
    setAnalyzed(false);
  }, [sourceBranch, targetBranch]);

  useEffect(() => {
    if (analysis) {
      const { problems, issue, analysis: files } = analysis;

      setProblems(problems);
      setFiles(files);
      setIssue(issue);
      setAnalyzed(true);
    }
  }, [analysis]);

  useEffect(() => {
    problems && setMergeable(!problems.some(p => p['preventsMerge'] === true));
  }, [problems]);

  /*
    TODO - check if "Critical"
    TODO - pull and show Jira info
    TODO - check if "Complete"
    TODO - mark as no-cicd, frontend, backend
   */
  return (
    <div className={cn('pull-request', className)}>
      <Spinner spinning={starting} />
      <SelectBranches
        start={start}
        onSelectSource={setSourceBranch}
        onSelectTarget={setTargetBranch}
      />
      {!analyzed && (
        <div className="actions">
          <Button
            className={'continue-pull-request-btn'}
            onClick={() => analyzePullRequest(repositoryName, sourceBranch, targetBranch)}
            loading={analyzing}
            label="pull-requests.continue-pull-request"
          />
        </div>
      )}
      {analyzed && problems && <ProblemList problems={problems} />}
      {analyzed && files && <FileList analysis={files.platformTeam || []} />}
      {analyzed && issue && <JiraIssue issue={issue} />}
      {analyzed && issue && (
        <CreatePullRequestButton
          repositoryName={repositoryName}
          sourceBranch={sourceBranch}
          targetBranch={targetBranch}
          issue={issue}
          mergeable={mergeable}
          onCreated={onCreated}
        />
      )}
    </div>
  );
};

export default styled(CreatePullRequest)`
  ${style}
`;
