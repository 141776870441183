export default {
  moduleDisplayLabel: 'Profile',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  confirmNewPassword: 'Confirm New Password',
  email: 'Email Address',
  firstName: 'First Name',
  lastName: 'Last Name',
  phoneNumber: 'Phone Number',
  preferredMfa: 'Preferred MFA Type',
  qrCodeInstruction: 'Scan this QR code or type the secret key into the app.',
  mfaTitle: 'Setup an authenticator app to Generate Codes',
  mfaConfirm: 'To confirm, enter the security code that was generated by the app.',
  EnableSoftwareTokenMFAException: 'Invalid security code.',
  'tab.general': 'General',
  'tab.mfa': 'MFA',
  'tab.change.password': 'Change Password',
  reenroll: 'Re-enroll',

  validation: {
    required: 'This field is required.',
    password: {
      minLength: 'Minimum length is {arg1}',
      required: 'This field is required.',
    },
    confirmPasswordRequired: 'This field is required.',
    passwordsMustMatch: 'Passwords must match.',
  },
};
