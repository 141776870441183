import React from 'react';
import { useParams } from 'react-router-dom';
import Wdc from '../wdc';
import { Theme } from '../theme-provider';
import Dashboard from 'core/dashboard';

const publicRoutes = {
  connect: <Wdc />,
  dashboard: <Dashboard />,
};

const PublicRoute = () => {
  const { componentId } = useParams();

  return (
    <Theme defaultTheme="dark">
      <div>{publicRoutes[componentId] || <div>Invalid URL</div>}</div>
    </Theme>
  );
};

export default PublicRoute;
