import Builds from './builds/nav.config';
import PullRequests from './pullRequests/nav.config';

export default {
  id: 'sdlc',
  icon: 'code-branch',
  description: 'SDLC',
  moduleId: 'sdlc',
  modules: [PullRequests, Builds],
  hidden: false,
};
