import { css } from 'styled-components';

export default css`
  display: flex;
  flex-direction: column;
  min-height: 0;
  min-width: 0;
  flex: 1;

  .item-details-header-section {
    display: flex;
    justify-content: flex-end;
    //border-bottom: 1px solid var(--baseBorderColor);
    padding: 5px;
  }
  .content-section {
    background-color: var(--cm-background-color);
    color: var(--cm-color);
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: auto;
    padding: 5px;
    .cx-spinner {
      margin-top: 20%;
    }
  }
`;
