import profile from './modules/profile/nav.config';
import dashboard from './modules/dashboard/nav.config';

import { MOUNT_POINT } from '../../core/constants';

export default {
  id: 'settings',
  moduleId: 'settings',
  mountPoint: MOUNT_POINT.USER_MENU,
  modules: [profile, dashboard],
  unrestricted: true,
};
