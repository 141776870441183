import Dashboard from './modules/dashboard/nav.config';
import Roadmap from './modules/roadmap/nav.config';
import { MOUNT_POINT } from '../../core/constants';

export default {
  id: 'sdlc',
  moduleId: 'sdlc',
  description: 'SDLC',
  mountPoint: MOUNT_POINT.APP_SWITCHER,
  modules: [Dashboard, Roadmap],
  hidden: false,
};
