import decode from 'core/dashboard/decode';

const token = '7RSZ7Xykv';

export const secret = () => {
  const values = `U2FsdGVkX1+oWnBCQ3sfltI8ABFA2qG9sEP1eWTlAHiKfo6pZ9gOBr7VLSze//${token}/S4Msc8gog+C19F2lhWBZkY3P1mGq/328DY8PfWkPeiDZuCdvM6eyoA2x5tjPWn4MkK16EqbOhW4cQ6G3h+qmZc5qCMYszkHTbbLCK2O5Vje32zk1tNr4I1U8X4Czsd7x7D9hlNbDKE+g4WZ7PsrZ92kAOqOIBuMFbVlC/VFtG9C0eBC9180wJMTVOaCDLtjJbgsGwieFkaijBE3TvmmC3WoKNRwM7S+vnWlXQMDYsOHqA5/pp82iFHpcvuA1CMcslRDNES0Jf1parcGHGYIvNZENiwN2BzNCeIvihqadruOxjfGGjeuK4RD+gZ+8l2dXPE//Axopsb3V+ZELvFk1JwIDrq/wlhFrMaU=`;
  const secrets = decode(values);

  const [subDomain] = window.location?.hostname?.split('.') ?? 'localhost';

  switch (subDomain) {
    case 'console':
      return secrets.prod;
    case 'console-model':
      return secrets.model;
    case 'console-stage':
      return secrets.stage;
  }

  return secrets.localhost;
};
