import React from 'react';

export const I18nContext = React.createContext();

export const useI18nContext = () => {
  const context = React.useContext(I18nContext);
  if (!context) {
    throw new Error('useI18nContext cannot be called outside the I18nContext Provider.');
  }
  return context;
};

export const I18nContextProvider = ({ prefix, children }) => {
  return <I18nContext.Provider value={{ prefix }}>{children}</I18nContext.Provider>;
};
