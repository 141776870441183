import React from 'react';
import i18n from './i18n';

const component = React.lazy(() => import('./index'));

export default {
  component,
  i18n,
  id: 'sandbox-dashboard',
  moduleId: 'sandbox-dashboard',
  permissions: {},
  hidden: true,
};
