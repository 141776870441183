import { useCallback, useEffect, useRef, useState } from 'react';
import { isFunction, isString } from 'lodash';

// https://github.com/facebook/react/issues/5465#issuecomment-157888325
export const makeCancelable = promise => {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(val => {
      return isCanceled ? null : resolve(val);
    });
    promise.catch(error => {
      return isCanceled ? null : reject(error);
    });
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
};

/*
  Creates a cancellable promise from the given fn
  and cancels it when the component unloads.
 */

const useApiCall = fn => {
  if (!isFunction(fn)) throw new Error('Expecting a function.');
  const [busy, setBusy] = useState(false);
  const apiCall = useRef(fn);
  const cancelablePromise = useRef({ cancel: () => {} });

  const cancel = useCallback(() => {
    cancelablePromise.current.cancel();
    setBusy(false);
  }, [cancelablePromise]);

  useEffect(() => {
    return () => {
      cancelablePromise.current.cancel();
    };
  }, [cancelablePromise]);

  const makeApiCall = useCallback(
    (...args) => {
      setBusy(true);

      const promise = apiCall.current(...args);
      const cancelable = makeCancelable(promise);
      cancelablePromise.current = cancelable;
      return cancelable.promise
        .then(results => {
          if (results && !results.success) {
            const errorObject = isString(results.response)
              ? { type: 'Error', message: results.response }
              : results.response;

            return Promise.reject({
              title: errorObject.type,
              message: errorObject.message,
            });
          }

          return results;
        })
        .finally(() => setBusy(false));
    },
    [cancelablePromise]
  );

  return [makeApiCall, busy, cancel];
};

export default useApiCall;
