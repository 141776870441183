import { css } from 'styled-components';

export default css`
  &.cx-spinner {
    > * {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .additional-action {
      margin-top: 20px;
    }
  }

  &.spinner-enter,
  &.spinner-appear {
    opacity: 0.01;
  }

  &.spinner-enter-active,
  &.spinner-appear-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
  }
`;
