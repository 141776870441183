import React from 'react';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';

export default () => {
  const { state } = useLocation();
  const { entityRef } = useParams();
  const { url } = useRouteMatch();

  return (
    <div style={{ textAlign: 'left' }}>
      <div>{url}</div>
      <div>{entityRef}</div>
      <pre>{JSON.stringify(state, null, 4)}</pre>
    </div>
  );
};
