import { API, Auth } from 'aws-amplify';
import getApiKey from '../getApiKey';

let useWebIdentity = true;

export default async (method, params = {}) => {
  const request = {
    body: {
      jsonrpc: '2.0',
      id: new Date().toISOString(),
      method,
      params,
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-api-key': getApiKey('sdlc'),
    },
  };

  if (useWebIdentity) {
    const Session = await Auth.currentSession();

    if (Session.idToken && Session.idToken.jwtToken) {
      request.headers['authorization'] = `${Session.idToken.jwtToken}`;
    }
  }

  return API.post('sdlc', `/v1/sdlc`, request).catch(error => {
    return {
      success: false,
      response: error.message || error,
    };
  });
};
