import React, { useEffect, useState } from 'react';
import { Internationalization } from '../../i18n';
import queryString from 'query-string';
import JWT from 'jwt-simple';
import { secret } from './secrets';
import Workspace from '../../apps/platform-admin/modules/dashboard/view/tickets';

const queryParams = queryString.parse(window.location.search);

const Dashboard = () => {
  const [auth, setAuth] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const { 'x-connex-id': xConnexId } = queryParams;

    if (xConnexId) {
      try {
        const user = JWT.decode(xConnexId, secret(), false, 'HS512');

        if (user?.groups?.includes('privileged')) {
          setAuth(user);
        } else {
          setError(true);
        }
      } catch (e) {
        setError(true);
      }
    } else {
      setError(true);
    }
  }, []);

  return (
    <Internationalization>
      {auth && <Workspace user={auth} />}
      {error && <div />}
    </Internationalization>
  );
};

export default Dashboard;
