import Dashboard from './modules/dashboard/nav.config';
import Users from './modules/users/nav.config';
import Organizations from './modules/organizations/nav.config';
import Entities from './modules/entities/nav.config';

import { MOUNT_POINT } from '../../core/constants';

export default {
  id: 'platform-admin',
  description: 'Platform Admin',
  mountPoint: MOUNT_POINT.APP_SWITCHER,
  modules: [Dashboard, Users, Organizations, Entities],
  hidden: false,
};
