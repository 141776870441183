import React, { useCallback } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Module } from 'core/modules/Module';
import { QueryListContextProvider } from './view/query-list-context';

const View = React.lazy(() => import('./view'));
const Details = React.lazy(() => import('./details'));

export default ({ module }) => {
  const history = useHistory();
  const openQueryDetails = useCallback(
    queryUid => {
      history.push(`${module.path}/${queryUid}`);
    },
    [history, module.path]
  );

  return (
    <Module module={module}>
      <QueryListContextProvider>
        <Route
          exact
          path={module.path}
          render={() => <View openQueryDetails={openQueryDetails} />}
        />
        <Route path={`${module.path}/:queryUid`} component={Details} />
      </QueryListContextProvider>
    </Module>
  );
};
