import React, { useEffect, useState } from 'react';
import { Area, AreaChart, Tooltip, XAxis, YAxis } from 'recharts';
import Widget from 'components/widget';
import { SizeMe } from 'react-sizeme';

const MonthTip = ({ label, payload }) => {
  const TicketCount = payload && payload.length > 0 && payload[0].value;

  return (
    <div>
      <p>
        {label} - {TicketCount}M Tickets
      </p>
    </div>
  );
};

const Charts = ({ months }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (Array.isArray(months)) {
      const items = months
        .map(item => {
          return {
            Month: `${item.Year}-${item.Month}`,
            Label: `${item.Month}/${item.Year.substr(2)}`,
            ProducedCount: Math.round(item.TicketCount / 1000),
            SharedCount: Math.round(item.TicketCount / 1000),
          };
        })
        .slice(-13);

      setData(items);
    }
  }, [months]);

  return (
    <SizeMe monitorHeight monitorWidth refreshRate={100}>
      {({ size }) => (
        <Widget>
          <div className="widget-header">Tickets Produced By Month (Thousands)</div>
          <AreaChart
            width={size.width - 12}
            height={250}
            data={data}
            margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
          >
            <defs>
              <linearGradient id="colorProduction" x1="0" y1="0" x2="0" y2="1">
                <stop offset="2%" stopColor="#337CD0" stopOpacity={0.9} />
                <stop offset="98%" stopColor="#337CD0" stopOpacity={0} />
              </linearGradient>
              <linearGradient id="colorShared" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="Label" />
            {data && <YAxis />}
            <Tooltip content={<MonthTip />} />
            <Area
              type="monotone"
              dataKey="ProducedCount"
              stroke="#666666"
              fillOpacity={1}
              fill="url(#colorProduction)"
            />
          </AreaChart>
        </Widget>
      )}
    </SizeMe>
  );
};

export default Charts;
