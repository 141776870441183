import { useState } from 'react';

export const useQueryLogsCache = () => {
  const [form, setForm] = useState(null);
  const [lists, setLists] = useState(null);
  const [results, setResults] = useState(null);
  const [itemCount, setItemCount] = useState(0);
  const [pagination, setPagination] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);

  return {
    form,
    setForm,
    results,
    setResults,
    itemCount,
    setItemCount,
    pagination,
    setPagination,
    lists,
    setLists,
    selectedItem,
    setSelectedItem,
  };
};
