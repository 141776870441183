import WDC from '../../api/wdc';
import { Auth } from 'aws-amplify';
import { decrypt } from './secrets';

const tableau = window.tableau;

export default schemaCallback => {
  if (tableau.connectionData) {
    const settings = JSON.parse(tableau.connectionData);
    const { entityRef, dataSet, username, apiKey } = settings;

    Auth.signIn({
      username,
      password: decrypt(apiKey, entityRef),
    })
      .then(() => {
        WDC(settings.entityRef, {
          Action: 'getSchemas',
          EID: entityRef,
          ID: dataSet,
        })
          .then(Result => {
            schemaCallback(Result.Schemas);
          })
          .catch(e => {
            tableau.log(e.message);

            schemaCallback([]);
          });
      })
      .catch(e => {
        tableau.log(e.message);
      });
  } else {
    tableau.log('No Connection Data Found!');

    schemaCallback([]);
  }
};
