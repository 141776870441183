import React from 'react';
import { Module } from '../../../../../core/modules/Module';

const ApiDocs = React.lazy(() => import('./ApiDocs'));

export default ({ module }) => {
  return (
    <Module module={module}>
      <ApiDocs />
    </Module>
  );
};
