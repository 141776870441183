import rocksetClient from '@rockset/client';

const ENDPOINT = 'https://api.rs2.usw2.rockset.com';

export const executeQueryLambda = async (name, Fields = [], privateKey) => {
  const options = {
    parameters: Fields || [],
  };

  const RockSet = rocksetClient(privateKey, ENDPOINT);

  return RockSet.queryLambdas
    .executeQueryLambdaByTag('connex', name, 'latest', options)
    .then(Rs => {
      const { results } = Rs;

      return {
        Items: results || [],
      };
    })
    .catch(err => {
      return {
        Items: [],
        Error: err.message || `Error executing ${name}`,
      };
    });
};
