import { useState } from 'react';
import { useIntl } from 'react-intl';
import { isObject } from 'lodash';
import useDeepCompareEffect from 'use-deep-compare-effect';

export const FORMATTER = {
  DATE: 'formatDate',
  TIME: 'formatTime',
  RELATIVE_TIME: 'formatRelativeTime',
  NUMBER: 'formatNumber',
  PLURAL: 'formatPlural',
  MESSAGE: 'formatMessage',
};

export const useIntlProps = (...messageDefinitions) => {
  const [intlProps, setIntlProps] = useState([]);
  const intl = useIntl();

  useDeepCompareEffect(() => {
    const messages = [];
    messageDefinitions.forEach(def => {
      const { values, formatter = FORMATTER.MESSAGE, ...message } = def;

      // might need a more robust check.
      if (isObject(def)) {
        messages.push(intl[formatter](message, values));
      } else {
        messages.push(def);
      }
    });

    setIntlProps(messages);
  }, [intl, messageDefinitions]);

  return intlProps;
};

export default useIntlProps;
