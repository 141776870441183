import APiDocs from './index';
import i18n from './i18n';

export default {
  id: 'api-docs',
  i18n,
  disabled: false,
  component: APiDocs,
  routes: [],
  persona: 'developer',
  permissions: {},
};
