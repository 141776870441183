import React from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import propTypes from 'prop-types';
import { Dropdown as AntDropdown } from 'antd';
import style from './style';

const Dropdown = ({ className, children, ...props }) => {
  return (
    <AntDropdown className={cn('cx-dropdown', className)} {...props}>
      {children}
    </AntDropdown>
  );
};

Dropdown.propTypes = {
  className: propTypes.string,
  children: propTypes.element,
};

export default styled(Dropdown)`
  ${style}
`;
