import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import Drawer from '../../../components/drawer';
import style from './style';
import { useDrawerContext, useDrawerStateContext } from '../../contexts/right-drawer';

export const RightDrawer = ({ className }) => {
  const { isOpen, content, footer, width, title } = useDrawerStateContext();
  const { cancelRightDrawer } = useDrawerContext();

  return (
    <Drawer
      className={cn('cx-drawer', className)}
      title={title}
      width={width}
      visible={isOpen}
      onClose={cancelRightDrawer}
      footer={footer}
    >
      {content}
    </Drawer>
  );
};

export default styled(RightDrawer)`
  ${style}
`;
