import React, { useCallback, useState } from 'react';
import { notification } from '../../../util';
import { useIntl } from 'react-intl';

const NotificationLogUpdaterContext = React.createContext();
const NotificationLogStateContext = React.createContext();
const NotifyContext = React.createContext();

export const useNotificationLogUpdaterContext = () => {
  const context = React.useContext(NotificationLogUpdaterContext);
  if (!context) {
    throw new Error(
      '`useNotificationLogUpdaterContext cannot be rendered outside of the NotificationLogUpdaterContext context provider`'
    );
  }
  return context;
};

export const useNotificationStateContext = () => {
  const context = React.useContext(NotificationLogStateContext);
  if (!context) {
    throw new Error(
      '`useNotificationLogStateContext cannot be rendered outside of the NotificationLogStateContext context provider`'
    );
  }
  return context;
};

export const useNotifyContext = () => {
  const context = React.useContext(NotifyContext);
  if (!context) {
    throw new Error(
      '`useNotifyContext cannot be rendered outside of the NotifyContext context provider`'
    );
  }
  return context;
};

export const NotificationLogProvider = ({ children }) => {
  const [notificationLog, setNotificationLog] = useState([]);

  const stateValue = React.useMemo(() => ({ notificationLog }), [notificationLog]);
  const updaterValue = React.useMemo(() => ({ setNotificationLog }), [setNotificationLog]);

  return (
    <NotificationLogStateContext.Provider value={stateValue}>
      <NotificationLogUpdaterContext.Provider value={updaterValue}>
        {children}
      </NotificationLogUpdaterContext.Provider>
    </NotificationLogStateContext.Provider>
  );
};

export const NotificationsProvider = ({
  defaultLogLength = 20,
  defaultDuration = 3500,
  children,
}) => {
  const { setNotificationLog } = useNotificationLogUpdaterContext();
  const { formatMessage } = useIntl();

  const addLogItem = useCallback(
    item => {
      if (item) {
        setNotificationLog(s => {
          const log = [...s, item];
          if (log.length > defaultLogLength) {
            log.shift();
          }
          return log;
        });
      }
    },
    [defaultLogLength, setNotificationLog]
  );

  const removeLogItem = useCallback(
    item => {
      setNotificationLog(s => {
        return s
          .filter(logItem => logItem.timeStamp !== item.timeStamp)
          .sort((a, b) => a.timeStamp - b.timeStamp);
      });
    },
    [setNotificationLog]
  );

  const clear = useCallback(() => {
    setNotificationLog([]);
  }, [setNotificationLog]);

  const notify = useCallback(
    options => {
      const {
        type = 'open',
        name = formatMessage({ id: 'error' }),
        title,
        message,
        icon,
        log = true,
        duration = defaultDuration,
      } = options;

      if (log) {
        const timeStamp = new Date().getTime();
        addLogItem({ timeStamp, ...options });
      }

      notification[type]({
        message: title || name,
        description: message,
        icon,
        duration: duration / 1000,
      });
    },
    [addLogItem, defaultDuration, formatMessage]
  );

  const success = useCallback(
    options => {
      notify({ ...options, type: 'success' });
    },
    [notify]
  );

  const info = useCallback(
    options => {
      notify({ ...options, type: 'info' });
    },
    [notify]
  );

  const warning = useCallback(
    options => {
      notify({ ...options, type: 'warning' });
    },
    [notify]
  );

  const error = useCallback(
    options => {
      notify({ ...options, type: 'error' });
    },
    [notify]
  );

  return (
    <NotifyContext.Provider value={{ notify, success, info, warning, error, removeLogItem, clear }}>
      {children}
    </NotifyContext.Provider>
  );
};
