import Dashboard from './modules/dashboard/nav.config';
import Examples from './modules/examples/nav.config';
import { MOUNT_POINT } from '../../core/constants';

export default {
  id: 'sandbox',
  moduleId: 'sandbox',
  description: 'Sandbox',
  mountPoint: MOUNT_POINT.APP_SWITCHER,
  modules: [Dashboard, Examples],
  hidden: true,
};
