import React from 'react';
import { Module } from '../../../../core/modules/Module';

const Dashboard = React.lazy(() => import('./Dashboard'));

export default ({ module }) => {
  return (
    <Module module={module}>
      <Dashboard />
    </Module>
  );
};
