import { css } from 'styled-components';

export default css`
  padding: 10px;

  h4 {
    text-align: left;

    span {
      margin-right: 15px;
    }
  }
`;
