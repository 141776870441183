export default {
  appName: 'Platform Admin',
  'pa-dashboard': 'Dashboard',
  users: 'Users',
  metrics: 'Metrics',
  'profile-search': 'Profile Search',
  organizations: 'Organizations',
  entities: 'Entities',
  'manage-data': 'Manage Data',
};
