import React, { useState } from 'react';
import { Button, FormItemWrapper, Select, Text } from 'components';
import { Space } from 'antd';
import { useUserContext } from 'core/contexts/user/UserContextProvider';

export const BuildListHeader = ({
  listing,
  listBuilds,
  maxResults,
  setMaxResults,
  setShowModal,
  setStatus,
  status,
}) => {
  const [isDirty, setIsDirty] = useState(false);
  const { isPlatformAdmin } = useUserContext();

  const maxResultsList = [{ id: 10 }, { id: 20 }, { id: 30 }];

  const statusList = [
    { id: 'Cancelled', name: <Text text="builds.cancelled" /> },
    { id: 'Failed', name: <Text text="builds.failed" /> },
    { id: 'InProgress', name: <Text text="builds.in-progress" /> },
    { id: 'Pending', name: <Text text="builds.pending" /> },
    { id: 'Success', name: <Text text="builds.success" /> },
    { id: 'TimedOut', name: <Text text="builds.timed-out" /> },
  ];

  return (
    <Space direction="vertical" style={{ margin: 15 }}>
      <Space>
        <FormItemWrapper htmlFor="select-status" label="builds.status">
          <Select
            disabled={listing}
            id="select-status"
            value={status}
            options={statusList}
            valuePath="id"
            displayPath="name"
            className="select-filter"
            onChange={value => {
              setIsDirty(true);
              setStatus(value);
            }}
          />
        </FormItemWrapper>
        <FormItemWrapper htmlFor="select-repository" label="builds.max-results">
          <Select
            disabled={listing}
            id="select-max-results"
            value={maxResults}
            options={maxResultsList}
            valuePath="id"
            displayPath="id"
            className="select-filter"
            onChange={value => {
              setIsDirty(true);
              setMaxResults(value);
            }}
          />
        </FormItemWrapper>
        <div style={{ paddingTop: '20px' }}>
          <Space>
            <Button
              id="refresh-btn"
              className={'search-pull-request-btn'}
              onClick={() => {
                setIsDirty(false);
                listBuilds(maxResults, status);
              }}
              disabled={listing}
              icon={<i className={isDirty ? 'fa fa-search' : 'fa fa-sync'} />}
              label={isDirty ? 'pull-requests.search' : 'pull-requests.refresh'}
              type="secondary"
            />
            {isPlatformAdmin() && (
              <Button
                id="create-btn"
                className="create-build"
                onClick={() => setShowModal(true)}
                label="builds.create-build"
                type="primary"
              />
            )}
          </Space>
        </div>
      </Space>
    </Space>
  );
};

export default BuildListHeader;
