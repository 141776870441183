import { css } from 'styled-components';

export default css`
  &&& {
    background-color: var(--input-background-color);
    border-radius: var(--input-border-radius);
    font-size: ${({ theme }) => `${theme.fontSizeMultiplier}rem`};
    width: 100%;

    &.cx-loading-field {
      border: 1px solid var(--input-border-color);
      width: 100%;
      height: ${({ theme }) => `${32 * theme.fontSizeMultiplier}px`};
      display: flex;
      align-items: center;
      padding-left: 10px;
      .loading-placeholder {
        color: var(--input-placeholder-color);
        margin-right: 10px;
      }

      .loading-spinner {
        color: var(--input-placeholder-color);
        margin-right: 10px;
      }
    }

    .ant-select-selector {
      color: var(--input-color) !important;
      background-color: var(--input-background-color) !important;
      border-color: var(--input-border-color);
    }

    &.has-error {
      .ant-select-selector {
        color: var(--input-error-color) !important;
        background-color: var(--input-error-background-color) !important;
        border-color: var(--input-error-border-color) !important;
      }
      & + .validation-error {
        color: var(--input-error-validation-message);
      }
      .ant-select-arrow {
        color: var(--select-arrow-error-color);
      }
    }

    .ant-select-arrow {
      color: var(--select-arrow-color);
    }

    &.cx-select-disabled {
      background-color: var(--input-disabled-background-color);
      color: var(--input-color);

      .ant-select-selector {
        border-color: var(--input-disabled-border-color) !important;
        color: var(--input-disabled-color) !important;
      }

      .ant-select-arrow {
        color: var(--select-arrow-disabled-color);
      }
    }

    &::placeholder {
      color: var(--input-placeholder-color);
    }

    &[disabled]:hover,
    &[disabled] {
      background-color: var(--input-disabled-background-color);
      border-color: var(--input-disabled-border-color);
      &::placeholder {
        color: var(--input-disabled-placeholder-color);
      }
    }

    &:active,
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
`;
