import React, { useCallback, useEffect, useState } from 'react';
import { Cascader } from 'antd';
import { useIntl } from 'react-intl';
import { MonthMap, RelativeDates, CustomDates, FixedDates } from './date-options';

export default ({ ticketMonths, onSelected }) => {
  const [dateFilters, setDateFilters] = useState(null);
  const [dateRange, setDateRange] = useState(null);
  const { formatNumber } = useIntl();

  useEffect(() => {
    setDateFilters([FixedDates, RelativeDates, CustomDates]);

    setDateRange({
      type: 'fixed',
      year: 'ALL',
    });
  }, []);

  useEffect(() => {
    if (!ticketMonths || !Array.isArray(ticketMonths)) {
      return;
    }

    const years = ticketMonths.map(Item => {
      const months = (Item.Months || []).map(M => {
        const label = `${MonthMap[M.Month] || M.Month} ${Item.Year}`;

        return {
          value: M.Month,
          label: `${label} (${formatNumber(M.TicketCount)})`,
          isLeaf: true,
        };
      });

      return {
        value: Item.Year,
        label: `${Item.Year} (${formatNumber(Item.TicketCount)})`,
        isLeaf: false,
        children: [{ value: 'ALL', label: 'All Months', isLeaf: true }].concat(months),
      };
    });

    setDateFilters([
      {
        value: 'fixed',
        label: `Fixed`,
        isLeaf: false,
        children: [{ value: 'ALL', label: 'All Years', isLeaf: true }].concat(years),
      },
      RelativeDates,
      CustomDates,
    ]);
  }, [formatNumber, ticketMonths]);

  useEffect(() => {
    onSelected && onSelected(dateRange);
  }, [dateRange, onSelected]);

  const selectedDateRange = useCallback(range => {
    if (range && Array.isArray(range) && range.length > 0) {
      const type = range[0];

      switch (range[0]) {
        case 'custom':
          setDateRange({ type });
          break;
        case 'fixed':
          if (range.length === 2 && range[1] === 'ALL') {
            setDateRange({
              type,
              year: range[1],
              month: 'ALL',
            });
          } else if (range.length === 3) {
            setDateRange({
              type,
              year: range[1],
              month: range[2],
            });
          }
          break;
        case 'relative':
          range.length === 3 &&
            setDateRange({
              type,
              unit: range[1],
              value: range[2],
            });
          break;
        default:
          break;
      }
    }
  }, []);

  return (
    <div style={{ width: '100%', paddingRight: '10px' }}>
      {dateFilters && (
        <Cascader
          style={{ float: 'left', width: '100%' }}
          options={dateFilters}
          defaultValue={['fixed', 'ALL']}
          onChange={selectedDateRange}
          changeOnSelect
          autoFocus
          placeholder="Select..."
        />
      )}
    </div>
  );
};
